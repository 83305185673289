import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../../context/'
import Select from "react-select"
import Modal from '../../../components/Modal'
import ApiService from '../../../data/services/ApiService'

const Options = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, activeCustomer, setActiveCustomer, activePriceList, setActivePriceList, clearCart, activeBranch, setActiveBranch } = generalContext
    const [customersModalOpen, setCustomerModalOpen] = useState(false)
    const [pricesModalOpen, setPricesModalOpen] = useState(false)
    const [branchesModalOpen, setBranchesModalOpen] = useState(true)
    const [customers, setCustomers] = useState({})
    const [priceLists, setPriceLists] = useState({})
    const [branches, setBranches] = useState([])

    useEffect(() => {
        (async () => {
            await fetchCustomers()
            await fetchPriceLists()
            await fetchBranches()
        })()
    }, []) // eslint-disable-line

    async function fetchCustomers() {
        try {
            setLoading(true)
            const response = await ApiService.getCustomers()
            let data = []
            response.data.customers.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.businessName
                })
            })

            setCustomers(data)

            const pg = data.filter(c => c.label === 'Público General')
            setActiveCustomer(pg[0])
        } catch (error) {
            // catch error
        } finally {
            setLoading(false)
        }
    }

    async function fetchPriceLists() {
        try {
            setLoading(true)
            const response = await ApiService.getPriceTypes()
            let data = []
            response.data.types.map((item) => {
                return data.push(item)
            })

            setPriceLists(data)
        } catch (error) {
            // catch error
        } finally {
            setLoading(false)
        }
    }

    async function fetchBranches() {
        try {
            setLoading(true)
            const response = await ApiService.getBranches()
            let data = []
            response.data.branches.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.name
                })
            })

            setBranches(data)
            if (data.length === 1) {
                setActiveBranch(data[0])
                setBranchesModalOpen(false)
            }
        } catch (error) {
            // catch error
        } finally {
            setLoading(false)
        }
    }

    const _findCustomer = async (id) => {
        const customer = await customers.filter((c) => { return c.value === id })

        setActiveCustomer(customer[0])
    }

    const _findPriceList = async (id) => {
        const list = await priceLists.filter((c) => { return c.id === id })

        setActivePriceList(list[0])
        clearCart()
    }

    const _renderBranchesOption = () => {
        return (
            <FormGroup>
                <Label className="control-label">
                    {props.t(`Select the branch you are selling from`)}
                </Label>

                <Select
                    value={activeBranch}
                    defaultValue={activeBranch}
                    placeholder={props.t(`Select branch`)}
                    onChange={(value) => { 
                        setActiveBranch(value)
                        setBranchesModalOpen(false)
                    }}
                    options={branches}
                    classNamePrefix="select2-selection"
                />
            </FormGroup>
        )
    }

    const _renderCustomersOption = () => {
        return (
            <FormGroup>
                <Label className="control-label">
                    {props.t(`Select customer`)}
                </Label>

                <Select
                    value={activeCustomer}
                    defaultValue={activeCustomer}
                    placeholder={props.t(`Select customer`)}
                    onChange={(value) => { 
                        _findCustomer(value.value)
                    }}
                    options={customers}
                    classNamePrefix="select2-selection"
                />
            </FormGroup>
        )
    }

    const _renderPricesOption = () => {
        return (
            <FormGroup>
                <Label className="control-label">
                    {props.t(`Select a price list`)}
                </Label>

                <select className="custom-select"
                    defaultValue={activePriceList.id}
                    onChange={(event) => {
                        _findPriceList(event.target.value)
                    }}>
                    {priceLists.map((item, i) => (
                        <option key={i} value={item.id}>{item.name}</option>
                    ))}
                </select>
            </FormGroup>
        )
    }

    const _renderCustomersModal = () => {
        return (<Modal
            title={props.t(`Customers`)}
            isOpen={customersModalOpen}
            onRequestClose={() => {
                setCustomerModalOpen(false)
            }}
            body={_renderCustomersOption()}
        />)
    }

    const _renderPricesModal = () => {
        return (<Modal
            title={props.t(`Price Lists`)}
            isOpen={pricesModalOpen}
            onRequestClose={() => {
                setPricesModalOpen(false)
            }}
            body={_renderPricesOption()}
        />)
    }

    const _renderBranchesModal = () => {
        return (<Modal
            title={props.t(`Select branch`)}
            isOpen={branchesModalOpen}
            onRequestClose={() => {
                setBranchesModalOpen(false)
            }}
            body={_renderBranchesOption()}
        />)
    }

    return (
        <Row className='cart-options'>
            {customersModalOpen && _renderCustomersModal()}
            {pricesModalOpen && _renderPricesModal()}
            {(branchesModalOpen && !activeBranch) && _renderBranchesModal()}

            <Col sm={6}>
                <Button onClick={() => {
                    setCustomerModalOpen(true)
                }} color="outline" size='sm'>
                    <i className="fa-solid fa-user"></i> {props.t(`Add Customer`)}
                </Button>
            </Col>

            <Col sm={6}>
                <Button onClick={() => {
                    setPricesModalOpen(true)
                }} color="outline" size='sm'>
                    <i className="fa-solid fa-tag"></i> {props.t(`Price Lists`)}
                </Button>
            </Col>
        </Row>
    )
}

export default withRouter(withNamespaces()(Options))