import React, { useContext, useEffect } from 'react'
import { GeneralContext } from '../../context/'
import {
    Container,
} from "reactstrap"

import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import DataTable from './components/Datatable'

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Categories`),
            parentLink: '/categories',
            item: props.t(`Categories`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <DataTable />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Index))
