import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import ApiService from '../../data/services/ApiService'
import Moment from 'moment'
// import num2letras from '../../helpers/num2letras'
import CurrencyFormat from 'react-currency-format'
import Barcode from 'react-barcode'
import { Row, Col, Button } from 'reactstrap'

const Print = (props) => {
    const [order, setOrder] = useState()
    const [lineItems, setLineItems] = useState([])
    const [customer, setCustomer] = useState({})

    useEffect(() => {
        (async () => {
            const id = props.match.params.id
            await fetchOrderById(id)
        })()
    }, []) // eslint-disable-line

    const fetchOrderById = async (id) => {
        try {
            const response = await ApiService.getSalesOrderForPrintById(id)

            setOrder(response.data.order)
            setLineItems(response.data.order.lineItems)
            setCustomer(response.data.order.customer)
        } catch (error) {
            // 
        }
    }

    return (
        <React.Fragment>
            {order && (
                <React.Fragment>
                    <div className="order-action-bar">
                        <Row>
                            <Col sm={6}>
                                <h1>SO-{order.folio}</h1>
                            </Col>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                                <Button color='default'
                                    onClick={() => { window.close() }}>
                                    <i className='mdi mdi-close'></i> {props.t(`Close`)}
                                </Button>
                                <Button color='primary'
                                    onClick={() => { window.print() }}>
                                    <i className='mdi mdi-printer'></i> {props.t(`Print`)}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="order page">
                        <Row className='order-header'>
                            <Col sm={9}>
                                {order.company.logo && (
                                    <img width={220} alt={order.company.businessName} src={order.company.logo} />
                                )}
                            </Col>

                            <Col sm={3} style={{ textAlign: 'right' }}>
                                <h1>{props.t(`Sales Order`)}</h1>
                                
                                <div className="barcode-wrapper">
                                    <Barcode width={3} height={24} lineColor={'#495057'} displayValue={false} value={order.folio} />
                                </div>

                                <Row>
                                    <Col sm={6}><strong>{props.t(`Order`)}</strong></Col>
                                    <Col sm={6}>SO-{order.folio}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}><strong>{props.t(`Date`)}</strong></Col>
                                    <Col sm={6}>{Moment(order.createdAt).format("DD/MM/YYYY")}</Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <strong>{props.t(`Customer details`)}</strong><br /><br />

                                <strong>{props.t(`Legal Business Name`)}:</strong> {customer.businessName} <br />
                                <strong>{props.t(`Tax Id`)}:</strong> {customer.taxId}<br />
                                <strong>{props.t(`Phone`)}:</strong> {customer.phone}<br />
                                <strong>{props.t(`WhatsApp`)}:</strong> {customer.whatsapp}<br />
                                <strong>{props.t(`E-Mail`)}:</strong> {customer.email}<br />
                            </Col>

                            <Col sm={6}>
                                <strong>{props.t(`Shipment Details`)}</strong><br /><br />

                                <strong>{order.customer.businessName}</strong><br />
                                <strong>
                                    {order.customerAddress.streetName} {order.customerAddress.exteriorNumber} {order.customerAddress.interiorNumber} {order.customerAddress.neighborhood}, {order.customerAddress.city}, {order.customerAddress.state}, {order.customerAddress.country} C.P. {order.customerAddress.zipCode}    
                                </strong><br />
                                <strong>{props.t(`Due date`)}:</strong> {Moment(order.dudeDate).format("DD/MM/YYYY")}<br />
                                {order.requiredShippingDate && (
                                    <React.Fragment>
                                        <strong>{props.t(`Ship before`)}:</strong> {Moment(order.requiredShippingDate).format("DD/MM/YYYY")}<br />
                                        <strong>{props.t(`Shipping Costs`)}:</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.shippingCosts} />
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>

                        <section className="items">
                            <table>
                                <thead>
                                    <th>{props.t(`Name & SKU`)}</th>
                                    <th>{props.t(`QTY`)}</th>
                                    <th>{props.t(`Fulfilled`)}</th>
                                    <th>{props.t(`Unit price`)}</th>
                                    <th>{props.t(`Discount`)}</th>
                                    <th>{props.t(`Subtotal`)}</th>
                                </thead>
                                <tbody>
                                    {lineItems.map((item, i) => (
                                        <tr key={i}>
                                            <td>
                                                <strong>{item.product.name}</strong><br />
                                                <small>{props.t(`SKU`)} {item.product.sku}</small>
                                            </td>
                                            <td>{item.quantity}</td>
                                            <td>{item.itemsFulfilled}</td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitPrice} /></td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.discount} /></td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitPrice * item.itemsFulfilled} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={4}>
                                            <strong>{props.t(`Remarks`)}</strong><br />

                                            {order.message}
                                        </td>
                                        <td style={{textAlign: 'right'}}><strong>{props.t(`Subotal`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.subtotal} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={5}><strong>{props.t(`Discount`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.discount} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={5}><strong>{props.t(`Tax`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.taxAmount} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={5}><strong>{props.t(`Total`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.total} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </section>
                    </div>
                    <div className="order-footer-bar">
                        {props.t(`This order was sent through Vanta`)}<br />
                        {props.t(`Not using Vanta?`)} <a href='//tuinventario.com'>{props.t(`Learn more`)}</a>

                        <br /><br />
                        <p>{props.t(`Made with`)} <span role="img" aria-label='heart'>♥️</span> {props.t(`by`)} juvasoft</p>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Print))