import React from 'react'

import Header from "../Global/Header"
import Sidebar from "../Global/Sidebar"
import Footer from "../Global/Footer"
import Content from "../Global/content"

import { withRouter } from "react-router-dom"

const Layout = ({ children }) => {
    const [toggleMenu, setToggleMenu] = React.useState(false)

    const toggleMenuCallback = () => {
        if (toggleMenu) {
            document.getElementById("sidebar").classList.remove("open")
            document.getElementById("content").classList.remove("open")
            setToggleMenu(false)
        }

        if (!toggleMenu) {
            document.getElementById("sidebar").classList.add("open")
            document.getElementById("content").classList.add("open")
            setToggleMenu(true)
        }
    }

    return(
        <React.Fragment>
            <Sidebar id="sidebar" toggleMenuCallback={toggleMenuCallback} />
            <div id="content" className="content">
                <Header toggleMenuCallback={toggleMenuCallback} title="Vanta" />
                <Content toggleMenuCallback={toggleMenuCallback} body={children} />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default withRouter(Layout)