import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardBody,
    CardFooter
} from 'reactstrap'

import { Link } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import Avatar from '../../../assets/images/avatar-product-placeholder.png'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const Table = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        async function fetchData() {
            setLoading(true)
            const response = await ApiService.getFamilies()

            setData(response.data.data)
            setFilteredData(response.data.data)
            setLoading(false)
        }
    }, []) // eslint-disable-line

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const name = item.name || ''
            const description = item.description || ''

            return name.toLowerCase().includes(keystroke) ||
                description.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        setLoading(true)
        const result = await ApiService.deleteFamily(id)
        if (!result.data.success) {
            toastr.error('No pude eliminar la familia', 'Error')
        }

        let filtered = await data.filter((v) => { return v.id !== id })

        setData(filtered)
        setFilteredData(filtered)
        setLoading(false)
        toastr.success('Eliminé una familia', '¡Éxito!')
    }

    return(
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control"
                                placeholder="Buscar ..."
                                onKeyUp={(e) => _filterData(e)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col style={{ textAlign: 'center' }} md={4} sm={12} xs={12}>
                    <Link to="/families/add">
                        <img style={{ width: '60%' }} src="/assets/images/add-item.svg" alt={``} />
                    </Link>
                </Col>
                {filteredData && filteredData.map((item, key) => (
                    <Col {...key} md={4} sm={12} xs={12}>
                        <Card>
                            <Row>
                                <Col sm={3}>
                                    {item.image && (
                                        <img width="64" title={item.name} alt={item.name}
                                        src={`https://storage.googleapis.com/cloudadmin-storage/families/${item.image}`} />
                                    )}

                                    {!item.image && (
                                        <img width="64" title={item.name} alt={item.name}
                                        src={Avatar} />
                                    )}
                                </Col>

                                <Col sm={9}>
                                    <CardTitle>{item.name}</CardTitle>
                                    <CardBody>{item.description}</CardBody>
                                </Col>
                            </Row>

                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex font-size-20">
                                    <div className="flex-fill">
                                        <Link id={'edit'} to={`/families/edit/${item.id}`}>
                                            <i className="mdi mdi-pencil"></i> {props.t(`Edit`)}
                                        </Link>
                                    </div>

                                    <div className="flex-fill">
                                        <Link id={'delete'} to={`#`} onClick={(e) => {
                                            e.preventDefault()
                                            _deleteItem(item.id)
                                        }}>
                                            <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Table))