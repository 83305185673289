import { takeEvery, fork, put, all } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, UPDATE_PASSWORD } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, updatePasswordSuccess, apiError, setActiveCompany, setToken } from "./actions"

import ApiService from "../../../data/services/ApiService"

function* loginUser({ payload: { data, history, from } }) {
    try {
        const response = yield ApiService.login({
            email: data.email,
            password: data.password
        })

        const { success, user, token, code } = response?.data
        if (!success) {
            yield put(apiError(code))

            return false
        }

        window.analytics.identify(user.id, {
            userId: user.id,
            companies: user.companies,
            name: `${user.firstName} ${user.lastName}`,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            roleId: user.roleId,
            role: user.role?.name,
            countryCode: user.countryCode,
            phone: user.phone
        })

        yield put(loginSuccess(user))
        yield put(setToken(token))

        if (user.companies.length === 1) {
            yield put(setActiveCompany(user.companies[0]))

            history.push(from || '/')
        }
    } catch (error) {
        yield put(apiError(error))
    }
}

function* updatePassword({ payload: { data } }) {
    try {
        const response = yield ApiService.updatePassword({
            password: data.password
        })

        if (!response.data.success) {
            yield put(apiError(`can't update password`))

            return false
        }

        yield put(updatePasswordSuccess())
    } catch(error) {
        yield put(apiError(error))
    }
}

function* logoutUser({ payload: { history } }) {
    // reactLocalStorage.clear()
    yield put(logoutUserSuccess())

    history.push('/login')
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export function* whatUpdatePassword() {
    yield takeEvery(UPDATE_PASSWORD, updatePassword)
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout), fork(whatUpdatePassword)])
}

export default authSaga