import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState(props.data)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isDefault, setIsDefault] = useState(false)
    const [type] = useState('price')

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setData(data)
            setName(data.name)
            setDescription(data.description)
            setIsDefault(data.default)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, data?.id)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        let data = {
            name,
            description,
            isDefault,
            type
        }

        setLoading(true)
        if (props.data !== undefined) { 
            data.id = props.data.id
            const response = await ApiService.updatePriceType(data).catch(error => {
                toastr.error(`No pude editar a ${name}.`, 'Error')
                setLoading(false)
            })

            if (response.data.success) {
                toastr.success(`${name} fue editado.`, '¡Éxito!')
            }
            setLoading(false)
            return
        }

        const response = await ApiService.createPriceType(data).catch(error => {
            toastr.error(`No pude crear a ${name}.`, 'Error')
            setLoading(false)
        })

        if (response.data.success) {
            toastr.success(`${name} fue agregado.`, '¡Éxito!')
        }
        setLoading(false)
        _clearForm()
    }

    const _validateForm = () => {
        if (name === '') { return false }

        return true
    }

    const _clearForm = () => {
        setName('')
        setDescription('')

        document.getElementById('name').focus()
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Name`)} *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                id="name"
                                                required
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="description">
                                                {props.t(`Description`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                id="description"
                                                className="form-control"
                                                value={description}
                                                onChange={event => setDescription(event.target.value)}
                                            />
                                        </FormGroup>

                                        {/* <FormGroup>
                                            <Label className="control-label">
                                                {props.t(`Type`)} *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={type}
                                                onChange={(e) => { setType(e.target.value) }}>
                                                <option key={1} value="price" selected={type === 'price' ? 'selected' : ''}>{props.t(`Price`)}</option>
                                                <option key={2} value="discount" selected={type === 'discount' ? 'selected' : ''}>{props.t(`Discount`)}</option>
                                            </select>
                                        </FormGroup> */}

                                        <FormGroup>
                                            <Label className='control-label'>{props.t(`Default`)}</Label>

                                            <select className="custom-select"
                                                defaultValue={isDefault}
                                                onChange={(e) => { setIsDefault(e.target.value) }}>
                                                <option key={1} value={true} selected={isDefault === true ? 'selected' : ''}>{props.t(`Yes`)}</option>
                                                <option key={2} value={false} selected={isDefault === false ? 'selected' : ''}>{props.t(`No`)}</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))