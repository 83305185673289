import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import MetisMenu from "metismenujs"
import '../../assets/fonts/font-awesome/font-awesome.css'
import { isMobile } from 'react-device-detect'

const Sidebar = (props) => {
    useEffect(() => {
        initMenu()
        // eslint-disable-next-line
    }, [])

    const initMenu = () => {
        new MetisMenu("#side-menu")
        var matchingMenuItem = null
        var ul = document.getElementById("side-menu")
        var items = ul.getElementsByTagName("a")

        for (var i = 0; i < items.length; ++i) {
            if (props.location?.pathname === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }

        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    }

    const activateParentDropdown = (item) => {
        item.classList.add("active")
        const parent = item.parentElement

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add("mm-show")
                const parent3 = parent2.parentElement

                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") //a
                    const parent4 = parent3.parentElement
                    if (parent4) {
                        parent4.classList.add("mm-active")
                    }
                }
            }

            return false
        }

        return false
    }

    const toggleMenu = () => {
        if (isMobile) {
            props.toggleMenuCallback()
        }
    }

    return(
        <div id="sidebar" className="sidebar">
            <div className="site-menubar">
                <div className="site-menubar-header">
                    <Link to='/'>
                        <img alt="Vanta" src="/assets/images/logo.svg" />
                    </Link>
                </div>

                <div className="site-menubar-body">
                    <ul className="metismenu list-unstyled site-menu" id="side-menu">
                        <li className="site-menu-item">
                            <Link to="/dashboard"
                                onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to Dashboard')
                                }}>
                                <i className="site-menu-icon bx bx-chart" aria-hidden="true"></i>
                                <span className="site-menu-title">{props.t('Dashboard')}</span>
                            </Link>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/#" className="has-arrow waves-effect"
                                onClick={() => {
                                    window.analytics.track('Toggle Sales Submenu')
                                }}>
                                <i className="site-menu-icon mdi mdi-shopping"></i>
                                <span className="site-menu-title">{props.t('Sales')}</span>
                            </Link>

                            <ul className="site-menu-submenu sub-menu" aria-expanded="false">
                                <li>
                                    <Link to='/pos'
                                        onClick={() => {
                                            toggleMenu()
                                            window.analytics.track('Go to POS')
                                        }}>
                                        {props.t(`POS`)} <i className='mdi mdi-plus'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/sales'
                                        onClick={() => {
                                            toggleMenu()
                                            window.analytics.track('Go to Sales')
                                        }}>
                                        {props.t(`Sales list`)} <i className='mdi mdi-chevron-right'></i>
                                    </Link>
                                </li>
                                <li className='mm-separator'></li>
                                {/* <li><Link to='/sales-quote/new'>{props.t(`New sales quote`)} <i className='mdi mdi-plus'></i> </Link></li> */}
                                <li>
                                    <Link to='/sales-order/new'
                                        onClick={() => {
                                            toggleMenu()
                                            window.analytics.track('Go to new sales order')
                                        }}>
                                        {props.t(`New sales order`)} <i className='mdi mdi-plus'></i>
                                    </Link>
                                </li>
                                <li><Link to='/sales-order' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to sales orders')
                                }}>{props.t(`Sales order list`)} <i className='mdi mdi-chevron-right'></i> </Link></li>
                                <li className='mm-separator'></li>
                                <li><Link to='/customers/add' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to Add Customer')
                                }}>{props.t(`New customer`)} <i className='mdi mdi-plus'></i> </Link></li>
                                <li><Link to='/customers' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to customer list')
                                }}>{props.t(`Customer list`)} <i className='mdi mdi-chevron-right'></i> </Link></li>
                            </ul>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/#" className="has-arrow waves-effect" onClick={() => {
                                    window.analytics.track('Open purchases')
                                }}>
                                <i className="site-menu-icon mdi mdi-barcode-scan"></i>
                                <span className="site-menu-title">{props.t('Purchasing')}</span>
                            </Link>

                            <ul className="site-menu-submenu sub-menu" aria-expanded="false">
                                <li><Link to='/purchase-order/new' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to new purchase order')
                                }}>{props.t(`New purchase order`)} <i className='mdi mdi-plus'></i> </Link></li>
                                <li><Link to='/purchase-order' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to purchase orders list')
                                }}>{props.t(`Purchase order list`)} <i className='mdi mdi-chevron-right'></i> </Link></li>
                                <li className='mm-separator'></li>
                                <li><Link to='/purchase-quote/new'>{props.t(`New purchase quote`)} <i className='mdi mdi-plus'></i> </Link></li>
                                <li><Link to='/purchase-quote'>{props.t(`Purchase quotes`)} <i className='mdi mdi-plus'></i> </Link></li>
                                <li className='mm-separator'></li>
                                <li><Link to='/expenses/add' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to add expense')
                                }}>{props.t(`New expense`)} <i className='mdi mdi-plus'></i> </Link></li>
                                <li><Link to="/expenses" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to expenses list')
                                }}>{props.t('Expenses list')} <i className='mdi mdi-chevron-right'></i></Link></li>
                                <li className='mm-separator'></li>
                                <li><Link to='/suppliers/add' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to add supplier')
                                }}>{props.t(`New vendor`)} <i className='mdi mdi-plus'></i> </Link></li>
                                <li><Link to='/suppliers' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to suppliers list')
                                }}>{props.t(`Vendor list`)} <i className='mdi mdi-chevron-right'></i></Link></li>
                            </ul>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="site-menu-icon mdi mdi-barcode"></i>
                                <span className="site-menu-title">{props.t('Inventory')}</span>
                            </Link>

                            <ul className="site-menu-submenu sub-menu" aria-expanded="false">
                                <li><Link to='/products/add' onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to add product')
                                }}>{props.t(`New product`)} <i className='mdi mdi-plus'></i> </Link></li>
                                <li><Link to="/products" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to prodycts list')
                                }}>{props.t('Product list')} <i className='mdi mdi-chevron-right'></i></Link></li>
                                {/* <li><Link to="/products/reorder">{props.t('Reorder stock')} <i className='mdi mdi-chevron-right'></i></Link></li> */}
                                <li className='mm-separator'></li>
                                <li><Link to="/brands" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to brands list')
                                }}>{props.t('Brands')}</Link></li>
                                <li><Link to="/families" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to families list')
                                }}>{props.t('Families')}</Link></li>
                                <li><Link to="/categories" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to categories list')
                                }}>{props.t('Categories')}</Link></li>
                                <li><Link to="/subcategories" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to subcategories list')
                                }}>{props.t('Subcategories')}</Link></li>
                                <li><Link to="/price-lists" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to prices list')
                                }}>{props.t('Price Lists')}</Link></li>
                            </ul>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/#" className="has-arrow waves-effect" onClick={() => {
                                    window.analytics.track('Open bank submenu')
                                }}>
                                <i className="site-menu-icon mdi mdi-bank"></i>
                                <span className="site-menu-title">{props.t('Bank')}</span>
                            </Link>

                            <ul className="site-menu-submenu sub-menu" aria-expanded="false">                                
                                <li><Link to="/bank/withdrawal" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to new bank withdrawal')
                                }}>{props.t('New withdrawal')} <i className='mdi mdi-minus'></i></Link></li>
                                <li><Link to="/bank/deposit" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to new bank deposit')
                                }}>{props.t('New deposit')} <i className='mdi mdi-plus'></i></Link></li>
                                <li className='mm-separator'></li>
                                <li><Link to="/bank" onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Go to bank accounts list')
                                }}>{props.t('Bank accounts')} <i className='mdi mdi-chevron-right'></i></Link></li>
                            </ul>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/reportes"
                                onClick={() => {
                                    toggleMenu()
                                    window.analytics.track('Ir a Reportes')
                                }}>
                                <i className="site-menu-icon bx bxs-pie-chart-alt-2" aria-hidden="true"></i>
                                <span className="site-menu-title">Reportes</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(withNamespaces()(Sidebar))