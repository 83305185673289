import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Form,
    Button
} from 'reactstrap'
import { withNamespaces } from 'react-i18next'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const _CompanyAddress = (props) => {
    const [company, setCompany] = useState({
        street: '',
        exteriorNumber: '',
        interiorNumber: '',
        neighborhood: '',
        zipCode: '',
        city: '',
        state: '',
        country: ''
    })

    useEffect(() => {
        setCompany(props.company)
    }, []) // eslint-disable-line

    const _handleSubmit = async (e) => {
        try {
            e.preventDefault()
            props.setLoading(true)
    
            const response = await ApiService.updateCompany(company)
            if (!response) {
                toastr.error(props.t(`I couldn't update your data`))

                return
            }
    
            props.closeModal(false)
            props.updateCompany(company)
            toastr.success(props.t(`Company updated`))
        } catch (error) {
            toastr.error(props.t(`I couldn't update your data`))
        } finally {
            props.setLoading(false)
        }
    }

    return (
        <Card>
            <Form id="create" method="POST" onSubmit={ _handleSubmit }>
                <CardBody>
                    <CardTitle>{props.t(`Company Address`)}</CardTitle>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Street Name`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.street}
                                    onChange={event => setCompany({
                                        ...company,
                                        street: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={3}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Exterior Number`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.exteriorNumber}
                                    onChange={event => setCompany({
                                        ...company,
                                        exteriorNumber: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={3}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Interior Number`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.interiorNumber}
                                    onChange={event => setCompany({
                                        ...company,
                                        interiorNumber: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Zip Code`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.zipCode}
                                    onChange={event => setCompany({
                                        ...company,
                                        zipCode: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Neighborhood`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.neighborhood}
                                    onChange={event => setCompany({
                                        ...company,
                                        neighborhood: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label>
                                    {props.t(`City`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.city}
                                    onChange={event => setCompany({
                                        ...company,
                                        city: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`State`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.state}
                                    onChange={event => setCompany({
                                        ...company,
                                        state: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Country`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.country}
                                    onChange={event => setCompany({
                                        ...company,
                                        country: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col sm={12} style={{textAlign: 'right'}}>
                            <Button
                                type="submit"
                                color="primary"
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                            >
                                <i className={`mdi mdi-check`}></i> {props.t(`Update company`)}
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Form>
        </Card>
    )
}

export default withNamespaces()(_CompanyAddress)