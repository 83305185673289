import React, { useState, useEffect, useContext } from 'react'
import {
    Container,
} from 'reactstrap'

import EditForm from './components/Form'
import { GeneralContext } from '../../context/'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import ApiService from '../../data/services/ApiService'

const Edit = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext
    const [data, setData] = useState([])

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Settings`),
            parentLink: '/price-lists',
            item: props.t(`Edit Price List`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            const id = props.match.params.id
            setLoading(true)
            const response = await ApiService.getPriceTypeById(id)
            setLoading(false)
            setData(response.data.list)
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <EditForm data={data} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Edit))
