import React from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
} from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

const Company = (props) => {
    return (
        <React.Fragment>
            <Row className="settings_company">
                <Col md={12}>
                    <Row className="settings_company_options">
                        <Col md={4}>
                            <Card>
                                <Link to={`/branches`}>
                                    <CardTitle>{props.t(`Branches`)}</CardTitle>

                                    <CardBody>{props.t(`Enter the name and address of your Inventory locations`)}</CardBody>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Company))