import React, { useState, useContext } from 'react'
import {
    Button,
    FormGroup,
    Input,
    Label,
    CardTitle
} from 'reactstrap'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import ApiService from '../../../data/services/ApiService'

let polling
toastr.options = { disableTimeOut : true }

export default (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [identifier] = useState((Math.random() + 1).toString(36).substring(2,6).toUpperCase())
    const [active, setActive] = useState(false)
    const [template, setTemplate] = useState('')
    const [missing, setMissing] = useState('')

    const _runPolling = () => {
        const pollingPromise = ApiService.pollBulkImport(identifier)

        polling = setTimeout(async () => {
            pollingPromise.then(response => {
                const { process } = response.data
                const { data, status } = process

                if (status === 'missing') {
                    setMissing(data)

                    _stopPolling()
                    toastr.error(`Revisa los datos que te hacen falta`, `Importación cancelada`)
                }

                if (status === 'success') {
                    _stopPolling()

                    toastr.success(`Terminé de importar el archivo`, `¡Listo!`)
                    setActive(false)
                }

                if (status === 'error') {
                    _stopPolling()

                    toastr.error(`Hubo un problema al procesar el archivo`, `¡Oops!`)
                    setActive(false)
                }

                if (status === 'processing') {
                    _runPolling()
                }
            }).catch(error => {
                _runPolling()
            })
        }, 60000)
    }

    const _stopPolling = () => {
        clearTimeout(polling)
    }

    const _doBulkImport = async () => {
        if (!template) {
            toastr.error(`Selecciona un archivo a importar.`, '¡Error!')
            return
        }

        setLoading(true)

        try {
            const data = new FormData()
            data.append('file', template)
            data.append('identifier', identifier)

            ApiService.bulkImportStock(data)
            setActive(true)
            setLoading(false)

            toastr.success(`He comenzado el proceso en segundo plano, puedes salir de esta pantalla, te avisaremos por correo electrónico cuando termine.`, `¡Éxito!`)

            setTimeout(() => {
                _runPolling()
            }, 500)
        } catch(error) {
            console.log(`bulk import timed out, continue polling`, active)
            _runPolling()
        }
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = [
            'text/csv',
            'application/vnd.ms-excel',
            'text/comma-separated-values',
            'application/csv',
            'application/excel',
            'application/vnd.msexcel'
        ]

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan archivos CSV', 'Error')

            return 
        }

        setTemplate(files[0])
    }

    return (
        <React.Fragment>
            <CardTitle>Ejecutar Actualización</CardTitle>

            <p>Una vez que hayas agregado tus productos al template, debes subirlo en esta sección.</p>

            <ul>
                <li>No olvides guardar el archivo en formato CSV (.csv)</li>
                <li>Asegúrate de que las marcas, familias, proveedores, etc estén previamente capturados para continuar.</li>
                <li>Evite poner comas en los valores, de lo contrario habrá conflictos.</li>
                <li>El proceso se realizará en segundo plano, puedes dejar esta pantalla si lo necesitas, te avisaremos por correo electrónico cuando termine.</li>
            </ul>

            {missing && (
                <React.Fragment>
                    <p>Estos son los catálogos que no están cargados o que tal vez, escribiste mal:</p>

                    <ul>
                        {missing.map((m) => {
                            return(
                                <li>{m}</li>
                            )
                        })}
                    </ul>
                </React.Fragment>
            )}

            <FormGroup>
                <Label>Template (Archivo CSV)</Label>

                <Input type="file"
                    id="image"
                    onChange={ _handleFileUpload }
                />
            </FormGroup>

            <Button
                onClick={_doBulkImport}
                disabled={!template}
                type="submit"
                color="primary"
                className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
            >
                <i className="mdi mdi-chevron-right mr-1"></i> Procesar Productos
            </Button>
        </React.Fragment>
    )
}