import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'

import { isEmpty } from 'lodash'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../../context'
import ApiService from '../../../data/services/ApiService'
import moment from 'moment'
import 'moment/min/locales'

registerLocale('es', es)
moment.locale('es')

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState(props.data)
    const [currencyId, setCurrencyId] = useState('')
    const [bankAccountId, setBankAccountId] = useState('')
    const [expenseTypeId, setExpenseTypeId] = useState('')
    const [date, setDate] = useState('')
    const [description, setDescription] = useState('')
    const [total, setTotal] = useState('')
    const [attachments, setAttachments] = useState([])
    //
    const [currencies, setCurrencies] = useState([])
    const [expenseTypes, setExpenseTypes] = useState([])
    const [bankAccounts, setBankAccounts] = useState([])

    useEffect(() => {
        (async () => {
            await fetchExpenseTypes()
            await fetchCurrencies()
            await fetchBankAccounts()
        })()

        async function fetchCurrencies() {
            setLoading(true)
            const response = await ApiService.getCurrencies()
            let data = []

            response.data.currencies.map((item) => {
                return data.push({
                    value: item.id,
                    label: `${item.name} (${item.value})`,
                })
            })

            setLoading(false)
            setCurrencies(data)
            setCurrencyId(data[0].value)
        }

        async function fetchExpenseTypes() {
            setLoading(true)
            const response = await ApiService.getExpenseTypes()
            let data = []

            response.data.types.map((item) => {
                return data.push({
                    value: item.id,
                    label: `${item.name}`,
                })
            })

            setLoading(false)
            setExpenseTypes(data)
            setExpenseTypeId(data[0].value)
        }

        async function fetchBankAccounts() {
            setLoading(true)
            const response = await ApiService.getBankAccounts()
            let data = []

            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: `${item.alias}`,
                })
            })

            setLoading(false)
            setBankAccounts(data)
            setBankAccountId(data[0].value)
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setData(data)
            setCurrencyId(data.currencyId)
            setBankAccountId(data.bankAccountId)
            setExpenseTypeId(data.expenseTypeId)
            setDate(!isEmpty(data.date) ? moment(data.date).toDate() : '')
            setDescription(data.description)
            setTotal(data.total)
            setAttachments(data.attachments)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, data?.id)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        const data = {
            currencyId,
            bankAccountId,
            expenseTypeId,
            date,
            description,
            total,
            attachments
        }

        setLoading(true)
        if (props.data !== undefined) { 
            data.id = props.data.id
            const response = await ApiService.updateExpense(data).catch(error => {
                toastr.error(`No pude editar el registro.`, 'Error')
                setLoading(false)
            })

            if (response.data.success) {
                toastr.success(`Edité el registro.`, '¡Éxito!')
            }
            setLoading(false)
            return
        }

        const response = await ApiService.createExpense(data).catch(error => {
            toastr.error(`No pude crear el registro.`, 'Error')
            setLoading(false)
        })

        if (response.data.success) {
            toastr.success(`Registré el gasto.`, '¡Éxito!')
        }
        setLoading(false)
        _clearForm()
    }

    const _validateForm = () => {
        if (description === '') { return false }
        if (total === '') { return false }

        return true
    }

    const _clearForm = () => {
        setDate('')
        setTotal('')
        setCurrencyId('')
        setExpenseTypeId('')
        setBankAccountId('')
        setDescription('')
        setAttachments([])

        document.getElementById('description').focus()
    }

    // @TODO: write a better feedback system for image loadings.
    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf']

        for (let i = 0; i < files.length; i++) {
            if (types.every((type) => files[i].type !== type)) {
                toastr.error('Revisa que hayas seleccionado el archivo correcto.', 'Error')

                return    
            }

            setLoading(true)
            fileUpload(files[i]).then((response) => {
                attachments.push(response.data.fileName)

                setLoading(false)
                toastr.success('Subí un archivo', '¡Éxito!')
            }).catch((error) => {
                setLoading(false)

                console.log('fileUpload', error)
                toastr.error('No pude subir la imagen', 'Error')
            })
        }
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('file', file)

        return ApiService.uploadExpenseAttachment(formData)
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Date`)} *
                                            </Label>

                                            <DatePicker
                                                locale="es"
                                                selected={date}
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                onChange={date => setDate(date)}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="name">
                                                {props.t(`Description`)}
                                            </Label>
                                            <Input
                                                id="description"
                                                type="text"
                                                className="form-control"
                                                value={description}
                                                onChange={event => setDescription(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="name">
                                                {props.t(`Amount`)}
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                value={total}
                                                onChange={event => setTotal(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="control-label">
                                                {props.t('Bank Accounts')} *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={bankAccountId}
                                                onChange={(event) => setBankAccountId(event.target.value)}>
                                                {bankAccounts.map((item, i) => {
                                                    let selected = currencyId === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="control-label">
                                                {props.t('Currency')} *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={currencyId}
                                                onChange={(event) => setCurrencyId(event.target.value)}>
                                                {currencies.map((item, i) => {
                                                    let selected = currencyId === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="control-label">
                                                {props.t('Expense Type')} *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={expenseTypeId}
                                                onChange={(event) => setExpenseTypeId(event.target.value)}>
                                                {expenseTypes.map((item, i) => {
                                                    let selected = currencyId === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>{props.t(`Adjuntar Archivos`)}</Label>

                                            <Input type="file"
                                                id="image"
                                                multiple
                                                onChange={ _handleFileUpload }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))