import React, { useContext } from 'react'
import { Table } from "reactstrap"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../../context/'
import CurrencyFormat from 'react-currency-format'

const CartTotals = (props) => {
    const generalContext = useContext(GeneralContext)
    const { cartItems } = generalContext

    return (
        <Table borderless>
            <tbody borderless="true">
                <tr>
                    <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Subtotal`)}:</td>
                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={cartItems.reduce((a, b) => a + +b.subtotal, 0)} /></td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Discount`)}:</td>
                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={cartItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)} /></td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Tax rate`)}:</td>
                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={cartItems.reduce((a, b) => a + +b.taxAmount, 0)} /></td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Total`)}:</td>
                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={cartItems.reduce((a, b) => a + +b.total, 0)} /></td>
                </tr>
            </tbody>
        </Table>
    )
}

export default withRouter(withNamespaces()(CartTotals))