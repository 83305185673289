import React, { useState, useEffect } from 'react'

import { Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody } from "reactstrap"

import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { loginUser, apiError, setActiveCompany } from "../../store/actions"
import { isEmpty } from 'lodash'
import toastr from 'toastr'

const Login = (props) => {
    const [nextPage] = useState(props.location?.state?.from?.pathname || '/')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [filteredCompanies, setFilteredCompanies] = useState([])
    const [profile, setProfile] = useState()

    useEffect(() => {
        const { error } = props

        if (error) {
            toastr.error('Utilizaste un usuario o password incorrecto', `Oop's`)
            props.apiError('')
        }

        console.log(`ERROR`, error)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.error])

    useEffect(() => {
        if (!isEmpty(props.profile)) {
            setProfile(props.profile)
            setFilteredCompanies(props.profile.companies)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.profile])

    const _handleSubmit = (e) => {
        e.preventDefault()

        if (!_validateNull()) {
            toastr.error('No olvides ingresar tu usuario y password')

            return false
        }

        props.loginUser({
            email, password
        }, props.history, nextPage)
    }

    const _validateNull = () => {
        if (email === '') { return false }
        if (password === '') { return false }

        return true
    }

    const _filterCompanies = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const companies = profile.companies.filter((item) => {
            const businessName = item.businessName || ''
            const taxId = item.taxId || ''

            return businessName.toLowerCase().includes(keystroke) ||
                taxId.toLowerCase().includes(keystroke)
        })

        setFilteredCompanies(companies)
    }

    const __loginForm = () => {
        return (
            <div className="form">
                <h1>Iniciar Sesión</h1>

                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label htmlFor="name">
                                Email o Teléfono
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormGroup>
                            <Label htmlFor="name">
                                Password
                            </Label>
                            <Input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className='form-actions'>
                    <Col sm={12}>
                        <Button
                            type="submit"
                            color="primary"
                            className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                        >
                            Iniciar Sesión
                        </Button>
                    </Col>

                    <Link to="/recuperar-password" onClick={() => {
                        window.analytics.track('Ir a Recuperar Contraseña')
                    }}>¿Olvidaste tu contraseña?</Link>
                </Row>
            </div>
        )
    }

    const __companiesForm = () => {
        return (
            <div className="form">
                <h1>Mis empresas</h1>

                <p>¿En qué empresa vas a trabajar hoy?</p> <br />

                <Row className="mb-2">
                    <Col sm={12}>
                        <div className="search-box mr-2 mb-2 d-inline-block">
                            <Input 
                                type="search"
                                placeholder={``}
                                onKeyUp={(e) => _filterCompanies(e)}
                            />

                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </Col>
                </Row>

                <React.Fragment>
                    {filteredCompanies.map((c, i) => (
                        <Card key={i}>
                            <CardBody>
                                <Link to={nextPage} onClick={(e) => {
                                    e.preventDefault()
                                    props.setActiveCompany(c)

                                    window.analytics.track('User selected company', c)
                                    props.history.push(nextPage)
                                }}>
                                    {c.businessName}
                                </Link>
                            </CardBody>
                        </Card>
                    ))}
                </React.Fragment>
            </div>
        )
    }

    return(
        <React.Fragment>
            <div className="login">
                <div className="wrapper grid-layout">
                    <Form id="login" type="POST" onSubmit={ _handleSubmit }>
                        <div className="logo">
                            <img alt="Vanta" src="./assets/images/logo.svg" />
                        </div>

                        {isEmpty(profile) && __loginForm()}
                        {!isEmpty(profile?.companies) && __companiesForm()}
                    </Form>

                    <Row>
                        <Col sm={12} className="login-copyright">
                            <p>Copyright © {new Date().getFullYear()}. Todos los Derechos Reservados.</p>
                            <p>Hecho con amor por <a target="_blank" rel="noopener noreferrer" href="https://juvasoft.com/?utm_source=vantaapp&utm_medium=login">Juvasoft</a></p>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { error, profile, activeCompany } = state.Auth

    return { error, profile, activeCompany }
}

export default withRouter(
    connect(mapStatetoProps, { loginUser, apiError, setActiveCompany })(Login)
)