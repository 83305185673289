import React, { useContext, useEffect } from 'react'
import { GeneralContext } from '../../context/'
import { Container } from 'reactstrap'
import Table from './components/Table'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Settings`),
            parentLink: '/settings/inventory',
            item: props.t(`Branches`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div id="mainContent" className="page mainContent">
                <Container fluid>
                    <Table />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Index))