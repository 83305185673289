import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Profile from './Profile'
import Breadcrumbs from './Breadcrumbs'
import { isMobile } from 'react-device-detect'

const Header = (props) => {
    const toggleMenu = (e) => {
        e.preventDefault()
        props.toggleMenuCallback()
    }

    return(
        <header>
            <div className='navbar-profile'>
                <Row>
                    <Col xs={8} sm={10} md={9} lg={9} xl={10}>
                        <Row>
                            {isMobile && (
                                <Col xs={1} sm={2} md={2} lg={1}>
                                    <Link to="/" onClick={toggleMenu}
                                        className="font-size-24 waves-effect"
                                        id="vertical-menu-btn">
                                        <i className="fa fa-fw fa-bars"></i>
                                    </Link>
                                </Col>
                            )}

                            <Col xs={10} sm={10} md={10} lg={11}>
                                <Breadcrumbs />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div className="navbar-profile-actions">
                            <Profile profile={props.Auth.profile} />
                        </div>
                    </Col>
                </Row>
            </div>
        </header>
    )
}

const mapStatetoProps = (state) => {
    const { Auth } = state
    return { Auth }
}

export default connect(mapStatetoProps)(Header)