import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardFooter
} from 'reactstrap'

import { Link } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const Table = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        async function fetchData() {
            setLoading(true)
            const response = await ApiService.getTaxSchemes()

            setData(response.data.schemes)
            setFilteredData(response.data.schemes)
            setLoading(false)
        }
    }, []) // eslint-disable-line

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
        const label = item.label || ''

            return label.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        const result = await ApiService.deleteTaxScheme(id)
        if (!result.data.success) {
            toastr.error(`No pude eliminar el esquema de impuestos`, 'Error')
        }

        let filtered = await data.filter((v) => { return v.id !== id })

        setData(filtered)
        setFilteredData(filtered)

        toastr.success(`Eliminé un esquema de impuestos`, '¡Éxito!')
    }

    return(
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control"
                                placeholder={props.t(`Search`)}
                                onKeyUp={(e) => _filterData(e)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col style={{ textAlign: 'center' }} md={4} sm={12} xs={12}>
                    <Link to="/tax-schemes/add">
                        <img style={{ width: '60%' }} src="/assets/images/add-item.svg" alt={``} />
                    </Link>
                </Col>

                {filteredData && filteredData.map((item, key) => (
                    <Col {...key} md={4} sm={12} xs={12}>
                        <Card>
                            <CardTitle>{item.label}</CardTitle>

                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex font-size-20">
                                    <div className="flex-fill">
                                        <Link id={'edit'} to={`/tax-schemes/edit/${item.id}`}>
                                            <i className="mdi mdi-pencil"></i> {props.t(`Edit`)}
                                        </Link>
                                    </div>

                                    <div className="flex-fill">
                                        <Link id={'delete'} to={`#`} onClick={(e) => {
                                            e.preventDefault()
                                            _deleteItem(item.id)
                                        }}>
                                            <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Table))