import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import { GeneralContext } from '../../../context'
import ApiService from '../../../data/services/ApiService'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import { validateRFC, validateEmail } from '../../../components/Global/helpers'

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [supplierCode, setSupplierCode] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [legalBusinessName, setLegalBusinessName] = useState('')
    const [taxId, setTaxId] = useState('')
    const [phone, setPhone] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [email, setEmail] = useState('')
    const [website, setWebsite] = useState('')
    const [image, setImage] = useState('')
    const [notes, setNotes] = useState('')
    const [tags, setTags] = useState([])

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props
            const tags = data.tags || []

            setId(data.id)
            setBusinessName(data.businessName)
            setSupplierCode(data.supplierCode)
            setTaxId(data.taxId)
            setPhone(data.phone)
            setWhatsapp(data.whatsapp)
            setImage(data.image)
            setLegalBusinessName(data.legalBusinessName)
            setEmail(data.email)
            setTags(tags)
            setNotes(data.notes)
            setWebsite(data.website)
        }

        document.getElementById("businessName").focus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        setLoading(true)

        let data = {
            businessName, 
            legalBusinessName,
            supplierCode,
            tags, 
            phone, 
            email,
            whatsapp,
            taxId,
            image,
            notes,
            website
        }

        if (props.data !== undefined) { 
            data = {
                ...data,
                id
            }

            _updateRecord(data)

            return false
        }
        
        _createRecord(data)
        
        props.history.push('/suppliers')
    }

    const _createRecord = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createSupplier(data)

            if (!response.data.success) {
                toastr.error(`No pude crear a ${businessName}.`, 'Error')

                return false
            }

            toastr.success(props.t(`Supplier Added`), '¡Éxito!')
            window.analytics.track('New supplier added', {
                data
            })
        } catch(error) {
            toastr.error(`No pude crear a ${businessName}.`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _updateRecord = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateSupplier(data)

            if (!response.data.success) {
                toastr.error(`No pude editar a ${businessName}.`, 'Error')

                return false
            }

            toastr.success(props.t(`Supplier Updated`), props.t(`Success`))
            window.analytics.track(`Supplier Updated`, {
                data
            })
        } catch(error) {
            toastr.error(`No pude editar a ${businessName}.`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (businessName === '') { return false }
        if (legalBusinessName === '') { return false }

        return true
    }

    const _generateSupplierCode = (e) => {
        let prefix = (Math.random() + 1).toString(36).substr(2,6).toUpperCase()

        setBusinessName(e.target.value)
        setSupplierCode(prefix)
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imagenes', 'Error')

            return 
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {
            setImage(response.data.fileName)
            setLoading(false)

            toastr.success('Subí el logotipo', '¡Éxito!')
        }).catch(error => {
            console.log('fileUpload', error)
            setLoading(false)
            toastr.error('No pude subir el logotipo', 'Error')
        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('file', file)

        return ApiService.uploadSupplierImage(formData)
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-secondary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardTitle>{props.t(`General Information`)}</CardTitle>
                            <CardBody>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Business Name`)} *
                                            </Label>
                                            <Input
                                                id="businessName"
                                                type="text"
                                                className="form-control"
                                                value={businessName}
                                                onChange={event => _generateSupplierCode(event)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Legal Business Name`)} *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={legalBusinessName}
                                                onChange={event => setLegalBusinessName(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Tax Id`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={taxId}
                                                id="taxId"
                                                onBlur={event => {
                                                    const { value } = event.target
                                                    if (!validateRFC(value)) {
                                                        toastr.error(`El RFC que ingresaste no es válido`, 'Error')
                                                        document.getElementById('taxId').style.border = '1px solid red'
                                                    } else {
                                                        document.getElementById('taxId').style.border = '1px solid #c7ced5'
                                                    }
                                                }}
                                                onChange={event => setTaxId(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Supplier Code`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={supplierCode}
                                                required
                                                readOnly
                                                onChange={event => setSupplierCode(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Website`)}
                                            </Label>
                                            <Input
                                                type="url"
                                                className="form-control"
                                                value={website}
                                                onChange={event => setWebsite(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label className="control-label">
                                            {props.t(`Tags`)}
                                            </Label>
                                            <TagsInput
                                                value={tags}
                                                onChange={(value) => setTags(value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>{props.t(`Image`)} *</Label>

                                            <Input type="file"
                                                id="logotipo"
                                                onChange={e => _handleFileUpload(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <CardTitle>{props.t(`Customer Support`)}</CardTitle>

                                <Row>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Phone Number`)}
                                            </Label>
                                            <Input
                                                type="phone"
                                                className="form-control"
                                                value={phone}
                                                pattern="\d{10}"
                                                title="Utiliza un número válido"
                                                onChange={event => setPhone(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Whatsapp`)}
                                            </Label>
                                            <Input
                                                type="phone"
                                                className="form-control"
                                                value={whatsapp}
                                                pattern="\d{10}"
                                                title="Utiliza un número válido"
                                                onChange={event => setWhatsapp(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Email`)}
                                            </Label>
                                            <Input
                                                id="email"
                                                type="email"
                                                className="form-control"
                                                value={email}
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                title="Utiliza un correo electrónico válido"
                                                onBlur={event => {
                                                    const { value } = event.target
                                                    if (!validateEmail(value)) {
                                                        toastr.error(`El Email que ingresaste no es válido`, 'Error')
                                                        document.getElementById('email').style.border = '1px solid red'
                                                    } else {
                                                        document.getElementById('email').style.border = '1px solid #c7ced5'
                                                    }
                                                }}
                                                onChange={event => setEmail(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))
