import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { withNamespaces } from "react-i18next"

const PagingState = ({
    onPageChange,
    currentPage,
    pageSize,
    totalCount,
    handleRowsPerPageChange,
    maxVisiblePages,
    t
}) => {
    const totalPages = Math.ceil(totalCount / pageSize)

    const handlePageClick = (page) => {
        if (page >= 0 && page < totalPages && page !== currentPage) {
            onPageChange(page)
        }
    }

    const renderPaginationItems = () => {
        const items = []
        const visiblePages = Math.min(totalPages, maxVisiblePages)

        // Determine the start and end index of visible pages
        let start = Math.max(0, currentPage - Math.floor(visiblePages / 2))
        let end = Math.min(start + visiblePages - 1, totalPages - 1)

        // If the range is too close to the end, adjust the start index
        if (end - start + 1 < visiblePages) {
            start = Math.max(0, end - visiblePages + 1)
        }

        if (start > 0) {
            items.push(
                <PaginationItem key="first">
                    <PaginationLink onClick={() => handlePageClick(0)}>1</PaginationLink>
                </PaginationItem>
            )

            items.push(<PaginationItem key="ellipsis1" disabled><PaginationLink>...</PaginationLink></PaginationItem>)
        }

        for (let i = start; i < end; i++) {
            items.push(
                <PaginationItem key={i} active={i === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(i)}>
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>
            )
        }

        if (end < totalPages - 1) {
            items.push(<PaginationItem key="ellipsis2" disabled><PaginationLink>...</PaginationLink></PaginationItem>)
            items.push(
                <PaginationItem key="last">
                    <PaginationLink onClick={() => handlePageClick(totalPages - 1)}>
                        {totalPages}
                    </PaginationLink>
                </PaginationItem>
            )
        }

        return items
    }

    return (
        <React.Fragment>
            <Pagination>
                <PaginationItem>
                    <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)} />
                </PaginationItem>
                {renderPaginationItems()}
                <PaginationItem>
                    <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
                </PaginationItem>
            </Pagination>

            <div>
                <span>{t(`Rows per page`)}: </span>
                <select
                    className="form-select"
                    value={pageSize}
                    onChange={handleRowsPerPageChange}
                >
                    {[5, 10, 25, 50, 100].map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces()(PagingState)