import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'
import ViewComponent from './components/View'

const View = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [orderId, setOrderId] = useState()
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Purchase Orders`),
            parentLink: '/purchase-order',
            item: props.t(`View Order`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            setOrderId(props.match.params.id)
        })()
    }, []) // eslint-disable-line

    return (
        <React.Fragment>
            <ViewComponent t={props.t} history={props.history} setLoading={setLoading} purchaseOrderId={orderId} />
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(View))