import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardTitle,
    CardSubtitle
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Select from "react-select"
import Countries from '../../../data/countries'
import { GeneralContext } from '../../../context'
import ApiService from '../../../data/services/ApiService'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { validateRFC, validateEmail } from '../../../components/Global/helpers'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import fiscalRegimes from '../../../data/fiscalRegimes'
import { isEmpty } from 'lodash'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [businessName, setBusinessName] = useState('')
    const [taxId, setTaxId] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [fiscalResidence, setFiscalResidence] = useState(false)
    const [residenciaFiscalDisabled, setResidenciaFiscalToggle] = useState(true)
    const [fiscalRegime, setFiscalRegime] = useState()
    const [email, setEmail] = useState('')
    const [notes, setNotes] = useState('')
    const [tags, setTags] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    // Address 
    const [contactName, setContactName] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [contactPhoneNumber, setContactPhoneNumber] = useState('')
    const [contactCellphone, setContactCellphone] = useState('')
    const [streetName, setStreetname] = useState('')
    const [exteriorNumber, setExteriorNumber] = useState()
    const [interiorNumber, setInteriorNumber] = useState()
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [zipCode, setZipCode] = useState()
    const [addressNotes] = useState('')
    // catalogs
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])

    useEffect(() => {
        (async () => {
            await _fetchCountries()
        })()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        (async () => {
            if (country !== '') {
                await _fetchStates(country.value)
            }
        })()

        // eslint-disable-next-line
    }, [country])

    const _fetchCountries = async () => {
        setLoading(true)
        try {
            let data = []
            const response = await ApiService.getCountries()
            response.data.countries.map((item) => {
                return data.push({
                    value: item.id,
                    label: props.t(item.nameEn),
                })
            })

            setCountries(data)
            setLoading(false)
        } catch (error) {
            toastr.error(props.t(`Oop's! I couldn't find any countries`))
        } finally {
            setLoading(false)
        }
    }

    const _fetchStates = async (countryId) => {
        try {
            setLoading(true)
            let data = []
            const response = await ApiService.getStates(countryId)
            response.data.states.map((item) => {
                return data.push({
                    value: item.id,
                    label: props.t(item.nomEstado),
                })
            })

            setStates(data)
            setLoading(false)
        } catch (error) {
            toastr.error(props.t(`Oop's! I couldn't find any states`))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            const notes = markdownToDraft(data.notes)
            const tags = !isEmpty(data.tags) ? data.tags : []

            setId(data.id)
            setBusinessName(data.businessName)
            setTaxId(data.taxId)
            setPhoneNumber(data.phoneNumber)
            setResidenciaFiscalToggle(data.fiscalResidence ? false : true)
            setEmail(data.email)
            setTags(tags)
            setNotes(data.notes)

            const rf = Countries.find(item => {
                return item.value === data.residenciaFiscal
            })

            const regfis = fiscalRegimes.find(item => {
                return item.value === data.fiscalRegime
            })

            setFiscalResidence(rf)
            setFiscalRegime(regfis)
            setEditorState(EditorState.createWithContent(convertFromRaw(notes)))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        setLoading(true)

        let data = {
            businessName, 
            tags, 
            phoneNumber, 
            email, 
            taxId, 
            notes,
            fiscalRegime: fiscalRegime?.value,
            fiscalResidence: fiscalResidence?.value,
            contactName,
            contactEmail,
            contactPhoneNumber,
            contactCellphone,
            streetName,
            interiorNumber,
            exteriorNumber,
            country: country.value,
            state: state.value,
            city,
            zipCode,
            neighborhood,
            addressNotes
        }

        if (props.data !== undefined) { 
            _updateCustomer({ ...data, id })

            return false
        }
        
        _createCustomer(data)
        
        props.history.push('/customers')
    }

    const onEditorStateChange = (editorState) => {
        const notes = draftToMarkdown(convertToRaw(editorState.getCurrentContent()))

        setEditorState(editorState)
        setNotes(notes)
    }

    const _createCustomer = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createCustomer(data)

            if (!response.data.success) {
                toastr.error(`No pude crear a ${businessName}.`, 'Error')

                return false
            }

            toastr.success(props.t(`Customer added`), '¡Éxito!')
            window.analytics.track('New customer addedd', {
                data
            })
        } catch(error) {
            toastr.error(`No pude crear a ${businessName}.`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _updateCustomer = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateCustomer(data)

            if (!response.data.success) {
                toastr.error(`No pude editar a ${businessName}.`, 'Error')

                return false
            }

            toastr.success(props.t(`Customer Updated`), props.t(`Success`))
            window.analytics.track(`Customer Updated`, {
                data
            })
        } catch(error) {
            toastr.error(`No pude editar a ${businessName}.`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (businessName === '') { return false }
        if (taxId === '') { return false }
        if (email === '') { return false }

        if (!validateRFC(taxId)) {
            toastr.error(`El RFC que ingresaste no es válido`, 'Error')
            document.getElementById('rfc').style.border = '1px solid red'

            return false
        }

        return true
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-secondary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Información General
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Agrega los datos generales de tu cliente.
                                            Recuerda que la información es poder, entre más
                                            sepas de tus clientes, mejor podrás ayudarles.
                                        </CardSubtitle>
                                    </Col>
                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Legal Business Name`)} *
                                                    </Label>
                                                    <Input
                                                        id="businessName"
                                                        type="text"
                                                        className="form-control"
                                                        value={businessName}
                                                        required
                                                        onChange={event => setBusinessName(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                    {props.t(`Tax Id`)} *
                                                    </Label>
                                                    <Input
                                                        id={'taxId'}
                                                        type="text"
                                                        className="form-control"
                                                        value={taxId}
                                                        required
                                                        onBlur={event => {
                                                            const { value } = event.target
                                                            if (!validateRFC(value)) {
                                                                toastr.error(`El RFC que ingresaste no es válido`, 'Error')
                                                                document.getElementById('taxId').style.border = '1px solid red'
                                                            } else {
                                                                document.getElementById('taxId').style.border = '1px solid #c7ced5'
                                                            }
                                                        }}
                                                        onChange={event => {
                                                            const { value } = event.target
                                                            if (value.toUpperCase() === 'XEXX010101000') {
                                                                setResidenciaFiscalToggle(false)
                                                            } else {
                                                                setResidenciaFiscalToggle(true)
                                                            }

                                                            setTaxId(value)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>{props.t(`Tags`)}</Label>

                                                    <TagsInput
                                                        onlyUnique={true}
                                                        addKeys={[9, 13, 32, 186, 188]}
                                                        value={tags}
                                                        onChange={(value) => setTags(value)}
                                                        pasteSplit={data => {
                                                            const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r']

                                                            return data.split(new RegExp(separators.join('|'))).map(d => d.trim())
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                    {props.t(`Fiscal Regime`)} *
                                                    </Label>
                                                    <Select
                                                        value={fiscalRegime}
                                                        placeholder={`Selecciona régimen fiscal`}
                                                        onChange={(value) => { 
                                                            setFiscalRegime(value)
                                                        }}
                                                        options={fiscalRegimes}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                    {props.t(`Fiscal Residence`)} *
                                                    </Label>
                                                    <Select
                                                        value={fiscalResidence}
                                                        placeholder={`Selecciona una residencia Fiscal`}
                                                        onChange={(value) => { 
                                                            setFiscalResidence(value)
                                                        }}
                                                        isDisabled={residenciaFiscalDisabled}
                                                        options={Countries}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`E-Mail`)}
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        type="email"
                                                        className="form-control"
                                                        value={email}
                                                        required
                                                        onBlur={event => {
                                                            const { value } = event.target
                                                            if (!validateEmail(value)) {
                                                                toastr.error(`El Email que ingresaste no es válido`, 'Error')
                                                                document.getElementById('email').style.border = '1px solid red'
                                                            } else {
                                                                document.getElementById('email').style.border = '1px solid #c7ced5'
                                                            }
                                                        }}
                                                        onChange={event => setEmail(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                    {props.t(`Phone`)}
                                                    </Label>
                                                    <Input
                                                        id="phoneNumber"
                                                        type="tel"
                                                        pattern="\d{10}"
                                                        maxLength={10}
                                                        className="form-control"
                                                        value={phoneNumber}
                                                        onChange={event => setPhoneNumber(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label>{props.t(`Notes`)}</Label>

                                                    <Editor
                                                        editorClassName="draftjs"
                                                        editorState={editorState}
                                                        onEditorStateChange={onEditorStateChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Direcciones
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Agrega las direcciones que tengas de tu cliente.
                                            Recuerda que la información es poder, entre más
                                            sepas de tus clientes, mejor podrás ayudarles.
                                        </CardSubtitle>
                                    </Col>
                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact name`)}
                                                    </Label>
                                                    <Input
                                                        id="contactName"
                                                        type="text"
                                                        className="form-control"
                                                        value={contactName}
                                                        onChange={event => setContactName(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact email`)}
                                                    </Label>
                                                    <Input
                                                        id="contactEmail"
                                                        type="email"
                                                        className="form-control"
                                                        value={contactEmail}
                                                        onChange={event => setContactEmail(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact phone number`)}
                                                    </Label>
                                                    <Input
                                                        type="tel"
                                                        pattern="\d{10}"
                                                        maxLength={10}
                                                        className="form-control"
                                                        id="contactPhoneNumber"
                                                        value={contactPhoneNumber}
                                                        onChange={event => setContactPhoneNumber(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact Cellphone number`)}
                                                    </Label>
                                                    <Input
                                                        id="contactCellphone"
                                                        type="tel"
                                                        pattern="\d{10}"
                                                        maxLength={10}
                                                        className="form-control"
                                                        value={contactCellphone}
                                                        onChange={event => setContactCellphone(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Street Name`)}
                                                    </Label>
                                                    <Input
                                                        id="streetName"
                                                        type="text"
                                                        className="form-control"
                                                        value={streetName}
                                                        onChange={event => setStreetname(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Exterior No.`)}
                                                    </Label>
                                                    <Input
                                                        id="exteriorNumber"
                                                        type="number"
                                                        className="form-control"
                                                        value={exteriorNumber}
                                                        onChange={event => setExteriorNumber(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Interior No.`)}
                                                    </Label>
                                                    <Input
                                                        id="interiorNumber"
                                                        type="number"
                                                        className="form-control"
                                                        value={interiorNumber}
                                                        onChange={event => setInteriorNumber(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Country`)}
                                                    </Label>

                                                    <Select 
                                                        id={`country`}
                                                        default={country}
                                                        placeholder={props.t(`Select country`)}
                                                        onChange={ value => setCountry(value) }
                                                        options={countries}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`State`)}
                                                    </Label>

                                                    <Select 
                                                        id={`state`}
                                                        default={state}
                                                        placeholder={props.t(`Select state`)}
                                                        onChange={ value => setState(value) }
                                                        options={states}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`City`)}
                                                    </Label>
                                                    <Input
                                                        id="city"
                                                        type="text"
                                                        className="form-control"
                                                        value={city}
                                                        required
                                                        onChange={event => setCity(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Zip Code`)}
                                                    </Label>
                                                    <Input
                                                        id="zipCode"
                                                        type="text"
                                                        className="form-control"
                                                        value={zipCode}
                                                        onChange={event => setZipCode(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Neighborhood`)}
                                                    </Label>
                                                    <Input
                                                        id="neighborhood"
                                                        type="text"
                                                        className="form-control"
                                                        value={neighborhood}
                                                        onChange={event => setNeighborhood(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))
