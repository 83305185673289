import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardTitle,
    CardSubtitle
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context'
import ApiService from '../../../data/services/ApiService'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [companyId] = useState()
    const [customerId, setCustomerId] = useState()
    // Address 
    const [contactName, setContactName] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [contactPhoneNumber, setContactPhoneNumber] = useState('')
    const [contactCellphone, setContactCellphone] = useState('')
    const [streetName, setStreetName] = useState('')
    const [exteriorNumber, setExteriorNumber] = useState()
    const [interiorNumber, setInteriorNumber] = useState()
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState()
    const [country, setCountry] = useState()
    const [zipCode, setZipCode] = useState()
    const [notes, setNotes] = useState()

    useEffect(() => {
        (async () => {
            await _fetchCustomerAddress()
        })()
        // eslint-disable-next-line
    }, [])

    const _fetchCustomerAddress = async () => {
        try {
            let id = props.match.params.id
            let customerId = props.match.params.customerId
            let response = await ApiService.getAddressById(id)

            if (!response.data.success) {
                throw new Error(props.t(`Address not found`))
            }

            const { address } = response.data
            setId(id)
            setCustomerId(customerId)
            setContactName(address.contactName)
            setContactEmail(address.contactEmail)
            setContactCellphone(address.contactCellphone)
            setContactPhoneNumber(address.contactPhoneNumber)
            setStreetName(address.streetName)
            setExteriorNumber(address.exteriorNumber)
            setInteriorNumber(address.interiorNumber)
            setNeighborhood(address.neighborhood)
            setCity(address.city)
            setZipCode(address.zipCode)
            setNotes(address.notes)
            setCountry(address.country)
            setState(address.state)
        } catch (error) {
            toastr.error(error.message)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        try {
            setLoading(true)

            let data = {
                companyId,
                customerId,
                contactName,
                contactEmail,
                contactPhoneNumber,
                contactCellphone,
                streetName,
                exteriorNumber,
                interiorNumber,
                neighborhood,
                city,
                state: state,
                country: country,
                zipCode,
                notes
            }
            
            await _updateCustomerAddress({ ...data, id })
            
            props.history.push(`/customers/addresses/${customerId}`)
        } catch (error) {
            console.log(`ERROR`, error)
            toastr.error(error.message, `Error`)
        } finally {
            setLoading(false)
        }
    }

    const _updateCustomerAddress = async (data) => {
        try {
            const response = await ApiService.updateCustomerAddress(data)

            if (!response.data.success) {
                throw new Error(props.t(`I couldn't edit this address`))
            }

            toastr.success(props.t(`Address Updated`), props.t(`Success`))
            window.analytics.track(`Address Updated`, {
                data
            })
        } catch(error) {
            throw new Error(props.t(`I couldn't edit this address`))
        }
    }

    const _validateForm = () => {
        if (customerId === '') { return false }
        if (streetName === '') { return false }
        if (exteriorNumber === '') { return false }

        return true
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-secondary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={4} sm={12}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>{props.t(`Contact Information`)}</CardTitle>

                                                <CardSubtitle className="mb-3">
                                                    {props.t(`Save the contact information`)}.
                                                </CardSubtitle>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col md={8} sm={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Contact name`)}
                                                            </Label>
                                                            <Input
                                                                id="contactName"
                                                                type="text"
                                                                className="form-control"
                                                                value={contactName}
                                                                onChange={event => setContactName(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Contact email`)}
                                                            </Label>
                                                            <Input
                                                                id="contactEmail"
                                                                type="email"
                                                                className="form-control"
                                                                value={contactEmail}
                                                                onChange={event => setContactEmail(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Contact phone number`)}
                                                            </Label>
                                                            <Input
                                                                type="tel"
                                                                pattern="\d{10}"
                                                                maxLength={10}
                                                                className="form-control"
                                                                id="contactPhoneNumber"
                                                                value={contactPhoneNumber}
                                                                onChange={event => setContactPhoneNumber(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Contact Cellphone number`)}
                                                            </Label>
                                                            <Input
                                                                id="contactCellphone"
                                                                type="tel"
                                                                pattern="\d{10}"
                                                                maxLength={10}
                                                                className="form-control"
                                                                value={contactCellphone}
                                                                onChange={event => setContactCellphone(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} sm={12}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>{props.t(`Address Information`)}</CardTitle>

                                                <CardSubtitle className="mb-3">
                                                    {props.t(`Save the address information`)}.
                                                </CardSubtitle>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={8} sm={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col sm={4}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Country`)}
                                                            </Label>

                                                            <Input
                                                                id="country"
                                                                type="text"
                                                                className="form-control"
                                                                value={country}
                                                                onChange={event => setCountry(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={4}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`State`)}
                                                            </Label>

                                                            <Input
                                                                id="state"
                                                                type="text"
                                                                className="form-control"
                                                                value={state}
                                                                onChange={event => setState(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={4}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Zip Code`)}
                                                            </Label>
                                                            <Input
                                                                id="zipCode"
                                                                type="text"
                                                                className="form-control"
                                                                value={zipCode}
                                                                required
                                                                onChange={event => setZipCode(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`City`)}
                                                            </Label>
                                                            <Input
                                                                id="city"
                                                                type="text"
                                                                className="form-control"
                                                                value={city}
                                                                required
                                                                onChange={event => setCity(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Neighborhood`)}
                                                            </Label>
                                                            <Input
                                                                id="neighborhood"
                                                                type="text"
                                                                className="form-control"
                                                                value={neighborhood}
                                                                onChange={event => setNeighborhood(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Street Name`)}
                                                            </Label>
                                                            <Input
                                                                id="streetName"
                                                                type="text"
                                                                className="form-control"
                                                                value={streetName}
                                                                onChange={event => setStreetName(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Exterior No.`)}
                                                            </Label>
                                                            <Input
                                                                id="exteriorNumber"
                                                                type="number"
                                                                className="form-control"
                                                                value={exteriorNumber}
                                                                onChange={event => setExteriorNumber(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={3}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Interior No.`)}
                                                            </Label>
                                                            <Input
                                                                id="interiorNumber"
                                                                type="number"
                                                                className="form-control"
                                                                value={interiorNumber}
                                                                onChange={event => setInteriorNumber(event.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <Label>
                                                                {props.t(`Notes`)}
                                                            </Label>

                                                            <Input id="notes"
                                                                type="textarea"
                                                                className="form-control"
                                                                value={notes}
                                                                onChange={event => setNotes(event.target.value)} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))
