import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Form,
    Button
} from 'reactstrap'
import { withNamespaces } from 'react-i18next'
import ApiService from '../../../data/services/ApiService'
import Select from "react-select"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const _Company = (props) => {
    const regimenFiscales = [
        {
            value: '621',
            label: 'Incorporación Fiscal'
        },
        {
            value: '601',
            label: 'General de Ley Personas Morales'
        },
        {
            value: '612',
            label: 'Personas Físicas con Actividades Empresariales y Profesionales'
        },
        {
            value: '603',
            label: 'Personas Morales con Fines no Lucrativos'
        },
        {
            value: '605',
            label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios'
        },
        {
            value: '606',
            label: 'Arrendamiento'
        },
        {
            value: '608',
            label: 'Demás ingresos'
        },
        {
            value: '609',
            label: 'Consolidación'
        },
        {
            value: '610',
            label: 'Residentes en el Extranjero sin Establecimiento Permanente en México'
        },
        {
            value: '611',
            label: 'Ingresos por Dividendos (socios y accionistas)'
        },
        {
            value: '614',
            label: 'Ingresos por intereses'
        },
        {
            value: '616',
            label: 'Sin obligaciones fiscales'
        },
        {
            value: '620',
            label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'
        },
        {
            value: '622',
            label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'
        },
        {
            value: '623',
            label: 'Opcional para Grupos de Sociedades'
        },
        {
            value: '624',
            label: 'Coordinados'
        },
        {
            value: '628',
            label: 'Hidrocarburos'
        },
        {
            value: '607',
            label: 'Régimen de Enajenación o Adquisición de Bienes'
        },
        {
            value: '629',
            label: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'
        },
        {
            value: '630',
            label: 'Enajenación de acciones en bolsa de valores'
        },
        {
            value: '615',
            label: 'Régimen de los ingresos por obtención de premios'
        }
    ]

    const [company, setCompany] = useState({
        businessName: '',
        legalBusinessName: '',
        fiscalRegime: '',
        taxId: '',
        street: '',
        exteriorNumber: '',
        interiorNumber: '',
        neighborhood: '',
        zipCode: '',
        city: '',
        state: '',
        country: '',
        logo: '',
        email: '',
        phone: '',
        website: ''
    })

    useEffect(() => {
        const fr = regimenFiscales.find(item => {
            return item.value === props.company.fiscalRegime
        })

        setCompany({
            ...props.company,
            fiscalRegime: fr
        })
    }, []) // eslint-disable-line

    const _handleSubmit = async (e) => {
        try {
            e.preventDefault()
            props.setLoading(true)

            const rf = company.fiscalRegime.value
            const response = await ApiService.updateCompany({...company, fiscalRegime: rf })

            if (!response.data?.success) {
                toastr.error(props.t(response.data?.message))

                return
            }

            props.closeModal(false)
            toastr.success(props.t(`Company data updated`))
            props.updateCompany({...company, fiscalRegime: rf })
        } catch (error) {
            toastr.error(props.t(`I couldn't update your data`))
            console.log(error)
        } finally {
            props.setLoading(false)
        }
    }

    return (
        <Card>
            <Form id="create" method="POST" onSubmit={ _handleSubmit }>
                <CardBody>
                    <CardTitle>{props.t(`Company Details`)}</CardTitle>

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Business name`)} *
                                </Label>
                                <Input
                                    required
                                    type="text"
                                    className="form-control"
                                    value={company.businessName}
                                    onChange={event => setCompany({
                                        ...company,
                                        businessName: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Legal business name`)} *
                                </Label>
                                <Input
                                    required
                                    type="text"
                                    className="form-control"
                                    value={company.legalBusinessName}
                                    onChange={event => setCompany({
                                        ...company,
                                        legalBusinessName: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Tax Id`)} *
                                </Label>
                                <Input
                                    required
                                    type="text"
                                    className="form-control"
                                    value={company.taxId}
                                    onChange={event => setCompany({
                                        ...company,
                                        taxId: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Fiscal Regime`)} *
                                </Label>
                                <Select
                                    value={company.fiscalRegime}
                                    placeholder={`Selecciona un régimen fiscal`}
                                    onChange={(value) => { 
                                        setCompany({
                                            ...company,
                                            fiscalRegime: value
                                        })
                                    }}
                                    options={regimenFiscales}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label>
                                    {props.t(`E-Mail`)}
                                </Label>
                                <Input
                                    type="email"
                                    className="form-control"
                                    value={company.email}
                                    onChange={event => setCompany({
                                        ...company,
                                        email: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Phone`)}
                                </Label>
                                <Input
                                    type="tel"
                                    className="form-control"
                                    value={company.phone}
                                    onChange={event => setCompany({
                                        ...company,
                                        phone: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label>
                                    {props.t(`Website`)}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={company.website}
                                    onChange={event => setCompany({
                                        ...company,
                                        website: event.target.value
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Row>
                        <Col sm={12} style={{textAlign: 'right'}}>
                            <Button
                                type="submit"
                                color="primary"
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                            >
                                <i className={`mdi mdi-check`}></i> {props.t(`Update company`)}
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>
            </Form>
        </Card>
    )
}

export default withNamespaces()(_Company)