import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from 'reactstrap'
import { isEmpty } from 'lodash'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState('')
    const [familyId, setFamilyId] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')

    const [families, setFamilies] = useState([])

    useEffect(() => {
        if (!isEmpty(props.data)) {
            setId(props.data.id)
            setFamilyId(props.data.familyId)
            setName(props.data.name)
            setDescription(props.data.description)
            setImage(props.data.image)
        }
    }, [props.data && props.data.id]) // eslint-disable-line

    useEffect(() => {
        (async () => {
            await fetchFamilies()
        })()

        async function fetchFamilies() {
            setLoading(true)
            const response = await ApiService.getFamilies()
            let data = []

            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.name,
                })
            })

            setLoading(false)
            setFamilies(data)
        }
    }, []) // eslint-disable-line

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')
            return
        }

        let data = {
            familyId,
            name,
            description,
            image
        }

        setLoading(true)
        if (props.data !== undefined) {
            data = {...data, id}
            const response = await ApiService.updateCategory(data).catch(error => {
                setLoading(false)
                toastr.error('No pude editar esta categoría', 'Error')
            })

            if (response.data.success) {
                toastr.success('Edité una categoría', '¡Éxito!')
            }

            setLoading(false)
            return
        }

        const response = await ApiService.createCategory(data).catch(error => {
            toastr.error('No pude agregar esta categoría', 'Error')
        })

        if (response.data.success) {
            toastr.success('Agregué una categoría', '¡Éxito!')
        }

        setLoading(false)
        _clearForm()
    }

    const _validateForm = () => {
        if (name === '') { return false }

        return true
    }

    const _clearForm = () => {
        document.getElementById('create').reset()
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imagenes', 'Error')

            return
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {
            setImage(response.data.fileName)
            setLoading(false)
            toastr.success('Subí la imagen', '¡Éxito!')
        }).catch(error => {
            console.log('fileUpload', error)
            setLoading(false)
            toastr.error('No pude subir la imagen', 'Error')
        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('file', file)

        return ApiService.uploadCategoryImage(formData)
    }

    return (
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12}>
                        <Card style={{ textAlign: 'right' }}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label className="control-label">
                                                Familia *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={familyId}
                                                onChange={(event) => setFamilyId(event.target.value)}>
                                                <option key="0" value="0">{props.t(`Select a family`)}... </option> 
                                                {families.map((item, i) => {
                                                    let selected = familyId === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>
                                                Nombre *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="name">
                                                Descripción
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={description}
                                                onChange={event => setDescription(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Imagen</Label>

                                            <Input type="file"
                                                id="image"
                                                onChange={_handleFileUpload}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))