export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const SET_ACTIVE_COMPANY = "SET_ACTIVE_COMPANY"
export const SET_TOKEN = "SET_TOKEN"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const UPDATE_AVATAR = "UPDATE_AVATAR"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"