import React, { useState, useEffect } from 'react'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {
    Card,
    Table,
    Input,
    InputGroup,
    InputGroupAddon,
    CardTitle,
    Row,
    Col,
    Button
} from 'reactstrap'
import Select from "react-select"
import CurrencyFormat from 'react-currency-format'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import Modal from '../../../components/Modal'
import Payment from './Payment'

import Moment from 'moment'
registerLocale('es', es)

export default ({
    salesOrderId,
    setLoading,
    history,
    t
}) => {
    const [order, setOrder] = useState()
    const [lineItems, setLineItems] = useState([])
    const [branches, setBranches] = useState([])
    const [paymentModalOpen, setPaymentModalOpen] = useState(false)

    useEffect(() => {
        (async () => {
            await fetchBranches()
        })()
    }, []) // eslint-disable-line

    useEffect(() => {
        (async () => {
            const response  = await ApiService.getSalesOrderById(salesOrderId)

            if (!response.data.success) {
                toastr.error(t(`I couldn't find this order`), `Error!`)

                return
            }

            setOrder(response.data.order)
            setLineItems(response.data.order.lineItems)
        })()
        // eslint-disable-next-line
    }, [salesOrderId])

    async function fetchBranches() {
        try {
            const response = await ApiService.getBranches()
            let branches = []

            response.data.data.map((b) => {
                return branches.push({
                    value: b.id,
                    label: b.name,
                })
            })

            setBranches(branches)
        } catch (error) { }
    }

    const _cancelOrder = async (id) => {
        try {
            const result = await ApiService.cancelSalesOrder(id)

            if (!result.data.success) {
                toastr.error(t(`I couldn't delete this Sales order`), 'Error')

                return false
            }

            toastr.success(t(`I deleted this Sales order`), '¡Éxito!')
            window.analytics.track('Sales Order Canceled', {
                orderId: id
            })

            history.push(`/Sales-order`)
        } catch (error) {
            console.log(error)

            toastr.error(t(`I couldn't delete this Sales order`), 'Error')
        }
    }

    const __autoFill = async (e) => {
        let items = [...lineItems]

        if (order.status.codeName === 'ENTREGADA_EN_PARCIALIDADES') {
            // eslint-disable-next-line
            lineItems.map((item, i) => {
                items[i] = {
                    ...item,
                    itemsFulfilled: +item.quantity - +item.itemsFulfilled
                }
            })
        }

        if (order.status.codeName === 'PENDIENTE') {
            // eslint-disable-next-line
            lineItems.map((item, i) => {
                items[i] = {
                    ...item,
                    itemsFulfilled: item.quantity
                }
            })
        }

        setLineItems(items)
    }

    const __fulfillProducts = async (e) => {
        try {
            e.preventDefault()
            if (!validateReceive()) {
                toastr.info(t(`Don't forget to fill in the blanks`))

                return
            }

            setLoading(true)
            const response = await ApiService.saleOrderFulfillProducts(order.id, lineItems)

            if (response.data.code === 'E_CANT_FULFILL_ZERO_PRODUCTS') {
                toastr.warning(t(`Don't forget to input how many items are being fulfilled`))

                return
            }

            if (!response.data.success || response.data.code === 'E_ORDER_FULFILLED_OR_CANCELLED') {
                toastr.error(t(`I couldn't receive this Sales order`), 'Error')

                return
            }

            if (response.data.code === 'ORDER_CREATED') {
                toastr.success(t(`Order updated`), t('Success!'))

                setOrder(response.data.order)
                setLineItems(response.data.order.lineItems)
            }
        } catch (error) {
            toastr.error(t(`I couldn't process your request`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const validateReceive = () => {
        for (let i of lineItems) {
            if (i.itemsFulfilled < 1) { return false}
            if (i.branchId === undefined) { return false}
            if (i.dateDelivered === undefined) { return false }

            return true
        }
    }

    const __renderPaymentModal = () => (
        <Modal 
            title={t(`Select payment method`)}
            isOpen={paymentModalOpen}
            onRequestClose={() => {
                setPaymentModalOpen(false)
            }}
            body={
                <Payment order={order} closeModal={setPaymentModalOpen} updateOrder={setOrder} history={history} setLoading={setLoading} t={t} />
            }
        />
    )

    return !order ? (<React.Fragment></React.Fragment>) : (
        <React.Fragment>
            {paymentModalOpen && __renderPaymentModal()}

            <Card>
                <Row>
                    <Col sm={10}>
                        <nav className='Sales-orders-view-nav'>
                            {/* <a href={"/"}><i className='fa fa-copy'></i> {t(`Copy order`)}</a> */}
                            <a className="btn btn-link p-0 m-0 d-inline align-baseline" href={`/sales-order/print/${order.id}`} rel="noopener noreferrer" target="_blank">
                                <i className='mdi mdi-printer'></i> {t(`Print`)}
                            </a>
                            {/* <a href={"/"}><i className='mdi mdi-email'></i> {t(`Email`)}</a> */}
                            {/* <a href={"/"}>{t(`Convert to quote`)}</a> */}
                            {order.status.name !== 'Cancelada' && (
                                <a href={"/"}
                                    onClick={(e) => { 
                                        e.preventDefault()
                                        _cancelOrder(order.id)
                                }}>
                                    <i className='mdi mdi-cancel'></i> {t(`Cancel order`)}
                                </a>
                            )}
                        </nav>
                    </Col>

                    <Col sm={2}><strong>#PO-{order.folio}</strong> ({order.status.name})</Col>
                </Row>
            </Card>

            <Card>
                <CardTitle>
                    <Row>
                        <Col sm={6}>
                            {t(`Fulfill Products`)}
                        </Col>
                        <Col style={{textAlign: 'right'}} sm={6}>
                            {order.status.codeName !== 'ENTREGADA' && (
                                <React.Fragment>
                                    <Button
                                        onClick={(e) => { __autoFill(e) }}
                                        color="secondary"
                                        className="btn btn-secondary btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >{t(`Auto fill`)}</Button>

                                    <Button
                                        onClick={(e) => { __fulfillProducts(e) }}
                                        color="primary"
                                        className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >
                                        {t(`Fulfill`)}
                                    </Button>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                </CardTitle>
                <section className="items">
                    <Table responive="true">
                        <thead>
                            <tr>
                                <th>{t(`Product and SKU`)}</th>
                                <th>{t(`QTY`)}</th>
                                <th>{t(`Location`)}</th>
                                <th>{t(`Fulfillment date`)}</th>
                                <th>{t(`Unit price`)}</th>
                                <th>{t(`Discount`)}</th>
                                <th>{t(`Subtotal`)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems.map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        <strong>{item.product.name}</strong> <em>{item.product.sku}</em><br />
                                    </td>
                                    <td width={100}>
                                        {order.status.codeName === 'ENTREGADA' && (
                                            <span>{item.itemsFulfilled}</span>
                                        )}

                                        {order.status.codeName !== 'ENTREGADA' && (
                                            <Input 
                                                type='number'
                                                value={item.itemsFulfilled}
                                                onChange={(e) => {
                                                    const { value } = e.target
                                                    let importe = +value * +item.unitPrice
                                                    let discount = importe * (+item.discount / 100)
                                                    let subtotal = importe - discount
                                                    let taxAmount = 0
                                                    let scheme = item.product.product.taxScheme

                                                    for (let tax of scheme.value) {
                                                        taxAmount += +subtotal * +tax.taxRate
                                                    }

                                                    let total = +subtotal + +taxAmount
                                                    const items = [...lineItems]
                                                    items[i] = {
                                                        ...items[i],
                                                        itemsFulfilled: value,
                                                        subtotal,
                                                        discount,
                                                        taxAmount,
                                                        total,
                                                        scheme
                                                    }

                                                    setLineItems(items)
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td width={150}>
                                        {order.status.codeName === 'ENTREGADA' && (
                                            <span>{branches.length > 0 && branches.find(b => b.value === item.branchId.value).label}</span>
                                        )}

                                        {order.status.codeName !== 'ENTREGADA' && (
                                            <Select
                                                value={item.branchId}
                                                defaultValue={item.branchId}
                                                placeholder={t(`Location`)}
                                                onChange={(value) => { 
                                                    const items = [...lineItems]

                                                    items[i] = {
                                                        ...items[i],
                                                        branchId: value
                                                    }

                                                    setLineItems(items)
                                                }}
                                                options={branches}
                                                classNamePrefix="select2-selection"
                                            />
                                        )}
                                    </td>
                                    <td width={120}>
                                        {order.status.codeName === 'ENTREGADA' && (
                                            <span>{Moment(item.receivedDate).format("DD/MM/YYYY")}</span>
                                        )}

                                        {order.status.codeName !== 'ENTREGADA' && (
                                            <DatePicker
                                                locale="es"
                                                selected={item.dateDelivered ? new Date(item.dateDelivered) : ''}
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                onChange={date => {
                                                    const items = [...lineItems]

                                                    items[i] = {
                                                        ...items[i],
                                                        dateDelivered: date
                                                    }

                                                    setLineItems(items)
                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        )}
                                    </td>
                                    <td width={150}>
                                        {order.status.codeName === 'ENTREGADA' && (
                                            <React.Fragment><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitPrice} /></React.Fragment>
                                        )}

                                        {order.status.codeName !== 'ENTREGADA' && (
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                <Input
                                                    type="number"
                                                    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                    value={item.unitPrice}
                                                    className="form-control" 
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        let importe = +item.itemsFulfilled * +value
                                                        let discount = importe * (+item.discount / 100)
                                                        let subtotal = importe - discount
                                                        let taxAmount = 0
                                                        let scheme = item.product.product.taxScheme

                                                        for (let tax of scheme.value) {
                                                            taxAmount += +subtotal * +tax.taxRate
                                                        }

                                                        let total = +subtotal + +taxAmount
                                                        const items = [...lineItems]
                                                        items[i] = {
                                                            ...items[i],
                                                            unitPrice: value,
                                                            subtotal,
                                                            discount,
                                                            taxAmount,
                                                            total,
                                                            scheme
                                                        }

                                                        setLineItems(items)
                                                    }}
                                                    />
                                            </InputGroup>
                                        )}
                                    </td>
                                    <td width={150}>
                                        {order.status.codeName === 'ENTREGADA' && (
                                            <React.Fragment><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.discount} /></React.Fragment>
                                        )}

                                        {order.status.codeName !== 'ENTREGADA' && (
                                            <InputGroup>
                                                <Input
                                                    type="number"
                                                    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                    className="form-control"
                                                    value={item.discount}
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        let importe = +item.itemsFulfilled * +item.unitPrice
                                                        let discount = importe * (+value / 100)
                                                        let subtotal = importe - discount
                                                        let taxAmount = 0
                                                        let scheme = item.product.product.taxScheme

                                                        for (let tax of scheme.value) {
                                                            taxAmount += +subtotal * +tax.taxRate
                                                        }

                                                        let total = +subtotal + +taxAmount
                                                        const items = [...lineItems]
                                                        items[i] = {
                                                            ...items[i],
                                                            discount: value,
                                                            subtotal,
                                                            taxAmount,
                                                            total,
                                                            scheme
                                                        }

                                                        setLineItems(items)
                                                    }}
                                                />
                                                <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                                            </InputGroup>
                                        )}
                                    </td>
                                    <td>
                                        <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={(item.unitPrice * item.itemsFulfilled) - (item.unitPrice * item.itemsFulfilled) * (item.discount / 100)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{textAlign: 'right'}} colSpan={7}>{t(`Subtotal`)}:</td>
                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal, 0)} /></td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}} colSpan={7}>{t(`Discount`)}:</td>
                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)} /></td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}} colSpan={7}>{t(`Tax rate`)}:</td>
                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.taxAmount, 0)} /></td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'right'}} colSpan={7}>{t(`Total`)}:</td>
                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.total, 0)} /></td>
                            </tr>
                            {order.paid && (
                                <React.Fragment>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={7}>{t(`Paid`)}:</td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.payments.reduce((a, b) => a + +b.amountPaid, 0)} /></td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={7}>{t(`Balance`)}:</td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={+lineItems.reduce((a, b) => a + +b.total, 0) - +order.payments.reduce((a, b) => a + +b.amountPaid, 0)} /></td>
                                    </tr>
                                </React.Fragment>
                            )}
                            {!order.paid && (
                                <React.Fragment>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={7}>{t(`Paid`)}:</td>
                                        <td>
                                            <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.payments.reduce((a, b) => a + +b.amountPaid, 0)} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={7}>{t(`Balance`)}:</td>
                                        <td>
                                            <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={+lineItems.reduce((a, b) => a + +b.total, 0) - +order.payments.reduce((a, b) => a + +b.amountPaid, 0)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={8}>
                                            <Button
                                                color="primary"
                                                // disabled={order.amountPaid < 1}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPaymentModalOpen(true)
                                                }}
                                                className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                            >
                                                {!order.paid && (
                                                    <React.Fragment>
                                                        {t(`Pay`)}
                                                    </React.Fragment>
                                                )}
                                            </Button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}
                        </tfoot>
                    </Table>
                </section>
            </Card>
        </React.Fragment>
    )
}