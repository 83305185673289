import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    SET_ACTIVE_COMPANY,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    UPDATE_AVATAR,
    UPDATE_PROFILE,
    UPDATE_COMPANY,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    SET_TOKEN
} from "./actionTypes"
import { loadState } from "../../localStorage"

const persistedState = loadState()
const initialState = persistedState ? persistedState?.Auth : {
    profile: null,
    activeCompany: null,
    error: null
}

const LoginReducer = (state = initialState, action) => {
    const { data } = action

    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                profile: data,
                loading: false,
            }
        case SET_TOKEN:
            return {
                ...state,
                token: data,
                loading: false
            }
        case SET_ACTIVE_COMPANY:
            return {
                ...state,
                activeCompany: data,
                loading: false
            }
        case LOGOUT_USER:
            state = { ...state }

            break
        case LOGOUT_USER_SUCCESS:
            return {
                profile: null,
                activeCompany: null,
                error: null
            }
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        case UPDATE_PASSWORD:
            state = {
                ...state,
                loading: true
            }

            break
        case UPDATE_PASSWORD_SUCCESS:
            state = {
                ...state,
                success: true,
                loading: false
            }

            break
        case UPDATE_AVATAR:
            return {
                ...state,
                profile: { ...state.profile, avatar: data },
                loading: false
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                profile: data,
                loading: false
            }
        case UPDATE_COMPANY:
            return {
                ...state,
                activeCompany: data,
                loading: false
            }
        default:
            return { ...state }
    }
    return state
}

export default LoginReducer
