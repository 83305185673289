import React, { useEffect, useState, useContext } from "react"
import {
    Container,
    Card,
    CardTitle,
    CardBody,
    Col,
    Row,
    FormGroup,
    Label
} from "reactstrap"
import { withNamespaces } from "react-i18next"
import BulkImportModify from './components/BulkUpdateProducts'
import ApiService from '../../data/services/ApiService'
import Select from "react-select"
import { GeneralContext } from '../../context'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import moment from 'moment'
import { saveStream } from '../../helpers/utils'

moment.locale('es')
const Import = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext

    const [suppliers, setSuppliers] = useState([])
    const [brands, setBrands] = useState([])
    const [families, setFamilies] = useState([])
    
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [selectedBrands, setSelectedBrands] = useState([])
    const [selectedFamilies, setSelectedFamilies] = useState([])

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Inventory`),
            parentLink: '/products',
            item: props.t(`Update Products`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            await getSuppliers()
            await getBrands()
            await getFamilies()
        })()
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSuppliers = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getSuppliers()
            let data = []

            // eslint-disable-next-line
            response.data.suppliers.map((item) => {
                data.push({
                    value: item.id,
                    label: item.businessName
                })
            })

            setSuppliers(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const getBrands = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getBrands()
            let data = []

            // eslint-disable-next-line
            response.data.data.map((item) => {
                data.push({
                    value: item.id,
                    label: item.name
                })
            })

            setBrands(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const getFamilies = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getFamilies()
            let data = []

            // eslint-disable-next-line
            response.data.data.map((item) => {
                data.push({
                    value: item.id,
                    label: item.name
                })
            })

            setFamilies(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const __exportProducts = async (e) => {
        try {
            setLoading(true)
            const response = await ApiService.exportProducts({
                suppliers: selectedSuppliers.map(s => s.value),
                brands: selectedBrands.map(s => s.value),
                families: selectedFamilies.map(s => s.value)
            })

            saveStream(
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                `filtered-products-template-${moment().format('DD-MM-YYYY_HH-mm-ss')}.csv`,
                response.data
            )
        } catch (error) {
            toastr.error(error.response.data.message, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _renderStep1 = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col sm={6} xs={12}>
                        <Card>
                            <CardBody>
                                <CardTitle>Exportar Catálogo de Productos</CardTitle>

                                <FormGroup>
                                    <Label>{props.t(`Suppliers`)}</Label>

                                    <Select
                                        value={selectedSuppliers}
                                        placeholder={props.t(`Select one or more suppliers`)}
                                        onChange={(value) => { 
                                            setSelectedSuppliers(value)
                                        }}
                                        isMulti={true}
                                        options={suppliers}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>{props.t(`Brands`)}</Label>

                                    <Select
                                        value={selectedBrands}
                                        placeholder={props.t(`Select one or more brands`)}
                                        onChange={(value) => { 
                                            setSelectedBrands(value)
                                        }}
                                        isMulti={true}
                                        options={brands}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>{props.t(`Families`)}</Label>

                                    <Select
                                        value={selectedFamilies}
                                        placeholder={props.t(`Select one or more families`)}
                                        onChange={(value) => { 
                                            setSelectedFamilies(value)
                                        }}
                                        isMulti={true}
                                        options={families}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>

                                <button
                                    className="btn btn-secondary waves-effect waves-light mb-2 mr-2"
                                    onClick={__exportProducts}
                                    disabled={false}
                                >
                                    <i className="mdi mdi-download mr-1"></i> Descargar Template
                                </button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} xs={12}>
                        <Card>
                            <CardBody>
                                <BulkImportModify />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    { _renderStep1() }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces()(Import)