import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter
} from 'reactstrap'
import Moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import CurrencyFormat from 'react-currency-format'
import Sidemodal from '../../../components/Sidemodal'
import View from './View'

const Table = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [modalOpen, setModalOpen] = useState(false)
    const [currentPurchaseQuoteId, setCurrentPurchaseQuoteId] = useState()
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await _fetchData()
        })()
    }, []) // eslint-disable-line

    const _fetchData = async () => {
        setLoading(true)
        try {
            const response = await ApiService.getPurchaseQuote()

            setFilteredData(response.data.quotes)
            setLoading(false)
        } catch (error) {
            toastr.error(props.t(`Oop's! I couldn't find any purchase quotes`))
        } finally {
            setLoading(false)
        }
    }

    const __renderPOView = () => (
        <Sidemodal
            className="xxl"
            title={props.t(`Purchase Quote`)}
            isOpen={modalOpen}
            onRequestClose={() => {
                setModalOpen(false)
            }}
            body={<View t={props.t} history={props.history} setLoading={setLoading} purchaseQuoteId={currentPurchaseQuoteId} />} />
    )

    return(
        <React.Fragment>
            <Row>
                <Col style={{ textAlign: 'center', }} md={4} sm={12} xs={12}>
                    <Link to="/purchase-quote/new">
                        <img style={{ width: '60%', paddingTop: '64px' }} src="/assets/images/add-item.svg" alt={``} />
                    </Link>
                </Col>
                {filteredData && filteredData.map((item, key) => (
                    <Col key={key} md={4} sm={12} xs={12}>
                        <Card
                            style={{cursor: 'pointer'}}
                            onClick={(e) => {    
                                e.preventDefault()
                                setModalOpen(!modalOpen)
                                setCurrentPurchaseQuoteId(item.id)
                            }}
                        >
                            <CardTitle>
                                <Row>
                                    <Col sm={6}>#PQ-{item.folio}</Col>
                                    <Col sm={6} style={{ textAlign: 'right' }}>({Moment(item.createdAt).format("DD/MM/YYYY")})</Col>
                                </Row>
                            </CardTitle>

                            <CardBody>
                                <strong>{props.t(`Units`)}: </strong> {item.lineItems.length}<br />
                                <strong>{props.t(`Expiration date`)}: </strong> {Moment(item.expirationDate).format("DD/MM/YYYY")}<br />
                                <strong>{props.t(`Total`)}: </strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.total} /> <br /><br />
                                <strong>{props.t(`Supplier`)}: </strong> {item.supplier.businessName}
                            </CardBody>
                            <CardFooter className={`purchase-order-status-label-${item.status.codeName}`}>
                                <i className='fa fa-circle'></i> {props.t(item.status.name)}
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>

            {modalOpen && __renderPOView()}
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Table))