import React, { useContext } from 'react'
import { GeneralContext } from '../context/'
import { Route, Redirect } from "react-router-dom"
import Loading from '../components/Loading'
import { loadState } from '../store/localStorage'

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => {
    const generalContext = useContext(GeneralContext)
	const {
		loading,
    } = generalContext

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthProtected && !loadState()?.Auth?.token) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }

                return (
                    <Layout>
                        <Component {...props} />
                        {loading && <Loading />}
                    </Layout>
                )
            }}
        />
    )
}

export default AppRoute
