import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Button,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormGroup,
    Label,
    Input
} from "reactstrap"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import Modal from '../../../components/Modal'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import CurrencyFormat from 'react-currency-format'
import Select from "react-select"
import paymentTypeCatalog from '../../../data/paymentTypes'

const Actions = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, folio, cartItems, activePriceList, getCartTotals, activeCustomer, activeBranch } = generalContext
    const [bankAccounts, setBankAccounts] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [accountId, setAccountId] = useState({value: '', label: ''})
    const [showLastForDigits, setShowLastFourDigits] = useState(false)
    const [lastFourDigits, setLastFourDigits] = useState('')
    const [paymentType, setPaymentType] = useState({value: '01', label: 'Efectivo'})
    const [amountPaid, setAmountPaid] = useState()
    const [retorno, setRetorno] = useState()
    const { subtotal, taxAmount, total } = getCartTotals()

    useEffect(() => {
        (async () => {
            await fetchBankAccounts()
        })()
    }, []) // eslint-disable-line

    const fetchBankAccounts = async () => {
        try {
            setLoading(true)
            const response = await ApiService.getBankAccounts()

            let data = []
            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.alias
                })
            })

            setBankAccounts(data)
            setAccountId(data[0])
        } catch (error) {
            setLoading(false)
            toastr.error(props.t(`I couldn't fetch your bank accounts`))
        } finally {
            setLoading(false)
        }
    }

    const __validateSale = () => {
        if (!activePriceList) { 
            toastr.error(props.t(`Please select one price list`))

            return false 
        }

        if (cartItems.length < 1) { 
            toastr.error(props.t(`Don't forget to add items to sale`))

            return false 
        }

        return true
    }

    const _saveOperation = async (e) => {
        try {
            e.preventDefault()
            if (!__validateSale()) { return }

            const { subtotal, discount, taxAmount, total } = getCartTotals()
            const response = await ApiService.saveSale({ 
                folio,
                branchId: activeBranch.value,
                priceTypeId: activePriceList.id,
                accountId: accountId.value,
                customerId: activeCustomer.value,
                subtotal,
                discount,
                taxAmount,
                total,
                paymentType: paymentType.value,
                amountPaid,
                lastFourDigits,
                lineItems: cartItems
             })

            if (!response.data.success) {
                // _o_ 
                toastr.error(props.t(`There was an error saving this sale`), props.t(`Oop's!`))
                return
            }

            // \o/
            toastr.success(props.t(`Sale saved succesfully`), props.t(`Success!`))

            const w = window.open(`/sales/print/${response.data.sale.id}`, 'Nota de Venta', "width=480,height=960,menubar=0,status=0,scrollbars=1,resizable=1")

            if (!w) {
                toastr.info(props.t(`I couldn't open your ticket, make sure to give your browser permissions`))
            }

            window.location.reload(false)
        } catch (error) {
            toastr.error(props.t(`There was an error saving this sale`), props.t(`Oop's!`))
        }
    }

    const __renderPaymentModal = () => (
        <Modal
            title={props.t(`Select payment method`)}
            isOpen={modalOpen}
            onRequestClose={() => {
                setModalOpen(false)
            }}
            body={
                <React.Fragment>
                    <Row>
                        <Col style={{
                            padding: '16px 24px',
                            background: '#496ddb',
                            textAlign: 'right',
                            color: 'white'
                        }} sm={12}>
                            <span style={{
                                fontSize: '36px'
                            }}>
                                {!retorno && (
                                    <CurrencyFormat
                                        decimalScale={2}
                                        displayType="text"
                                        thousandSeparator
                                        prefix="$"
                                        value={total} />
                                )}

                                {retorno && (
                                    <React.Fragment>
                                        {props.t(`Return to customer`)}: <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={retorno} />
                                    </React.Fragment>
                                )}
                            </span>

                            <span style={{
                                display: 'block',
                                fontSize: '12px'
                            }}>
                                Subtotal: <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={subtotal} /> |
                                IVA: <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={taxAmount} /></span>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>{props.t(`Amount paid`)}</Label>

                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            $
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input
                                        autoFocus={true}
                                        id="amountPaid"
                                        type="text"
                                        value={amountPaid}
                                        placeholder={props.t(`Leave in blank if payment amount is exact`)}
                                        onChange={(e) => {
                                            const { value } = e.target 
                                            if (value > total) {
                                                const retorno = value - total
                                                setRetorno(retorno)
                                            }

                                            setAmountPaid(value)
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value !== '' && e.target.value < total) {
                                                toastr.warning(props.t(`Make sure customer pays the full amount.`))
                                                document.getElementById("amountPaid").focus()
                                            }
                                        }}
                                        />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="paymentType">
                                    {props.t(`Bank Account`)}
                                </Label>

                                <Select
                                    id="bankAccount"
                                    value={accountId}
                                    placeholder={props.t(`Select a bank account`)}
                                    onChange={(value) => { 
                                        setAccountId(value)
                                    }}
                                    options={bankAccounts}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="paymentType">
                                    {props.t(`Payment type`)}
                                </Label>

                                <Select
                                    id="paymentType"
                                    value={paymentType}
                                    placeholder={props.t(`Select a payment type`)}
                                    onChange={(value) => {
                                        if (
                                            value.value === '04' ||
                                            value.value === '05' ||
                                            value.value === '06' ||
                                            value.value === '08' ||
                                            value.value === '28' ||
                                            value.value === '29'
                                            ) {
                                            setShowLastFourDigits(true)
                                        }

                                        setPaymentType(value)
                                    }}
                                    options={paymentTypeCatalog}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>

                        {showLastForDigits && (
                            <Col sm={6}>
                                <FormGroup>
                                    <Label htmlFor="paymentType">
                                        {props.t(`Last Four Digits`)}
                                    </Label>

                                    <Input
                                        id="lastFourDigits"
                                        type="text"
                                        value={lastFourDigits}
                                        placeholder={props.t(`Input card's last four digits`)}
                                        onChange={(e) => {
                                            setLastFourDigits(e.target.value)
                                        } }
                                        />
                                </FormGroup>
                            </Col>
                        )}
                    </Row>
                </React.Fragment>
            }
            footer={
                <React.Fragment>
                    <Button color="secondary" size='md' onClick={() => { setModalOpen(false) }}>{props.t(`Cancel`)}</Button>
                    <Button color="primary" size='md' onClick={_saveOperation}>{props.t(`Save payment`)}</Button>
                </React.Fragment>
            }
        />
    )

    return (
        <Row style={{textAlign: 'right'}}>
            {modalOpen && __renderPaymentModal()}

            <Col sm={12}>
                <Button 
                    onClick={() => {
                        if (cartItems.length < 1) {
                            toastr.warning(props.t(`Don't forget to add items to sell`))

                            return false
                        }

                        setModalOpen(true)
                    }}
                    color="primary" className='btn btn-primary' size="lg">Cobrar Venta</Button>
            </Col>
        </Row>
    )
}

export default withRouter(withNamespaces()(Actions))