import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardBody,
    CardFooter
} from "reactstrap"

import { GeneralContext } from '../../../context'

import { Link } from "react-router-dom"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

import ApiService from '../../../data/services/ApiService'

const DataTable = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getUsers()

            setData(response.data.users)
            setFilteredData(response.data.users)
        } catch (error) {
            toastr.error(`No pude comunicarme con el servidor de Timbrát`, 'Problema de Conexión')
        } finally {
            setLoading(false)
        }
    }

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const firstName = item.firstName || ''
            const email = item.email || ''
            const lastName = item.lastName || ''
            const phone = item.phone || ''

            return firstName.toLowerCase().includes(keystroke) ||
                lastName.toLowerCase().includes(keystroke) ||
                email.toLowerCase().includes(keystroke) ||
                phone.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        setLoading(true)

        try {
            await ApiService.deleteUser(id)
            const filtered = await data.filter((v) => { return v.id !== id })

            setData(filtered)
            setFilteredData(filtered)

            window.analytics.track('User deleted')
            toastr.success(props.t(`User Deleted`), '¡Éxito!')
        } catch (error) {
            console.log(error)
            toastr.error(`No pude comunicarme con el servidor`, 'Problema de Conexión')
        } finally {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control"
                                placeholder={props.t(`Search`)}
                                onKeyUp={(e) => _filterData(e)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                </Col>
                <Col sm="8">
                    <div className="text-sm-right">
                        <Link to={`/users/add`} className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2">
                            <i className="mdi mdi-plus mr-1"></i> {props.t(`Add User`)}
                        </Link>
                    </div>
                </Col>
            </Row>

            <Row>
                {filteredData.map(
                    (item, key) => (
                        <Col md={6} key={key}>
                            <Card key={key}>
                                <CardTitle>{item.firstName} {item.lastName}</CardTitle>
                                <CardBody>
                                    <strong>E-Mail:</strong> {item.email}<br />
                                    <strong>Phone:</strong> {item.phone}<br />
                                </CardBody>
                                <CardFooter className="bg-transparent border-top">
                                    <div className="d-flex font-size-20">
                                        <div className="flex-fill">
                                            <Link id={'edit'} to={`/users/edit/${item.id}`}>
                                                <i className="mdi mdi-pencil"></i> {props.t(`Edit`)}
                                            </Link>
                                        </div>

                                        <div className="flex-fill">
                                            <Link id={'delete'} to={`#`} onClick={(e) => {
                                                e.preventDefault()
                                                _deleteItem(item.id)
                                            }}>
                                                <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                            </Link>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    )
                )}
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(DataTable))