class ProductHelper {
    getVariantPriceByPriceId(variant, priceTypeId) {
        const price = variant.prices.filter(p => p.priceTypeId === priceTypeId)

        return price.length > 0 ? price[0].amount : 0
    }

    calculateTotals({ quantity, unitPrice, discountRate, scheme }) {
        let taxAmount = 0
        let subtotal = 0
        for (let tax of scheme.value) {
            if (scheme.taxInclusive) {
                unitPrice = +unitPrice / (+tax.taxRate + 1)
                subtotal = +quantity * +unitPrice
            }

            taxAmount += +subtotal * +tax.taxRate
        }

        const discount = +subtotal * (+discountRate / 100)
        const total = (+subtotal - +discount) + +taxAmount

        return {
            subtotal,
            discount,
            taxAmount,
            unitPrice,
            total
        }
    }
}

export default new ProductHelper()