import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Button,
    FormGroup
} from "reactstrap"
import { isEmpty, head, filter, debounce } from 'lodash'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import Modal from '../../../components/Modal'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import ProductHelper from '../../../helpers/ProductHelper'
import DefaultImage from '../../../assets/images/avatar-product-placeholder.png'
import CurrencyFormat from 'react-currency-format'

const ProductList = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, addItemToCart, activePriceList } = generalContext
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [seriesModalOpen, setSeriesModalOpen] = useState(false)
    const [productInUse, setProductInUse] = useState()
    const [tempSeries, setTempSeries] = useState('')
    const debouncedSearch = useRef(
        debounce(async (criteria, activePriceList) => {
            const response = await ApiService.searchVariants(criteria, activePriceList.id)

            setFilteredProducts(response.data.products)
        }, 300)
    ).current

    useEffect(() => {
        (async () => {
            if (activePriceList.id !== undefined) {
                await fetchProducts(activePriceList.id)
            }
        })()
    }, [activePriceList?.id]) // eslint-disable-line

    useEffect(() => {
        (() => {
          debouncedSearch.cancel()
        })()
    }, [debouncedSearch]) // eslint-disable-line

    async function fetchProducts(priceTypeId) {
        try {
            setLoading(true)
            const response = await ApiService.getPopularProducts(priceTypeId)

            setProducts(response.data.products)
            setFilteredProducts(response.data.products)
        } catch (error) {
            // catch error
            toastr.error(props.t(`I couldn't fetch your products`), props.t(`Oop's!`))
        } finally {
            setLoading(false)
        }
    }

    const _filterProducts = async (value) => {
        if (value === '') {
            setFilteredProducts(products)

            return
        }

        try {
            setLoading(true)
            debouncedSearch(value, activePriceList)
        } catch (error) {
            // catch error
            toastr.error(props.t(`I couldn't fetch your products`), props.t(`Oop's!`))
        } finally {
            setLoading(false)
        }
    }

    const __findAndAddProduct = async (criteria) => {
        try {
            setLoading(true)
            const response = await ApiService.searchVariants(criteria, activePriceList.id)
            if (response.data.products.length === 1) {
                const product = response.data.products[0]

                if (product.product.productType === 'serialized') {
                    setProductInUse(product)
                    setSeriesModalOpen(true)
                } else {
                    __addItemToCart(product, false)
                }

                document.getElementById("searchInput").value = ''
                setFilteredProducts(response.data.products)
            }
        } catch (error) {
            // catch error
        } finally {
            setLoading(false)
        }
    }

    const _renderProductAvatar = (product) => {
        if (isEmpty(product.gallery)) {
            return( <img alt={``} style={{maxWidth: '128px'}} src={DefaultImage} /> )
        }

        const image = filter(product.gallery, { featured: true })
        if (isEmpty(head(image))) {
            return (<img alt={``} style={{maxWidth: '128px'}} src={head(product.gallery).fileName || DefaultImage} />)
        }

        return (
            <div style={{textAlign: 'center'}}>
                <img style={{maxWidth: '128px'}}  alt={head(image).id} src={`${head(image).fileName}`} />
            </div>
        )
    }

    const __addItemToCart = (product, serie) => {
        let quantity = 1
        let discount = 0.00
        let scheme = product.product.taxScheme
        let unitPrice = ProductHelper.getVariantPriceByPriceId(product, activePriceList.id) 
        let { subtotal, taxAmount, total } = ProductHelper.calculateTotals({
            unitPrice,
            quantity,
            discountRate: discount,
            scheme
        })

        addItemToCart({
            product,
            quantity,
            discount,
            taxAmount: taxAmount,
            itemsFulfilled: 0,
            unitCost: product.unitCost,
            unitPrice,
            subtotal,
            serie,
            total,
            scheme
        })
    }

    const __renderSeriesModal = () => (
        <Modal
            title={props.t(`Scan barcode or write series`)}
            isOpen={seriesModalOpen}
            onRequestClose={() => {
                setSeriesModalOpen(false)
            }}
            body={
                <React.Fragment>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Input
                                    autoFocus={true}
                                    id='series'
                                    type="text"
                                    value={tempSeries}
                                    style={{ height: 100 }}
                                    placeholder={props.t(`scan barcode or write series`)}
                                    onChange={e => setTempSeries(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </React.Fragment>
            } 
            footer={
                <Row className='form-actions'>
                    <Col sm={12}>
                        <Button color='primary' onClick={(e) => {
                            e.preventDefault()
                            if (tempSeries === '') {
                                toastr.warning(props.t(`Please input the product's series or batch`))
                                return
                            }

                            __addItemToCart(productInUse, tempSeries)
                            setProductInUse(null)
                            setSeriesModalOpen(false)
                            setTempSeries('')
                        }}>
                            {props.t(`Continue`)}
                        </Button>
                    </Col>
                </Row>
            }/>
    )

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fa fa-barcode" aria-hidden="true" /> / <i className="fa fa-search" aria-hidden="true" />
                            </InputGroupText>
                        </InputGroupAddon>

                        <Input
                            autoFocus={true}
                            id="searchInput"
                            type={`search`}
                            className="form-control"
                            style={{zIndex: '0'}}
                            placeholder={props.t(`Search products or scan barcode`)} 
                            onKeyUp={(e) => _filterProducts(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Tab') {
                                    e.preventDefault()
                                    __findAndAddProduct(e.target.value, activePriceList)
                                }
                            }}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row style={{paddingTop: '24px'}}>
                {filteredProducts.map((product, i) => {
                    return (
                        <Col className='product' key={i} xs={12} sm={6} md={4} lg={4}
                            style={{padding: '16px', textAlign: 'center'}}>
                            <a style={{color: '#464b56'}} href="/pos" onClick={(e) => {
                                e.preventDefault()
                                if (product.product.productType === 'serialized') {
                                    setProductInUse(product)
                                    setSeriesModalOpen(true)
                                } else {
                                    __addItemToCart(product)
                                }

                                document.getElementById("searchInput").focus()
                            }}>
                                <figure className='product-photo'>{_renderProductAvatar(product)}</figure>
                                <span className='product-name'>{product.name}</span> <br />
                                <span className='product-price'>
                                    <CurrencyFormat
                                        displayType="text"
                                        thousandSeparator
                                        decimalScale={2}
                                        prefix="$"
                                        value={(() => {
                                            let unitPrice = ProductHelper.getVariantPriceByPriceId(product, activePriceList.id)
                                            let { taxInclusive, value } = product.product.taxScheme

                                            if (taxInclusive) {
                                                let taxAmount = 0
                                                for (let tax of value) {
                                                    taxAmount += (+unitPrice / (+tax.taxRate + 1)) * tax.taxRate
                                                }

                                                return +unitPrice - +taxAmount
                                            }

                                            return unitPrice
                                        })()} />
                                </span>
                            </a>
                        </Col>
                    )
                })}
            </Row>

            {seriesModalOpen && __renderSeriesModal()}
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(ProductList))