import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from 'reactstrap'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState(props.data)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setData(data)
            setName(data.name)
            setDescription(data.description)
            setImage(data.image)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, data?.id)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        const data = {
            name,
            description,
            image
        }
        setLoading(true)
        if (props.data !== undefined) { 
            data.id = props.data.id
            const response = await ApiService.updateFamily(data).catch(error => {
                toastr.error('No pude editar esta familia', 'Error')
                setLoading(false)
            })

            if (response.data.success) {
                toastr.success('Edité una familia', '¡Éxito!')
            }
            setLoading(false)
            return
        }

        const response = await ApiService.createFamily(data).catch(error => {
            toastr.error('No pude agregar esta familia', 'Error')
        })

        if (response.data.success) {
            toastr.success('Agregué una familia', '¡Éxito!')
        }
        setLoading(false)
        _clearForm()
    }

    const _validateForm = () => {
        if (name === '') { return false }

        return true
    }

    const _clearForm = () => {
        document.getElementById('create').reset()
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imagenes', 'Error')

            return 
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {
            setImage(response.data.fileName)
            setLoading(false)
            toastr.success('Subí la imagen', '¡Éxito!')
        }).catch(error => {
            console.log('fileUpload', error)
            setLoading(false)
            toastr.error('No pude subir la imagen', 'Error')
        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('file', file)

        return ApiService.uploadFamilyImage(formData)
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                Nombre *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="name">
                                                Descripción
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={description}
                                                onChange={event => setDescription(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Imagen</Label>

                                            <Input type="file"
                                                id="image"
                                                onChange={ _handleFileUpload }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))