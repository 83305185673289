import React, { useState, useEffect } from 'react'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {
    Card,
    Table,
    Input,
    InputGroup,
    InputGroupAddon,
    CardTitle,
    Row,
    Col,
    Button
} from 'reactstrap'
import Select from "react-select"
import CurrencyFormat from 'react-currency-format'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import { isEmpty } from 'lodash'

import Moment from 'moment'
registerLocale('es', es)

export default ({
    purchaseOrderId,
    setLoading,
    history,
    t
}) => {
    const [order, setOrder] = useState()
    const [lineItems, setLineItems] = useState([])
    const [branches, setBranches] = useState([])

    useEffect(() => {
        (async () => {
            await fetchBranches()
        })()
    }, []) // eslint-disable-line

    useEffect(() => {
        (async () => {
            const response  = await ApiService.getPurchaseOrderById(purchaseOrderId)

            if (!response.data.success) {
                toastr.error(t(`I couldn't find this order`), `Error!`)

                return
            }

            setOrder(response.data.order)
            setLineItems(response.data.order.lineItems)
        })()
        // eslint-disable-next-line
    }, [purchaseOrderId])

    async function fetchBranches() {
        try {
            const response = await ApiService.getBranches()
            let branches = []

            response.data.branches.map((b) => {
                return branches.push({
                    value: b.id,
                    label: b.branchName,
                })
            })

            setBranches(branches)
        } catch (error) { }
    }

    const _cancelOrder = async (id) => {
        try {
            const result = await ApiService.cancelPurchaseOrder(id)

            if (!result.data.success) {
                toastr.error(t(`I couldn't delete this purchase order`), 'Error')

                return false
            }

            toastr.success(t(`I deleted this purchase order`), '¡Éxito!')
            window.analytics.track('Purchase Order Canceled', {
                orderId: id
            })

            history.push(`/purchase-order`)
        } catch (error) {
            console.log(error)

            toastr.error(t(`I couldn't delete this purchase order`), 'Error')
        }
    }

    const __markOrderAsPaid = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)
            const response = await ApiService.markOrderAsPaid(order)

            if (!response.data.success) {
                toastr.error(t(`I couldn't mark as paid this purchase order`), 'Error')
                return
            }

            setOrder({
                ...order,
                paid: response.data.paid
            })

            toastr.success(t(`Order marked as paid`), t('Success!'))
        } catch (error) {
            toastr.error(t(`I couldn't mark as paid this purchase order`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const __autoFill = async (e) => {
        let items = [...lineItems]

        // eslint-disable-next-line
        lineItems.map((item, i) => {
            items[i] = {
                ...item,
                itemsReceived: item.quantity
            }
        })

        setLineItems(items)
    }

    const __receiveProducts = async (e) => {
        try {
            e.preventDefault()
            if (!validateReceive()) {
                toastr.info(t(`Don't forget to fill in the blanks`))

                return
            }

            setLoading(true)
            const response = await ApiService.receiveProducts(order.id, lineItems)

            if (!response.data.success || response.data.code === 'E_ORDER_FULFILLED_OR_CANCELLED') {
                toastr.error(t(`I couldn't receive this purchase order`), 'Error')

                return
            }

            if (response.data.code === 'ORDER_CREATED') {
                toastr.success(t(`Order updated`), t('Success!'))

                setOrder(response.data.order)
                setLineItems(response.data.order.lineItems)
            }
        } catch (error) {
            toastr.error(t(`I couldn't process your request`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const validateReceive = () => {
        for (let i of lineItems) {
            if (i.itemsReceived < 1) { return false }
            if (i.branchId === undefined) { return false}
            if (i.receivedDate === undefined) { return false }

            return true
        }
    }

    return !order ? (<React.Fragment></React.Fragment>) : (
        <React.Fragment>
            <Card>
                <Row>
                    <Col sm={10}>
                        <nav className='purchase-orders-view-nav'>
                            {/* <a href={"/"}><i className='fa fa-copy'></i> {t(`Copy order`)}</a> */}
                            <a className="btn btn-link p-0 m-0 d-inline align-baseline" href={`/purchase-order/print/${order.id}`} rel="noopener noreferrer" target="_blank">
                                <i className='mdi mdi-printer'></i> {t(`Print`)}
                            </a>
                            {/* <a href={"/"}><i className='mdi mdi-email'></i> {t(`Email`)}</a> */}
                            {order.status.codeName === 'ACTIVO' && (
                                <a href={"/"}
                                    onClick={(e) => { 
                                        e.preventDefault()
                                        _cancelOrder(order.id)
                                }}>
                                    <i className='mdi mdi-cancel'></i> {t(`Cancel order`)}
                                </a>
                            )}
                        </nav>
                    </Col>

                    <Col sm={2}><strong>#PO-{order.folio}</strong> ({order.status.name})</Col>
                </Row>
            </Card>

            <Card>
                <CardTitle>
                    <Row>
                        <Col sm={6}>
                            {t(`Receive Products`)}
                        </Col>
                        <Col style={{textAlign: 'right'}} sm={6}>
                            {order.status.codeName !== 'RECIBIDA' && (
                                <React.Fragment>
                                    <Button
                                        onClick={(e) => { __autoFill(e) }}
                                        color="secondary"
                                        className="btn btn-secondary btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >{t(`Auto fill`)}</Button>

                                    <Button
                                        onClick={(e) => { __receiveProducts(e) }}
                                        color="primary"
                                        className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >
                                        {t(`Fullfil`)}
                                    </Button>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                </CardTitle>

                <Row>
                    <Col sm={6}>
                        <strong>{t(`Vendor details`)}</strong><br /><br />

                        <strong>{t(`Legal Business Name`)}:</strong> {order.supplier.legalBusinessName} <br />
                        <strong>{t(`Tax Id`)}:</strong> {order.supplier.taxId}<br />
                        <strong>{t(`Phone`)}:</strong> {order.supplier.phone}<br />
                        <strong>{t(`WhatsApp`)}:</strong> {order.supplier.whatsapp}<br />
                        <strong>{t(`E-Mail`)}:</strong> {order.supplier.email}<br />
                    </Col>

                    <Col sm={6}>
                        <strong>{t(`Shipment Details`)}</strong><br /><br />

                        <strong>{order.company.legalBusinessName}</strong><br />
                        <strong>{t(`Tax Id`)}</strong> {order.company.taxId} <br />
                        <strong>{t(`Location`)}:</strong> {order.branch.branchName}<br />
                        <strong>{t(`Address`)}:</strong><br />{order.branch.streetName} {order.branch.exteriorNumber} Int. {order.branch.interiorNumber}, {order.branch.neighborhood}, {order.branch.city}, {order.branch.state}, {order.branch.country}, {t(`Zip Code`)} {order.branch.zipCode}<br />
                        <strong>{t(`Due date`)}:</strong> {Moment(order.dudeDate).format("DD/MM/YYYY")}<br />
                        {order.requiredShippingDate && (
                            <React.Fragment>
                                <strong>{t(`Ship before`)}:</strong> {Moment(order.requiredShippingDate).format("DD/MM/YYYY")}<br />
                                <strong>{t(`Shipping Costs`)}:</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.shippingCosts} />
                            </React.Fragment>
                        )}
                    </Col>
                </Row>

                <section className="items">
                    <Table responive="true">
                        <thead>
                            <tr>
                                <th>{t(`Product and SKU`)}</th>
                                <th>{t(`QTY`)}</th>
                                <th>{t(`Location`)}</th>
                                <th>{t(`Received date`)}</th>
                                <th>{t(`Unit cost`)}</th>
                                <th>{t(`Discount`)}</th>
                                <th>{t(`Subtotal`)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems.map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        <strong>{item.product.name}</strong> <em>{item.product.sku}</em><br />
                                    </td>
                                    <td width={100}>
                                        {order.status.codeName === 'RECIBIDA' && (
                                            <span>{item.itemsReceived}</span>
                                        )}

                                        {order.status.codeName !== 'RECIBIDA' && (
                                            <Input 
                                                type='number'
                                                value={item.itemsReceived}
                                                // placeholder={`${t(`Ordered`)}: ${item.quantity}`}
                                                onChange={(e) => {
                                                    const { value } = e.target

                                                    let importe = +value * +item.unitCost
                                                    let discount = importe * (+item.discount / 100)
                                                    let subtotal = importe - discount
                                                    let taxAmount = 0
                                                    let scheme = item.product.product.taxScheme

                                                    for (let tax of scheme.value) {
                                                        taxAmount += +subtotal * +tax.taxRate
                                                    }

                                                    let total = +subtotal + +taxAmount

                                                    const items = [...lineItems]
                                                    items[i] = {
                                                        ...items[i],
                                                        itemsReceived: value,
                                                        subtotal,
                                                        taxAmount,
                                                        total,
                                                        scheme
                                                    }

                                                    setLineItems(items)
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td width={150}>
                                        {order.status.codeName === 'RECIBIDA' && (
                                            <span>{branches.length > 0 && branches.find(b => b.value === item.branchId.value).label}</span>
                                        )}

                                        {order.status.codeName !== 'RECIBIDA' && (
                                            <Select
                                                value={item.branchId}
                                                defaultValue={item.branchId}
                                                placeholder={t(`Location`)}
                                                onChange={(value) => { 
                                                    const items = [...lineItems]

                                                    items[i] = {
                                                        ...items[i],
                                                        branchId: value
                                                    }

                                                    setLineItems(items)
                                                }}
                                                options={branches}
                                                classNamePrefix="select2-selection"
                                            />
                                        )}
                                    </td>
                                    <td width={120}>
                                        {order.status.codeName === 'RECIBIDA' && (
                                            <span>{Moment(item.receivedDate).format("DD/MM/YYYY")}</span>
                                        )}

                                        {order.status.codeName !== 'RECIBIDA' && (
                                            <DatePicker
                                                locale="es"
                                                selected={!isEmpty(item.receivedDate) ? new Date(item.receivedDate) : new Date()}
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                onChange={date => {
                                                    const items = [...lineItems]

                                                    items[i] = {
                                                        ...items[i],
                                                        receivedDate: date
                                                    }

                                                    setLineItems(items)
                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        )}
                                    </td>
                                    <td width={150}>
                                        {order.status.codeName === 'RECIBIDA' && (
                                            <React.Fragment><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitCost} /></React.Fragment>
                                        )}

                                        {order.status.codeName !== 'RECIBIDA' && (
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                <Input
                                                    type="number"
                                                    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                    value={item.unitCost}
                                                    className="form-control" 
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        let importe = +item.itemsReceived * +value
                                                        let discount = importe * (+item.discount / 100)
                                                        let subtotal = importe - discount
                                                        let taxAmount = 0
                                                        let scheme = item.product.product.taxScheme

                                                        for (let tax of scheme.value) {
                                                            taxAmount += +subtotal * +tax.taxRate
                                                        }

                                                        let total = +subtotal + +taxAmount
                                                        const items = [...lineItems]
                                                        items[i] = {
                                                            ...items[i],
                                                            unitCost: value,
                                                            subtotal,
                                                            discount,
                                                            taxAmount,
                                                            total,
                                                            scheme
                                                        }

                                                        setLineItems(items)
                                                    }}
                                                    />
                                            </InputGroup>
                                        )}
                                    </td>
                                    <td width={130}>
                                        {order.status.codeName === 'RECIBIDA' && (
                                            <React.Fragment><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.discount} /></React.Fragment>
                                        )}

                                        {order.status.codeName !== 'RECIBIDA' && (
                                            <InputGroup>
                                                <Input
                                                    type="number"
                                                    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                    className="form-control"
                                                    value={item.discount}
                                                    onChange={(e) => {
                                                        const { value } = e.target

                                                        let importe = +item.itemsReceived * +item.unitCost
                                                        let discount = importe * (+value / 100)
                                                        let subtotal = importe - discount
                                                        let taxAmount = 0
                                                        let scheme = item.product.product.taxScheme

                                                        for (let tax of scheme.value) {
                                                            taxAmount += +subtotal * +tax.taxRate
                                                        }

                                                        let total = +subtotal + +taxAmount

                                                        const items = [...lineItems]
                                                        items[i] = {
                                                            ...items[i],
                                                            discount: value,
                                                            subtotal,
                                                            taxAmount,
                                                            total
                                                        }

                                                        setLineItems(items)
                                                    }}
                                                />
                                                <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                                            </InputGroup>
                                        )}
                                    </td>
                                    <td>
                                        <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={(item.unitCost * item.itemsReceived) - ((item.unitCost * item.itemsReceived) * (item.discount / 100))} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        {lineItems.reduce((a, b) => a + +b.itemsReceived, 0) > 0 && (
                            <tfoot>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={6}>{t(`Subtotal`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal, 0)} /></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={6}>{t(`Discount`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)} /></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={6}>{t(`Tax rate`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.taxAmount, 0)} /></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={6}>{t(`Total`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.total, 0)} /></td>
                                </tr>
                                {order.paid && (
                                    <React.Fragment>
                                        <tr>
                                            <td style={{textAlign: 'right'}} colSpan={6}>{t(`Paid`)}:</td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={order.amountPaid} /></td>
                                        </tr>

                                        <tr>
                                            <td style={{textAlign: 'right'}} colSpan={6}>{t(`Balance`)}:</td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={+lineItems.reduce((a, b) => a + +b.total, 0) - +order.amountPaid} /></td>
                                        </tr>
                                    </React.Fragment>
                                )}
                                {!order.paid && (
                                    <React.Fragment>
                                        <tr>
                                            <td style={{textAlign: 'right'}} colSpan={6}>{t(`Paid`)}:</td>
                                            <td width={150}>
                                                <Input
                                                    id='amountPaid'
                                                    value={order.amountPaid}
                                                    type="number"
                                                    onChange={(e) => {
                                                        setOrder({
                                                            ...order,
                                                            amountPaid: +e.target.value
                                                        })
                                                    }} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{textAlign: 'right'}} colSpan={6}>{t(`Balance`)}:</td>
                                            <td>
                                                <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={+lineItems.reduce((a, b) => a + +b.total, 0) - +order.amountPaid} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{textAlign: 'right'}} colSpan={8}>
                                                <Button
                                                    color="primary"
                                                    onClick={(e) => {
                                                        __markOrderAsPaid(e)
                                                    }}
                                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                                >
                                                    {t(`Pay`)}
                                                </Button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </tfoot>
                        )}
                    </Table>
                </section>
            </Card>
        </React.Fragment>
    )
}