import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardBody,
    CardFooter
} from "reactstrap"

import { GeneralContext } from '../../../context'

import { Link } from "react-router-dom"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

import ApiService from '../../../data/services/ApiService'

const Table = (props) => {

    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getBranches()

            setData(response.data.branches)
            setFilteredData(response.data.branches)
        } catch (error) {
            toastr.error(`No pude comunicarme con el servidor`, 'Problema de Conexión')
        } finally {
            setLoading(false)
        }
    }

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const branchName = item.branchName || ''
            const address = item.address || ''

            return branchName.toLowerCase().includes(keystroke) ||
                address.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        try {
            setLoading(true)
            await ApiService.deleteBranch(id)
            const filtered = await data.filter((v) => { return v.id !== id })

            setData(filtered)
            setFilteredData(filtered)

            toastr.success(props.t(`Branch deleted`), props.t(`Success`))
            window.analytics.track('branch deleted')
        } catch (error) {
            toastr.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control"
                                placeholder={props.t(`Search`)}
                                onKeyUp={(e) => _filterData(e)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col style={{ textAlign: 'center' }} md={4} sm={6} xs={12}>
                    <Link to="/branches/add">
                        <img style={{ width: '60%' }} src="/assets/images/add-item.svg" alt={``} />
                    </Link>
                </Col>
                {filteredData.map(
                    (item, key) => (
                        <Col key={key} lg={3} md={4} sm={6} xs={12}>
                            <Card>
                                <CardTitle>{item.branchName}</CardTitle>
                                <CardBody>
                                    <ul className='branch-contact-details'>
                                        <li><strong>{props.t(`Contact name`)}:</strong> {item.contactName}</li>
                                        <li><strong>{props.t(`Contact email`)}:</strong> {item.contactEmail}</li>
                                        <li><strong>{props.t(`Contact cellphone number`)}:</strong> {item.contactCellphone}</li>
                                        <li>&nbsp;</li>
                                        <li><strong>{props.t(`Address`)}:</strong> {item.streetName} {item.exteriorNumber} Int. {item.interiorNumber}, {item.neighborhood}, {item.city}, {item.state}, {item.country}, {props.t(`Zip Code`)} {item.zipCode}</li>
                                    </ul>
                                </CardBody>
                                <CardFooter className="bg-transparent border-top">
                                    <div className="d-flex font-size-20">
                                        <div className="flex-fill">
                                            <Link id={'edit'} to={`/branches/edit/${item.id}`}>
                                                <i className="mdi mdi-pencil"></i> {props.t(`Edit`)}
                                            </Link>
                                        </div>

                                        <div className="flex-fill">
                                            <Link id={'delete'} to={`#`} onClick={(e) => {
                                                e.preventDefault()
                                                _deleteItem(item.id)
                                            }}>
                                                <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                            </Link>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    )
                )}
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Table))