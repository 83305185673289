import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Table,
    InputGroup,
    InputGroupAddon
} from 'reactstrap'
import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context'
import TagsInput from 'react-tagsinput'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import Select from 'react-select'
import CSelect from 'react-select/creatable'
import toastr from 'toastr'
import Dropzone from "react-dropzone"
import { isEmpty } from 'lodash'

import { Link, withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import 'react-tagsinput/react-tagsinput.css'
import 'toastr/build/toastr.min.css'
import { connect } from 'react-redux'

const EditForm = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const genders = [ {
        label: 'Femenino',
        value: 'Femenino'
    }, {
        label: 'Masculino',
        value: 'Masculino'
    }, {
        label: 'Neutro',
        value: 'Neutro'
    }]

    const units = [{
        value: 'g',
        label: 'g'
    }, {
        value: 'kg',
        label: 'kg'
    }, {
        value: 'l',
        label: 'l'
    }, {
        value: 'ml',
        label: 'ml'
    }, {
        value: 'oz',
        label: 'oz'
    }, {
        value: 'lb',
        label: 'lb'
    }]

    const sizeUnits = [{
        value: 'cm',
        label: props.t('Centimeters')
    }, {
        value: 'm',
        label: props.t('Meters')
    }, {
        value: 'in',
        label: props.t('Inches')
    }, {
        value: 'mm',
        label: props.t('Millimeters')
    }]

    const variantTypes = [{
        value: 'color',
        label: props.t('Color')
    }, {
        value: 'size',
        label: props.t('Talla')
    }, {
        value: 'pieces',
        label: props.t('Piezas')
    }, {
        value: 'presentation',
        label: props.t('Presentación')
    }]

    const [id, setId] = useState('')
    const [familyId, setFamilyId] = useState('')
    const [categoryId, setCategoryId] = useState('')
    const [subcategoryId, setSubCategoryId] = useState('')
    const [brandId, setBrandId] = useState('')
    const [sku, setSKU] = useState('')
    const [upc, setUPC] = useState('')
    const [barcode, setBarcode] = useState('')
    const [description, setDescription] = useState('')
    const [tags, setTags] = useState([])
    const [name, setName] = useState('')    
    const [featured] = useState(false)
    const [weight, setWeight] = useState()
    const [weightUnit, setWeightUnit] = useState({
        value: 'g',
        label: 'g'
    })
    const [sizeUnit, setSizeUnit] = useState({
        value: 'cm',
        label: props.t('Centimeters')
    })
    const [gender, setGender] = useState('')
    const [model, setModel] = useState('')
    const [boxHeight, setBoxHeight] = useState()
    const [boxLength, setBoxLength] = useState()
    const [boxWidth, setBoxWidth] = useState()
    const [initialStock, setInitialStock] = useState(0)
    const [sellable, setSellable] = useState(true)
    const [productType, setProductType] = useState()
    const [unitCost, setUnitCost] = useState()
    const [itHasVariants, setItHasVariants] = useState(false)
    const [variants, setVariants] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [priceList, setPriceList] = useState([])
    const [variantOptions, setVariantOptions] = useState([])
    const [taxSchemes, setTaxSchemes] = useState([])
    const [taxSchemeId, setTaxSchemeId] = useState('')
    const [images, setImages] = useState([])

    const [tempVariant, setTempVariant] = useState({
        name: false,
        barcode: false,
        sku: false,
        unitCost: 0,
        priceList: [],
        options: [],
        initialStock: 0
    })

    // Relations
    const [families, setFamilies] = useState([])
    const [categories, setCategories] = useState([])
    const [subcategories, setSubCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [priceTypes, setPriceTypes] = useState([])

    useEffect(() => {
        const { product } = props

        const tags = product.tags || []
        const description = markdownToDraft(product.description)

        setGender(genders.find(i => i.value === product.gender))
        setSizeUnit(sizeUnits.find(i => i.value === product.sizeUnit))
        setWeightUnit(units.find(i => i.value === product.weightUnit))

        setId(product.id)
        setName(product.name)
        setFamilyId(product.familyId)
        setCategoryId(product.categoryId)
        setSubCategoryId(product.subcategoryId)
        setBrandId(product.brandId)
        setTags(tags)
        setModel(product.model)
        setInitialStock(product.initialStock)
        setBarcode(product.barcode)
        setDescription(product.description)
        setSKU(product.sku)
        setUPC(product.upc)
        setWeight(product.weight)
        setItHasVariants(product.itHasVariants)
        setVariants(product.variants)
        setSellable(product.sellable)
        setVariantOptions(product.variantOptions)
        setProductType(product.productType)
        setTaxSchemeId(product.taxSchemeId)

        setBoxHeight(product.boxHeight)
        setBoxWidth(product.boxWidth)
        setBoxLength(product.boxLength)

        if (!isEmpty(product.images)) {
            setImages(product.images)
        }

        // procesar variants
        if (!product.itHasVariants) {
            setPriceList(product.variants[0].priceList)
            setUnitCost(product.variants[0].unitCost)
        }

        if (product.familyId !== undefined) { 
            fetchCategories(product.familyId)
        }

        if (product.categoryId !== undefined) { 
            fetchSubcategories(product.categoryId)
        }

        setEditorState(EditorState.createWithContent(convertFromRaw(description)))
    }, [props.product]) // eslint-disable-line

    useEffect(() => {
        if (!isEmpty(taxSchemes)) {
            let dts = taxSchemes.find(ts => ts.value === taxSchemeId)
            setTaxSchemeId(dts)
        }
    }, [taxSchemes]) // eslint-disable-line

    useEffect(() => {
        if (!isEmpty(families)) {
            setFamilyId(families.find(i => i.value === familyId))
        }
    }, [families]) // eslint-disable-line

    useEffect(() => {
        if (!isEmpty(brands)) {
            setBrandId(brands.find(i => i.value === brandId))
        }
    }, [brands]) // eslint-disable-line

    useEffect(() => {
        if (!isEmpty(categories)) {
            setCategoryId(categories.find(i => i.value === categoryId))
        }
    }, [categories]) // eslint-disable-line

    useEffect(() => {
        if (!isEmpty(subcategories)) {
            setSubCategoryId(subcategories.find(i => i.value === subcategoryId))
        }
    }, [subcategories]) // eslint-disable-line

    useEffect(() => {
        (async () => {
            await fetchFamilies()
            await fetchBrands()
            await fetchPriceTypes()
            await fetchTaxSchemes()
        })()
    }, []) // eslint-disable-line

    async function fetchTaxSchemes() {
        try {
            setLoading(true)
            const { data } = await ApiService.getTaxSchemes()
            let array = []
            for (let s of data.schemes) {
                array.push({
                    value: s.id,
                    label: s.label
                })
            }

            setTaxSchemes(array)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your tax schemes`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchFamilies() {
        setLoading(true)
        const response = await ApiService.getFamilies()
        let data = []

        response.data.data.map((item) => {
            return data.push({
                value: item.id,
                label: item.name
            })
        })

        setLoading(false)
        setFamilies(data)
    }

    async function fetchBrands() {
        setLoading(true)
        const response = await ApiService.getBrands()
        let brands = []

        response.data.data.map((brand) => {
            const item = {
                value: brand.id,
                label: brand.name,
            }

            return brands.push(item)
        })

        setLoading(false)
        setBrands(brands)
    }

    async function fetchCategories(id) {
        setLoading(true)
        const response = await ApiService.getCategoriesByFamilyId(id)
        let data = []

        response.data.data.map((item) => {
            return data.push({
                value: item.id,
                label: item.name,
            })
        })

        setLoading(false)
        setCategories(data)
    }

    async function fetchSubcategories(id) {
        setLoading(true)
        const response = await ApiService.getSubcategoriesByCategoryId(id)
        let data = []

        response.data.data.map((item) => {
            return data.push({
                value: item.id,
                label: item.name,
            })
        })

        setLoading(false)
        setSubCategories(data)
    }

    async function fetchPriceTypes() {
        setLoading(true)
        const response = await ApiService.getPriceTypes()
        let data = []

        response.data.types.map((item) => {
            return data.push({
                value: item.id,
                label: item.name,
                type: item.type,
                default: item.default
            })
        })

        setLoading(false)
        setPriceTypes(data)
    }

    const onEditorStateChange = (editorState) => {
        const description = draftToMarkdown(convertToRaw(editorState.getCurrentContent()))

        setEditorState(editorState)
        setDescription(description)
    }

    const handleAcceptedFiles = async (files) => {
        try {
            let data = [...images]
            for (let file of files) {
                setLoading(true)
                const formData = new FormData()
                formData.append('file', file)
                let response = await ApiService.uploadProductImage(formData)
    
                data.push({
                    hash: response.data.hash,
                    imageURL: response.data.imageURL,
                    fileName: response.data.fileName,
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            }

            setImages(data)
        } catch (error) {
            toastr.error(props.t(`I couldn't upload yiouor image`))
        } finally {
            setLoading(false)
        }
    }

    const __deleteImage = async (productId, fileName) => {
        try {
            setLoading(true)
            const { data } = await ApiService.deleteProductImage(productId, fileName)

            if (data.code === 'E_NOT_FOUND') { return false }

            setImages(data.images)
        } catch (error) {
            toastr.error(props.t(`I couldn't delete this image`))
        } finally {
            setLoading(false)
        }
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const handleSubmit = (async (e) => {
        e.preventDefault()
        
        if (!_validateForm()) {
            toastr.error(props.t(`Please review missing fields`), 'Warning')

            return
        }

        try {
            setLoading(true)

            const data = {
                id,
                name,
                description,
                tags,
                upc,
                sku,
                barcode,
                model,
                gender: gender.value,
                familyId: familyId.value,
                categoryId: categoryId.value,
                subcategoryId: subcategoryId.value,
                brandId: brandId.value,
                weight,
                weightUnit: weightUnit.value,
                sizeUnit: sizeUnit.value,
                boxHeight,
                boxLength,
                boxWidth,
                unitCost,
                sellable,
                featured,
                productType,
                initialStock,
                taxSchemeId: taxSchemeId.value,
                priceList,
                itHasVariants,
                variantOptions,
                variants,
                images
            }

            const response = await ApiService.updateProduct(data)
            if (!response.data.success) {
                toastr.error(props.t(`I couldn't edit this product, please try again.`), 'Error')

                return
            }

            toastr.success(props.t(`Product Edited Successfully`), '¡Éxito!')
        } catch (error) {
            toastr.error(props.t(`I couldn't edit this product, please try again.`), 'Error')

            setLoading(false)
        } finally {
            setLoading(false)
        }
    })

    const _handleFamilyChange = async (e) => {
        const id = e.target.value

        setFamilyId(id)
        await fetchCategories(id)
    }

    const _handleCategoryChange = async (e) => {
        const id = e.target.value

        setCategoryId(id)
        await fetchSubcategories(id)
    }

    const _validateForm = () => {
        if (name === '') { return false }
        if (barcode === '') { return false }
        if (familyId === '') { return false }
        if (categoryId === '') { return false }
        if (subcategoryId === '') { return false }
        if (brandId === '') { return false }
        if (itHasVariants && variants.length < 1) { return false }

        return true
    }

    const _deleteVariant = async (i) => {
        const data = [...variants]
        data.splice(i, 1)

        setVariants(data)
    }

    const _addProductVariant = async (e) => {
        e.preventDefault()
        let variant = {...tempVariant}

        if (
            variant.unitCost === false || 
            variant.options.length < 1
        ) {
            toastr.error(props.t(`Please fill out the variant's details`), 'Error')

            return
        }

        let data = [...variants]

        const { sku, name } = variant

        data.push({...variant, name, sku})
        setVariants(data)

        toastr.success(props.t(`Variant added`), 'Éxito')
    }

    return(
        <React.Fragment>
            <Form id="product-create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col sm={12} xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Link 
                                    className="btn btn-default waves-effect waves-light mb-2 mr-2"
                                    to="/products"
                                >
                                    {props.t('Cancel')}
                                </Link>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t('Save Product')}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} xs={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Información del Producto
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Ponle nombre y descripción a tu producto. 
                                            Considera que, si estos productos los vas 
                                            a vender en línea, entre más clara sea la
                                            información del producto, más fácil será 
                                            para tus clientes descubrir y comprar tus
                                            productos.
                                        </CardSubtitle>

                                        <CardBody>
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles)
                                                }}
                                                >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div style={{ textAlign: 'center', paddingTop: '48px' }}
                                                                className="dz-message needsclick">
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                </div>

                                                                <h4>{props.t(`Drop files here or click to upload`)}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                <Row>
                                                    {images.map((f, i) => {
                                                        return (                                                            
                                                            <Col key={i} className="col-auto">
                                                                <img
                                                                    height={250}
                                                                    data-dz-thumbnail=""
                                                                    className="avatar-sm rounded bg-light"
                                                                    alt={f.name}
                                                                    src={f.imageURL}
                                                                />
                                                                <Link to={`/#`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        __deleteImage(id, f.fileName)
                                                                    }}>
                                                                    <span><i className='fa fa-trash'></i></span>
                                                                </Link>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <FormGroup>
                                            <Label htmlFor="name">
                                                Nombre del Producto *
                                            </Label>
                                            <Input
                                                id="name"
                                                name="productName"
                                                type="text"
                                                placeholder={`Macbook Pro 16"`}
                                                className="form-control"
                                                value={name}
                                                required
                                                onChange={(event) => { setName(event?.target?.value) }}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="description">
                                                Descripción del
                                                Producto
                                            </Label>
                                            <Editor
                                                editorClassName="draftjs"
                                                editorState={editorState}
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="control-label">
                                                Etiquetas
                                            </Label>
                                            <TagsInput
                                                onlyUnique={true}
                                                addKeys={[9, 13, 32, 186, 188]}
                                                value={tags}
                                                onChange={(value) => setTags(value)}
                                                pasteSplit={data => {
                                                    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r']

                                                    return data.split(new RegExp(separators.join('|'))).map(d => d.trim())
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Datos Generales del Producto
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            No todos los campos son obligatorios
                                            para dar de alta un producto, pero, 
                                            en nuestra experiencia, entre más
                                            datos tengas de tus productos,
                                            más eficiente es tu organización de inventarios
                                            y categorización en tus canales de ventas.
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="barcode">
                                                        <i className='mdi mdi-barcode-scan'></i> {props.t(`Barcode`)} 
                                                    </Label>
                                                    <Input
                                                        id="barcode"
                                                        name="barcode"
                                                        type="text"
                                                        className="form-control"
                                                        value={barcode}
                                                        required
                                                        onChange={(event) => setBarcode(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="sku">
                                                        {props.t(`SKU`)}
                                                    </Label>
                                                    <Input
                                                        id="sku"
                                                        name="sku"
                                                        type="text"
                                                        className="form-control"
                                                        value={sku}
                                                        maxLength={15}
                                                        onChange={(event) => setSKU(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="upc">
                                                        {props.t(`UPC`)}
                                                    </Label>
                                                    <Input
                                                        id="upc"
                                                        name="upc"
                                                        type="text"
                                                        maxLength={12}
                                                        className="form-control"
                                                        value={upc}
                                                        onChange={(event) => setUPC(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="model">
                                                        Modelo
                                                    </Label>
                                                    <Input
                                                        id="model"
                                                        name="model"
                                                        type="text"
                                                        className="form-control"
                                                        value={model}
                                                        onChange={(event) => setModel(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="upc">
                                                        Gender
                                                    </Label>

                                                    <Select 
                                                        id="gender"
                                                        value={gender}
                                                        onChange={value => setGender(value)}
                                                        options={genders} />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Brand`)} *
                                                    </Label>

                                                    <Select 
                                                        value={brandId}
                                                        placeholder={props.t(`Select brand`)}
                                                        onChange={ value => setBrandId(value) }
                                                        options={brands} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Family`)} *
                                                    </Label>

                                                    <Select 
                                                        id="family"
                                                        name="family"
                                                        value={familyId}
                                                        placeholder={props.t(`Select family`)}
                                                        onChange={ _handleFamilyChange }
                                                        options={families} 
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Category`)} *
                                                    </Label>

                                                    <Select 
                                                        value={categoryId}
                                                        placeholder={props.t(`Select category`)}
                                                        onChange={ _handleCategoryChange }
                                                        options={categories} 
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Subcategory`)} *
                                                    </Label>

                                                    <Select
                                                        value={subcategoryId}
                                                        default={subcategoryId}
                                                        placeholder={props.t(`Select subcategory`)}
                                                        onChange={ value => setSubCategoryId(value) }
                                                        options={subcategories} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ paddingTop: '16px'}}>
                                            <Col sm={3}>
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input type="checkbox" className="custom-control-input" id="sellable" onChange={() => false} checked={sellable} />
                                                    <label className="custom-control-label" onClick={() => { setSellable(!sellable) }} > Este producto se vende</label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Dimensiones y Pesos
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Los datos de dimensiones y pesos
                                            te ayudan a calcular gastos de logística
                                            y a tener un mayor control en tus espacios
                                            y almacenes.
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label htmlFor="weight">
                                                        {props.t(`Weight`)}
                                                    </Label>
                                                    <Input
                                                        id="weight"
                                                        name="weight"
                                                        type="number"
                                                        className="form-control"
                                                        value={weight}
                                                        onChange={(event) => setWeight(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <Label htmlFor="weightUnit">
                                                    {props.t(`Weight unit`)}
                                                </Label>
                                                <FormGroup>
                                                    <Select 
                                                        id={`weightUnit`}
                                                        value={weightUnit}
                                                        placeholder={props.t(`Select unit`)}
                                                        onChange={ value => setWeightUnit(value) }
                                                        options={units}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="sizeUnit">
                                                        {props.t(`Size unit`)}
                                                    </Label>

                                                    <Select 
                                                        id={`sizeUnit`}
                                                        value={sizeUnit}
                                                        placeholder={props.t(`Select size unit`)}
                                                        onChange={ value => setSizeUnit(value) }
                                                        options={sizeUnits}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="boxHeight">
                                                        {props.t(`Box Height`)} ({sizeUnit?.value})
                                                    </Label>
                                                    <Input
                                                        id="boxHeight"
                                                        name="boxHeight"
                                                        type="number"
                                                        className="form-control"
                                                        value={boxHeight}
                                                        onChange={(event) => setBoxHeight(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="boxLength">
                                                        {props.t(`Box Length`)} ({sizeUnit?.value})
                                                    </Label>
                                                    <Input
                                                        id="boxLength"
                                                        name="boxLength"
                                                        type="number"
                                                        className="form-control"
                                                        value={boxLength}
                                                        onChange={(event) => setBoxLength(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="boxWidth">
                                                        {props.t(`Box Width`)} ({sizeUnit?.value})
                                                    </Label>
                                                    <Input
                                                        id="boxWidth"
                                                        name="boxWidth"
                                                        type="number"
                                                        className="form-control"
                                                        value={boxWidth}
                                                        onChange={(event) => setBoxWidth(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Costos y Precios
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            <p>
                                                Registra los costos y precios del producto.
                                                Si registraste listas de precios, no olvides llenar
                                                sus respectivos valores.
                                            </p>
                                            <p>
                                                El precio marcado con asterisco y negritas es
                                                el valor que el sistema utilizará por default.
                                            </p>
                                            <p>
                                                Si este producto tiene variantes, tendrás la
                                                oportunidad de ajustar el valor a cada una en
                                                la siguiente sección.
                                            </p>
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Label htmlFor="unitCost">
                                                        {props.t(`Unit cost`)}
                                                    </Label>

                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                        <Input
                                                            id="unitCost"
                                                            name="unitCost"
                                                            type="text"
                                                            className="form-control"
                                                            value={unitCost}
                                                            onChange={(event) => setUnitCost(event.target.value)}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Label htmlFor="taxSchemeId">
                                                        {props.t(`Tax Scheme`)}
                                                    </Label>

                                                    <Select 
                                                        id={`taxSchemeId`}
                                                        value={taxSchemeId}
                                                        placeholder={props.t(`Select tax scheme`)}
                                                        onChange={ value => setTaxSchemeId(value) }
                                                        options={taxSchemes} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {priceTypes && !itHasVariants && (
                                            <Row>
                                                <Col sm={12}>
                                                    <CardTitle>Listas de Precios</CardTitle>
                                                </Col>

                                                {priceTypes.map((pt) => {
                                                    // Find the matching price entry in priceList based on priceTypeId
                                                    const matchingPrice = priceList.find((price) => price.priceTypeId === pt.value)

                                                    return (
                                                        <Col sm={4} key={pt.value}>
                                                            <FormGroup>
                                                                <Label>
                                                                    {pt.default && (
                                                                        <strong>Precio {pt.label} *</strong>
                                                                    )}

                                                                    {!pt.default && (
                                                                        <React.Fragment>Precio {pt.label}</React.Fragment>
                                                                    )}
                                                                </Label>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">{pt.type === 'price' ? `$` : `%`}</InputGroupAddon>
                                                                    <Input
                                                                        id={pt.value}
                                                                        type="number"
                                                                        pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                                        className="form-control"
                                                                        placeholder={pt.type === 'price' ? '100.00' : '10'}
                                                                        value={matchingPrice ? matchingPrice.amount : ''} // Populate with matching value
                                                                        onChange={(e) => {
                                                                            const { value } = e.target;
                                                                            const updatedPriceList = priceList.map((price) => {
                                                                                if (price.priceTypeId === pt.value) { // Update based on "value" property
                                                                                    return {
                                                                                        ...price,
                                                                                        amount: value,
                                                                                    }
                                                                                }

                                                                                return price
                                                                            })

                                                                            setPriceList(updatedPriceList)
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Variaciones de Producto <small> Se creará automáticamente una variación única, a menos que actives múltiples variaciones.</small>
                                        </CardTitle>

                                        <CardSubtitle>
                                            <p>
                                                Un producto puede tener una o mas variaciones, ej. diferentes tallas y/o colores. Cada variación tiene sus propios precios, niveles de stock, entre otras cosas.
                                            </p>

                                            <div className="custom-control custom-checkbox mb-3">
                                                <input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={itHasVariants} />
                                                <label className="custom-control-label" onClick={() => {
                                                    setItHasVariants(!itHasVariants)
                                                    }} >
                                                        Este producto tiene múltiples variaciones.
                                                </label>
                                            </div>
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                    {itHasVariants && (
                                        <React.Fragment>
                                            <CSelect
                                                placeholder={props.t(`Select variant options`)}
                                                defaultValue={variantOptions}
                                                options={variantTypes}
                                                onChange={(value) => {
                                                    setVariantOptions(value)
                                                }}
                                                isClearable
                                                isSearcheable
                                                isMulti={true}
                                            />
                                        </React.Fragment>
                                    )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        {(itHasVariants && variantOptions?.length > 0) && (
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <Table className={'table'}>
                                                <thead>
                                                    <tr>
                                                        <th>SKU</th>
                                                        <th>Barcode</th>
                                                        <th>Nombre de Variante</th>
                                                        {variantOptions?.map((o, i) => {
                                                            return (
                                                                <React.Fragment key={i}>
                                                                    <th>{o.label}</th>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                        <th>Costo Unitario</th>
                                                        {priceTypes && (
                                                            <React.Fragment>
                                                                {priceTypes.map((pt) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            {pt.default && (
                                                                                <th>Precio {pt.label} *</th>
                                                                            )}

                                                                            {!pt.default && (
                                                                                <th>Precio {pt.label}</th>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )}
                                                        <th>Stock Inicial</th>
                                                        <th>...</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {variants[0] && (
                                                        <React.Fragment>
                                                            {variants.map((v, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{v.sku}</td>
                                                                        <td>{v.barcode}</td>
                                                                        <td>{v.name}</td>
                                                                        {v.options.map((o) => {
                                                                            return (
                                                                                <td>{o.value}</td>
                                                                            )
                                                                        })}
                                                                        <td>{v.unitCost}</td>
                                                                        {v.priceList.map((pt) => (
                                                                            <td>{pt.amount}</td>
                                                                        ))}
                                                                        <td>{v.initialStock ? v.initialStock : 0}</td>
                                                                        <td>
                                                                            <button onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    _deleteVariant(i)
                                                                                }}
                                                                                className="btn btn-small btn-default">
                                                                                <i className="mdi mdi-delete"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                    <tr>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                placeholder="SKU"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        sku: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                placeholder={props.t('Barcode')}
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        barcode: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                placeholder="Adidas Superstar M"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        name: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        {variantOptions.map((o, i) => {
                                                            return (
                                                                <td key={i}>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder={props.t(o.label)}
                                                                        className="form-control"
                                                                        onChange={(event) => {
                                                                            const { value } = event.target
                                                                            const options = [...tempVariant.options]
                                                                            options[i] = {
                                                                                label: props.t(o.label),
                                                                                value
                                                                            }

                                                                            setTempVariant({
                                                                                ...tempVariant,
                                                                                options
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                placeholder="Costo Unitario"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        unitCost: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>

                                                        {priceTypes && (
                                                            <React.Fragment>
                                                                {priceTypes.map((pt, index) => {
                                                                    const priceListItem = tempVariant.priceList.find((price) => price.priceTypeId === pt.value);
                                                                    const initialValue = priceListItem ? priceListItem.amount : '';

                                                                    return (
                                                                        <td key={pt.value}>
                                                                            <InputGroup>
                                                                                <InputGroupAddon addonType="prepend">{pt.type === 'price' ? `$` : `%`}</InputGroupAddon>
                                                                                <Input
                                                                                    type="number"
                                                                                    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                                                    className="form-control"
                                                                                    placeholder={pt.type === 'price' ? '100.00' : '10'}
                                                                                    value={initialValue}
                                                                                    onChange={(event) => {
                                                                                        const { value } = event.target;
                                                                                        const updatedPriceList = tempVariant.priceList.map((price) => {
                                                                                            if (price.priceTypeId === pt.value) {
                                                                                                return {
                                                                                                    ...price,
                                                                                                    amount: value,
                                                                                                };
                                                                                            }
                                                                                            return price;
                                                                                        });

                                                                                        // If priceListItem is not found, add a new entry
                                                                                        if (!priceListItem) {
                                                                                            updatedPriceList.push({
                                                                                                priceTypeId: pt.value,
                                                                                                amount: value,
                                                                                                type: pt.type,
                                                                                                default: pt.default,
                                                                                            });
                                                                                        }

                                                                                        setTempVariant({
                                                                                            ...tempVariant,
                                                                                            priceList: updatedPriceList,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </InputGroup>
                                                                        </td>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        )}

                                                        <td>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Stock"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        initialStock: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>

                                                        <td>
                                                            <Button
                                                                style={{ padding: '4px 16px' }}
                                                                className="btn btn-success waves-effect waves-light mb-2 mr-2"
                                                                onClick={ _addProductVariant }>
                                                                <i className="mdi mdi-plus"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return {}
}

export default connect(
    mapStatetoProps, {}
)(withRouter(withNamespaces()(EditForm)))