import React, { useState, useEffect, useContext } from "react"
import {
    Container,
    Card,
    CardTitle,
    CardBody,
    Button
} from "reactstrap"
import { withNamespaces } from "react-i18next"
import BulkImport from './components/BulkImport'
import { GeneralContext } from '../../context/'

const Import = (props) => {
    const [component, setComponent] = useState()
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Inventory`),
            parentLink: '/products',
            item: props.t(`Import Products`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const _renderStep1 = () => {
        return (
            <React.Fragment>
                <CardTitle>Paso 1: Descargar Template</CardTitle>
                    <p>
                        Para iniciar, por favor descargue el template de importación. Lo podrá abrir en cualquier aplicación de hoja de cálculo, tales como Microsoft Excel o Apple Numbers.
                    </p>

                    <p>Hemos agregado algunos ejemplos a manera de guía. Para más información, puedes visitar nuestra página de soporte técnico .</p>

                    <a href="https://storage.googleapis.com/vantaapp/Template_to_Import_Products.xlsx" className="btn btn-secondary btn-rounded waves-effect waves-light mb-2 mr-2">
                        <i className="mdi mdi-download mr-1"></i> Descargar Template
                    </a>

                    <Button type="submit" color="primary" onClick={() => { _gotoStep2() }} className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2">
                        <i className="mdi mdi-chevron-right mr-1"></i> Continuar
                    </Button>
            </React.Fragment>
        )
    }

    const _gotoStep2 = () => {
        setComponent(<BulkImport />)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                        {!component && (_renderStep1())}
                        {component}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces()(Import)