import React, { useContext, useEffect } from 'react'
import { Card, CardTitle, CardBody, Container, Row, Col, CardFooter, CardSubtitle } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

import ProductList from './components/ProductList'
import Cart from './components/Cart'
import CartTotals from './components/CartTotals'
import CartActions from './components/Actions'
import CartOptions from './components/Options'
import { GeneralContext } from '../../context/'
import ApiService from '../../data/services/ApiService'

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { folio, setFolio, setLoading, activeCustomer, setActivePriceList, cartItems, setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Sales`),
            parentLink: '/sales',
            item: props.t(`POS`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            await fetchNextFolio()
            await fetchDefaultPriceList()
        })()
    }, []) // eslint-disable-line

    async function fetchNextFolio() {
        try {
            setLoading(true)
            const response = await ApiService.fetchNextFolio()

            setFolio(response.data.folio)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    async function fetchDefaultPriceList() {
        try {
            setLoading(true)
            const response = await ApiService.fetchDefaultPriceList()

            setActivePriceList(response.data.list)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md={7} xl={8}>
                            <Card>
                                <CardBody>
                                    <CartActions />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <ProductList />
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={5} xl={4}>
                            <Card>
                                <CardTitle>{props.t('Transaction')} #{folio}</CardTitle>
                                {activeCustomer && <CardSubtitle>{props.t('Customer')}: {activeCustomer.label}</CardSubtitle>}

                                <CardBody>
                                    <CartOptions />
                                    <Cart />
                                </CardBody>

                                {cartItems.length > 0 && (
                                    <CardFooter>
                                        <CartTotals />
                                    </CardFooter>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Index))