import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Label,
    Button,
    FormGroup
} from "reactstrap"
import ApiService from '../../../data/services/ApiService'
import DatePicker, { registerLocale } from 'react-datepicker'
import Select from "react-select"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import es from 'date-fns/locale/es'

registerLocale('es', es)

const styles = { textAlign: 'right' }

export default ({
    quote,
    setLoading,
    history,
    setCreateOrderModalOpen,
    t
}) => {
    const [accountId, setAccountId] = useState({value: '', label: ''})
    const [dueDate, setDueDate] = useState()
    const [bankAccounts, setBankAccounts] = useState()

    useEffect(() => {
        (async () => {
            await fetchBankAccounts()
        })()
    }, []) // eslint-disable-line

    const fetchBankAccounts = async () => {
        try {
            setLoading(true)
            const response = await ApiService.getBankAccounts()

            let data = []
            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.alias
                })
            })

            setBankAccounts(data)
            setAccountId(data[0])
        } catch (error) {
            toastr.error(t(`I couldn't fetch your bank accounts`))
        } finally {
            setLoading(false)
        }
    }

    const __markPurchaseQuoteAsOrder = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)
            const response = await ApiService.markPurchaseQuoteAsOrder({
                quoteId: quote.id,
                accountId: accountId.value,
                dueDate
            })

            if (!response.data.success) {
                toastr.error(t(`I couldn't create an order`), 'Error')
                return
            }

            toastr.success(t(`Order created from this quote`), t('Success!'))

            history.push(`/purchase-order/view/${response.data.order.id}`)
        } catch (error) {
            toastr.error(t(`I couldn't create an order`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Row>
            <Col md={6} sm={12}>
                <FormGroup>
                    <Label htmlFor="paymentType">
                        {t(`Bank Account`)}
                    </Label>

                    <Select
                        id="bankAccount"
                        value={accountId}
                        placeholder={t(`Select a bank account`)}
                        onChange={(value) => { 
                            setAccountId(value)
                        }}
                        options={bankAccounts}
                        classNamePrefix="select2-selection"
                    />
                </FormGroup>
            </Col>

            <Col md={6} sm={12}>
                <FormGroup>
                    <Label>{t(`Due date`)}</Label>

                    <DatePicker
                        locale="es"
                        selected={dueDate}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        onChange={date => setDueDate(date)}
                        showMonthDropdown
                        showYearDropdown
                    />
                </FormGroup>
            </Col>

            <Col sm={12} style={styles}>
                <Button color="primary" size='md' onClick={__markPurchaseQuoteAsOrder}>{t(`Create Order`)}</Button>
            </Col>
        </Row>
    )
}