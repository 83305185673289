import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    CardSubtitle
} from 'reactstrap'
import Moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import CurrencyFormat from 'react-currency-format'

const Table = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await _fetchData()
        })()
    }, []) // eslint-disable-line

    const _fetchData = async () => {
        setLoading(true)
        try {
            const response = await ApiService.getSales()

            setData(response.data.sales)
            setFilteredData(response.data.sales)
            setLoading(false)
        } catch (error) {
            toastr.error(props.t(`Oop's! I couldn't find any sales`))
        } finally {
            setLoading(false)
        }
    }

    const _deleteItem = async (id) => {
        setLoading(true)

        try {
            const result = await ApiService.deleteCustomer(id)

            if (!result.data.success) {
                toastr.error(props.t(`I couldn't delete this customer`), 'Error')

                return false
            }

            let filtered = await data.filter((v) => { return v.id !== id })

            setData(filtered)
            setFilteredData(filtered)

            toastr.success(props.t(`I deleted a customer`), '¡Éxito!')
            window.analytics.track('Customer Deleted', {
                customerId: id
            })
        } catch (error) {
            console.log(error)

            toastr.error(props.t(`I couldn't delete this customer`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <Row>
                <Col style={{ textAlign: 'center' }} md={4} sm={12} xs={12}>
                    <Link to="/pos">
                        <img style={{ width: '60%' }} src="/assets/images/add-item.svg" alt={``} />
                    </Link>
                </Col>
                {filteredData && filteredData.map((item, key) => (
                    <Col {...key} md={4} sm={12} xs={12}>
                        <Card>
                            <CardTitle>#{item.folio} {item.customer.businessName}</CardTitle>
                            <CardSubtitle>{Moment(item.createdAt).format("DD/MM/YYYY")} | {item.lineItems.length} {props.t(`items`)} | <strong>Total: </strong><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.total} /></CardSubtitle>
                            <CardBody>

                            </CardBody>

                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex font-size-20">
                                    <div className="flex-fill">
                                        <Link id={'edit'} to={`/sales/print/${item.id}`} target="_blank">
                                            <i className="mdi mdi-printer"></i> {props.t(`Print`)}
                                        </Link>
                                    </div>

                                    <div className="flex-fill">
                                        <Link id={'delete'} to={`#`} onClick={(e) => {
                                            e.preventDefault()
                                            _deleteItem(item.id)
                                        }}>
                                            <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Table))