import React, { useContext, useEffect, useState } from 'react'
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap"

import { withRouter, Link, useParams } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../context/'

import Personal from './components/Personal'
import Company from './components/Company'
import Inventory from './components/Inventory'
import Orders from './components/Orders'

const Index = (props) => {
    const { route } = useParams() 
    const [navigation, setNavigation] = useState()
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Settings`),
            parentLink: '/settings',
            item: props.t(`Settings`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        switch(route) {
            case 'inventory':
                window.analytics.track('[conf] Ir a Inventario')
                setNavigation(<Inventory />)
                break;
            case 'orders':
                window.analytics.track('[conf] Ir a Ordenes')
                setNavigation(<Orders />)
                break;
            case 'integrations':
                window.analytics.track('[conf] Ir a Integraciones')
                break;
            case 'company':
                window.analytics.track('[conf] Ir a Compañía')
                setNavigation(<Company />)
                break;
            case 'personal':
            default:
                window.analytics.track('[conf] Ir a Personal')
                setNavigation(<Personal />)
                break;
        }
    }, [route])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <Row className="config">
                                <Col className="configuration-nav" md={12} xs={12}>
                                    <nav>
                                        <Link to="/settings/personal" className={route === 'personal' ? 'active' : ''}>{props.t(`Personal`)}</Link>
                                        <Link to="/settings/company" className={route === 'company' ? 'active' : ''}>{props.t(`Company`)}</Link>
                                        <Link to="/settings/inventory" className={route === 'inventory' ? 'active' : ''}>{props.t(`Inventory`)}</Link>
                                        <Link to="/settings/orders" className={route === 'orders' ? 'active' : ''}>{props.t(`Orders`)}</Link>
                                        {/* <Link to="/settings/integrations" className={route === 'integrations' ? 'active' : ''}>{props.t(`Integrations`)}</Link> */}
                                    </nav>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {navigation}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Index))
