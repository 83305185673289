import React, { useRef } from 'react'

export default (props) => {
	const propContent = useRef(null)

	const toggleMenu = () => {
		props.toggleMenuCallback()
	}

	return (
		<div onClick={() => toggleMenu} ref={propContent}>
			{props.body}
		</div>
	)
}