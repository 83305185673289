import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Form,
    Button
} from 'reactstrap'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import moment from 'moment'
import 'moment/min/locales'
import { withNamespaces } from 'react-i18next'
import ApiService from '../../../data/services/ApiService'
import { updateAvatar, updateProfile } from '../../../store/actions'
import { GeneralContext } from '../../../context/'
import DefaultAvatar from '../../../assets/images/avatar-1.jpg'

moment.locale('es')

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        countryCode: '',
        phone: '',
        country: '',
        password: ''
    })
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [countryCodes] = useState([
        {
            value: '+52',
            label: '+52 (MX)'
        },
        {
            value: '+1',
            label: '+1 (US)'
        },
    ])

    useEffect(() => {
        setUser(props.user)
    }, []) // eslint-disable-line

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imágenes', 'Error')

            return 
        }

        setLoading(true)
        fileUpload(files[0]).then((response) => {
            if (!response.data.success) {
                toastr.error('No pude actualizar tu avatar', 'Error')
                setLoading(false)
                return
            }

            setUser({
                ...user,
                avatar: response.data.fileURL
            })

            setLoading(false)
            props.updateAvatar(response.data.fileURL)
            toastr.success('Actualicé tu avatar', '¡Éxito!')
        }).catch(error => {
            console.log('fileUpload', error)
            setLoading(false)
            toastr.error('No pude actualizar tu avatar', 'Error')
        })
    }

    const fileUpload = (file) => {
        try {
            setLoading(true)
            const formData = new FormData()

            formData.append('userId', user.id)
            formData.append('file', file)
    
            return ApiService.uploadUserAvatar(formData)
        } catch (error) {
            toastr.error(props.t(`I couldn't upload your avatar, please try again`))
        } finally {
            setLoading(false)
        }
    }

    const _handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)
    
            const response = await ApiService.updateUser(user)
            if (!response) {
                toastr.error(props.t(`I couldn't update your data`))

                return
            }
    
            props.updateProfile(user)
            toastr.success(`Actualicé tus datos`)
        } catch (error) {
            toastr.error(props.t(`I couldn't update your data`))
        } finally {
            setLoading(false)
        }
    }

    const _handleUdatePassword = async (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            toastr.error(props.t(`Make sure both passwords match`))

            return
        }

        try {
            setLoading(true)
            const response = await ApiService.updatePassword({
                userId: user.id,
                password
            })
    
            if (!response) {
                toastr.error(props.t(`I couldn't update your password, please try again`))
                return
            }

            toastr.success(props.t(`Password updated`))
        } catch (error) {
            toastr.error(props.t(`I couldn't update your password, please try again`))
        } finally {
            setLoading(false)
        }
    }

    const setAvatar = () => {
        if (!isEmpty(user)) {
            if (!isEmpty(user.avatar)) {
                return user.avatar
            }
        }

        return DefaultAvatar
    }

    return (
        <React.Fragment>
            <Row>
                <Col md={3}>
                    <Card>
                        <CardBody>
                            <img
                                className="rounded rounded-circle avatar-lg"
                                width={150}
                                src={setAvatar()}
                                alt={user.firstName}
                            />

                            <br /><br />

                            <FormGroup>
                                <Label>Actualiza tu Avatar</Label>

                                <Input type="file"
                                    id="image"
                                    onChange={ _handleFileUpload }
                                />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={9}>
                    <Card>
                        <CardBody>
                            <CardTitle>Administra tu información Personal</CardTitle>

                            <Form id="create" method="POST" onSubmit={ _handleSubmit }>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Nombre/s *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={user.firstName}
                                                onChange={event => setUser({
                                                    ...user,
                                                    firstName: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Apellido/s *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={user.lastName}
                                                onChange={event => setUser({
                                                    ...user,
                                                    lastName: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                E-Mail *
                                            </Label>
                                            <Input
                                                type="email"
                                                className="form-control"
                                                value={user.email}
                                                onChange={event => setUser({
                                                    ...user,
                                                    email: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>
                                                Código de País
                                            </Label>
                                            <select className="custom-select"
                                                onChange={event => setUser({
                                                    ...user,
                                                    countryCode: event.target.value
                                                })}
                                                defaultValue={user.countryCode}>
                                                {countryCodes.map((item, i) => {
                                                    let selected = user.countryCode === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                Teléfono *
                                            </Label>
                                            <Input
                                                type="tel"
                                                className="form-control"
                                                value={user.phone}
                                                onChange={event => setUser({
                                                    ...user,
                                                    phone: event.target.value
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} style={{textAlign: 'right'}}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                        >
                                            <i className={`mdi mdi-check`}></i> Actualizar mis datos
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle>Actualizar Contraseña</CardTitle>

                            <Form id="create" method="POST" onSubmit={ _handleUdatePassword }>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Contraseña
                                            </Label>
                                            <Input
                                                id="password"
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                onChange={event => setPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Confirmar Contraseña
                                            </Label>
                                            <Input
                                                id="confirmPassword"
                                                type="password"
                                                className="form-control"
                                                value={confirmPassword}
                                                onChange={event => setConfirmPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} style={{textAlign: 'right'}}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                        >
                                            <i className={`mdi mdi-check`}></i> Actualizar Contraseña
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return { user: state.Auth.profile }
}

export default connect(
    mapStatetoProps, { updateAvatar, updateProfile }
)(withRouter(withNamespaces()(Index)))