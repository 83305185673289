import React, { useState, useEffect, useContext } from 'react'
import {
    Container,
} from 'reactstrap'

import Form from './components/Form'
import { GeneralContext } from '../../context/'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import ApiService from '../../data/services/ApiService'

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext
    const [data, setData] = useState([])

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Expenses`),
            parentLink: '/expenses',
            item: props.t(`Edit Expense`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            const id = props.match.params.id
            setLoading(true)

            try {
                const response = await ApiService.getExpenseById(id)
                setData(response.data.expense)
            } catch (error) {
                // something something
            } finally {
                setLoading(false)
            }
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Form data={data} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))
