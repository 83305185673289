import React, { useContext } from "react"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap"
import { GeneralContext } from '../../context/'
import { isMobile } from "react-device-detect"

const Breadcrumbs = (props) => {
    const generalContext = useContext(GeneralContext)
    const { breadcrumb } = generalContext

    return (
        <React.Fragment>
            {props.title !== undefined && (
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h1 className="page-title">
                        <p>
                            <Link to={`/`} onClick={(e) => {
                                e.preventDefault()
                                props.history.push(-1)
                            }}><i className="mdi mdi-chevron-left"></i></Link> {props.icon && ( <i className={props.icon}> </i>  )} {props.breadcrumbItem}
                        </p>

                        <Breadcrumb listClassName="breadcrumb m-0">
                            <BreadcrumbItem>
                                <Link to={props.parentLink}>{props.title}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active> {props.breadcrumbItem} </BreadcrumbItem>
                        </Breadcrumb>
                    </h1>
                </div>
            )}

            {breadcrumb.item !== '' && (
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h1 className="page-title">
                                {breadcrumb.icon && ( <i className={breadcrumb.icon}> </i>  )}

                                <p>{isMobile && (<Link to={`/`} onClick={(e) => {
                                e.preventDefault()
                                props.history.goBack()
                            }}><i className="mdi mdi-chevron-left font-size-24"></i></Link>)} {breadcrumb.item}</p>

                                <Breadcrumb listClassName="breadcrumb m-0">
                                    <BreadcrumbItem>
                                        <Link to={breadcrumb.parentLink}>{breadcrumb.parent}</Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active> {breadcrumb.item} </BreadcrumbItem>
                                </Breadcrumb>
                            </h1>
                        </div>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    )
}

export default withRouter(Breadcrumbs)
