import React, { useEffect } from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import toastr from 'toastr'
import ApiService from '../../data/services/ApiService'
import { withNamespaces } from "react-i18next"
import { loginSuccess, apiError, setActiveCompany, setToken } from "../../store/actions"

const Verify = (props) => {
    useEffect(() => {
        (async () => {
            try {
                const userId = props.match.params.userId
                const companyId = props.match.params.companyId
                const verificationCode = props.match.params.verificationCode
                const response = await ApiService.verifyAccount({
                    verificationCode,
                    companyId,
                    userId
                })
    
                if (!response.data.success) {
                    toastr.error(props.t(`We couldn't verify your account, want to try again?`))
    
                    return
                }

                const user = {
                    ...response.data.user,
                    companies: [response.data.company]
                }

                toastr.success(props.t(`Thanks for confirming your account`), props.t(`Success!`))

                window.analytics.track("User account confirmed", { userId: response.data.userId })
                window.analytics.identify(response.data.userId, { 
                    email: user.email,
                    verified: true
                })
    
                props.setToken(response.data.token)
                props.loginSuccess(user)
                props.setActiveCompany(response.data.company)
                props.history.push('/')
            } catch (error) {
                console.error(`ERROR`, error)
                // props.history.push(`/login`)
            }
        })()

        // eslint-disable-next-line
    }, [])

    return(
        <React.Fragment></React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { error } = state.Auth

    return { error }
}

export default withRouter(
    connect(
        mapStatetoProps, 
        { loginSuccess, apiError, setActiveCompany, setToken }
    )(withNamespaces()(Verify)
))