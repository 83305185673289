import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    UPDATE_AVATAR,
    UPDATE_PROFILE,
    UPDATE_COMPANY,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    SET_ACTIVE_COMPANY,
    SET_TOKEN
} from "./actionTypes"
import { reactLocalStorage } from "reactjs-localstorage"

export const loginUser = (data, history, from) => {
    return {
        type: LOGIN_USER,
        payload: { data, history, from }
    }
}

export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        data: token
    }
}

export const setActiveCompany = (company) => {
    reactLocalStorage.set("activeCompany", company)

    return {
        type: SET_ACTIVE_COMPANY,
        data: company
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        data: user
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const updatePasswordSuccess = () => {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        payload: {}
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const updateAvatar = (avatar) => {
    return {
        type: UPDATE_AVATAR,
        data: avatar
    }
}

export const updateProfile = (data) => {
    return {
        type: UPDATE_PROFILE,
        data
    }
}

export const updateCompany = (data) => {
    return {
        type: UPDATE_COMPANY,
        data
    }
}

export const updatePassword = (data) => {
    return {
        type: UPDATE_PASSWORD,
        data
    }
}