import React, { useState, useEffect, useContext } from 'react'
import { withRouter, Link } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import moment from 'moment'
import 'moment/min/locales'
import { isEmpty, head, filter } from 'lodash'
import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context/'
import DefaultImage from '../../../assets/images/avatar-product-placeholder.png'
import { withNamespaces } from "react-i18next"
import PagingState from '../../../components/PagingState'

import {
    Row,
    Col,
    Input,
    Card,
    CardBody,
    CardTitle,
    CardFooter
} from 'reactstrap'

moment.locale('es')

const Datatable = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [list, setList] = useState([])
    const [offset, setOffset] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [filters] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const handlePageChange = newPage => {
        setCurrentPage(newPage)
        onPageChanged(newPage)
    }

    const handleRowsPerPageChange = e => {
        setRowsPerPage(e.target.value)
    }

    const fetchData = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getProducts(offset, rowsPerPage, filters)

            setList(response.data.data)
            setFilteredData(response.data.data)
            setTotalCount(response.data.count)
        } catch (error) {
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    const onPageChanged = (newPage => setOffset(newPage * rowsPerPage))

    useEffect(() => {
        (async function fetch() {
            await fetchData()
        })()
    }, [offset, filters, rowsPerPage]) // eslint-disable-line

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = list.filter((item) => {
            const name = item.name || ''
            const sku = item.sku || ''
            const description = item.description || ''
            const brand = item.brand.name || ''
            const family = item.family.name || ''
            const category = item.category.name || ''
            const subcategory = item.subcategory.name || ''

            return name.toLowerCase().includes(keystroke) ||
                description.toLowerCase().includes(keystroke) ||
                sku.toLowerCase().includes(keystroke) ||
                brand.toLowerCase().includes(keystroke) ||
                family.toLowerCase().includes(keystroke) || 
                category.toLowerCase().includes(keystroke) || 
                subcategory.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const showToast = (title, message, toastType) => {
        if (toastType === "info") {
            toastr.info(message, title)
        } else if (toastType === "warning")  {
            toastr.warning(message,title)
        } else if (toastType === "error") {
            toastr.error(message, title)
        } else {
            toastr.success(message, title)
        }
    }

    const _deleteItem = async (id) => {
        setLoading(true)

        try {
            const result = await ApiService.deleteProduct(id)
            if (!result.data.success) {
                showToast('Error', 'Hubo un problema al eliminar el producto', 'error')
            }
    
            showToast('¡Éxito!', 'Producto eliminado correctamente', 'success')

            // refresh list
            await fetchData()
        } catch (error) {
            showToast('Error', 'Hubo un problema al eliminar el producto', 'error')
        } finally {
            setLoading(false)
        }
    }

    const _renderProductAvatar = (product) => {
        if (isEmpty(product.images)) {
            return( <img alt={``} style={{maxWidth: '128px'}} src={DefaultImage} /> )
        }

        const image = filter(product.images, { featured: true })
        if (isEmpty(head(image))) {
            return (<img alt={``} style={{maxWidth: '128px'}} src={head(product.images).imageURL || DefaultImage} />)
        }

        return (
            <div style={{textAlign: 'center'}}>
                <img style={{maxWidth: '128px'}}  alt={head(image).id} src={`${head(image).imageURL}`} />
            </div>
        )
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row className="mb-2">
                        <Col sm="4">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                    <Input type="text" className="form-control"
                                        placeholder={props.t(`Search`)}
                                        onKeyUp={(e) => _filterData(e)}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                        <Col sm="8">
                            <div className="text-sm-right">
                                <Link to={`/products/import`} className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-import mr-1"></i> {props.t(`Import Products`)}
                                </Link>

                                <Link to={`/products/update-products`} className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-update mr-1"></i> {props.t(`Update Products`)}
                                </Link>

                                <Link to={`/products/update-prices`} className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-tag mr-1"></i> {props.t(`Update Prices`)}
                                </Link>

                                <Link to={`/products/update-stock`} className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-barcode mr-1"></i> {props.t(`Update Stock`)}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Col style={{ textAlign: 'center' }} md={4} sm={12} xs={12}>
                <Link to="/products/add">
                    <img style={{ width: '60%' }} src="/assets/images/add-item.svg" alt={``} />
                </Link>
            </Col>

            <Row>
                {filteredData && filteredData.map((item, key) => (
                    <Col key={key} lg={6} xl={4} md={12} xs={12} sm={6}>
                        <Card>
                            <CardTitle>{item.name} <small>(SKU: {item.sku})</small></CardTitle>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>{_renderProductAvatar(item)}</Col>
                                    <Col sm={8}>
                                        <ul style={{listStyle: 'none', padding: 0}}>
                                            <li><strong>{props.t(`Brand`)}:</strong> {item.brand.name}</li>
                                            <li><strong>{props.t(`Family`)}:</strong> {item.family.name}</li>
                                            <li><strong>{props.t(`Category`)}:</strong> {item.category.name}</li>
                                            <li><strong>{props.t(`Subcategory`)}:</strong> {item.subcategory.name}</li>
                                            <li><strong>{props.t(`Variants`)}:</strong> {item.variants.length}</li>
                                            <li>{props.t(`In Stock`)}: {item.variants.reduce((a, b) => a + b.stock.reduce((c, d) => c + d.currentStock, 0), 0)}</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </CardBody>

                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex font-size-20">
                                    {/* <div className="flex-fill">
                                        <Link id={'viewVariants'} to={`/products/view/${item.id}`}>
                                            <i className="mdi mdi-account-details text-success"></i> Ver Variantes
                                        </Link>
                                    </div> */}

                                    <div className="flex-fill">
                                        <Link id={'edit'} to={`/products/edit/${item.id}`}>
                                            <i className="mdi mdi-pencil"></i> {props.t(`Edit`)}
                                        </Link>
                                    </div>

                                    <div className="flex-fill">
                                        <Link id={'delete'} to={`#`} onClick={(e) => {
                                            e.preventDefault()
                                            _deleteItem(item.id)
                                        }}>
                                            <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Card>
                <PagingState
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    pageSize={rowsPerPage}
                    totalCount={totalCount}
                    maxVisiblePages={6}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </Card>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Datatable))