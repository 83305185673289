import React, { useState, useEffect, useContext } from 'react'
import {
    Container,
    Card,
    CardBody,
    TabContent, 
    TabPane, 
    NavItem, 
    Nav,
    NavLink
} from 'reactstrap'
import classnames from 'classnames'
import ProductEdit from './components/EditForm'
import Gallery from './components/Gallery'
import Variants from './components/Variants'
import ApiService from '../../data/services/ApiService'
import { GeneralContext } from '../../context/'

const View = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext

    const [product, setProduct] = useState({})
    const [variants, setVariants] = useState([])
    const [gallery, setGallery] = useState([])
    const [activeTab, setActiveTab] = useState(1)

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Inventory`),
            parentLink: '/products',
            item: `${product.name} (${variants.length})`
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            const { id } = props.match.params

            _loadProduct(id)
        })()
    }, []) // eslint-disable-line

    const _loadProduct = async (id) => {
        setLoading(true)
        const response = await ApiService.getProductById(id)

        setProduct(response.data.product)
        setVariants(response.data.product.variants)
        setGallery(response.data.product.gallery)
        setLoading(false)
    }

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <Nav tabs className="nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                            active: activeTab === 1
                                        })}
                                        onClick={() => {
                                            setActiveTab(1)
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Variaciones</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                            active: activeTab === 2
                                        })}
                                        onClick={() => {
                                            setActiveTab(2)
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Detalles</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={classnames({
                                            active: activeTab === 3
                                        })}
                                        onClick={() => {
                                            setActiveTab(3)
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Imágenes</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1} className="p-3">
                                    {product.id && variants[1] && (
                                        <Variants product={product} variants={variants} />
                                    )}
                                </TabPane>

                                <TabPane tabId={2} className="p-3">
                                    {
                                        product.id && (
                                            <ProductEdit product={product} />
                                        )
                                    }
                                </TabPane>

                                <TabPane tabId={3} className="p-3">
                                    {product.id && (
                                        <Gallery product={product} gallery={gallery} />
                                    )}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default View