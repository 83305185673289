import React, { useState, useEffect, useContext } from 'react'
import {
    Container,
} from 'reactstrap'

import EditForm from './components/Form'
import { GeneralContext } from '../../context/'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import ApiService from '../../data/services/ApiService'

const Edit = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext
    const [data, setData] = useState([])

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Settings`),
            parentLink: '/tax-schemes',
            item: props.t(`Edit Tax Scheme`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            const id = props.match?.params?.id
            await getTaxSchemeById(id)
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTaxSchemeById = async (id) => {
        try {            
            setLoading(true)
            const { data } = await ApiService.getTaxSchemeById(id)

            setData(data?.scheme)
        } catch (error) {}
        finally {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <EditForm data={data} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Edit))