import React, { useState, useContext } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input
} from 'reactstrap'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import moment from 'moment'
import 'moment/min/locales'
import { withNamespaces } from 'react-i18next'
import ApiService from '../../../data/services/ApiService'
import { updateCompany } from '../../../store/actions'
import { GeneralContext } from '../../../context/'
import DefaultAvatar from '../../../assets/images/avatar-1.jpg'
import Sidemodal from '../../../components/Sidemodal'

import CompanyDetails from './_Company'
import CompanyAddress from './_CompanyAddress'
import Users from './_Users'

moment.locale('es')

const Company = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [companyDetailsModalOpen, setCompanyDetailsModalOpen] = useState(false)
    const [companyAddressModalOpen, setCompanyAddressModalOpen] = useState(false)
    const [teamMembersModalOpen, setTeamMembersModalOpen] = useState(false)

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imágenes', 'Error')

            return 
        }

        setLoading(true)
        fileUpload(files[0]).then((response) => {
            if (!response.data.success) {
                toastr.error('No pude actualizar tu avatar', 'Error')
                setLoading(false)
                return
            }

            props.updateCompany({
                ...props.company,
                logo: response.data.fileURL
            })

            toastr.success(props.t(`I updated your company's logotype`))
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            toastr.error(props.t(`I had trouble uploading your company's logotype`), 'Error')
        })
    }

    const fileUpload = (file) => {
        try {
            setLoading(true)
            const formData = new FormData()

            formData.append('companyId', props.company.id)
            formData.append('file', file)
    
            return ApiService.uploadCompanyLogo(formData)
        } catch (error) {
            toastr.error(props.t(`I couldn't upload your avatar, please try again`))
        } finally {
            setLoading(false)
        }
    }

    const setAvatar = () => {
        if (!isEmpty(props.company)) {
            if (!isEmpty(props.company.logo)) {
                return props.company.logo
            }
        }

        return DefaultAvatar
    }

    const __renderCompanyModal = () => (
        <Sidemodal
            className="lg"
            title={props.t(`Company Details`)}
            isOpen={companyDetailsModalOpen}
            onRequestClose={() => {
                setCompanyDetailsModalOpen(false)
            }}
            body={<CompanyDetails 
                t={props.t} 
                history={props.history} 
                setLoading={setLoading} 
                company={props.company} 
                updateCompany={props.updateCompany}
                closeModal={setCompanyDetailsModalOpen}
                />} />
    )

    const __renderCompanyAddressModal = () => (
        <Sidemodal
            className="lg"
            title={props.t(`Company Address`)}
            isOpen={companyAddressModalOpen}
            onRequestClose={() => {
                setCompanyAddressModalOpen(false)
            }}
            body={<CompanyAddress 
                t={props.t} 
                history={props.history} 
                setLoading={setLoading} 
                company={props.company} 
                updateCompany={props.updateCompany}
                closeModal={setCompanyAddressModalOpen}
                />} />
    )

    const __renderUsersModal = () => (
        <Sidemodal
            className="lg"
            title={props.t(`Team Members`)}
            isOpen={teamMembersModalOpen}
            onRequestClose={() => {
                setTeamMembersModalOpen(false)
            }}
            body={<Users 
                t={props.t} 
                history={props.history} 
                setLoading={setLoading} 
                company={props.company} 
                updateCompany={props.updateCompany}
                closeModal={setTeamMembersModalOpen}
                />} />
    )

    return (
        <React.Fragment>
            <Row className="settings_company">
                <Col md={3}>
                    <Card>
                        <CardBody>
                            <img
                                className="rounded rounded-circle avatar-lg"
                                width={200}
                                src={setAvatar()}
                                alt={props.company.businessName}
                            />

                            <br /><br />

                            <FormGroup>
                                <Label>{props.t(`Update company's Logotype`)}</Label>

                                <Input type="file"
                                    id="image"
                                    onChange={ _handleFileUpload }
                                />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={9}>
                    <Row className="settings_company_options">
                        <Col md={4}>
                            <Card>
                                <Link to={`/#`} onClick={(e) => {
                                    e.preventDefault()
                                    setCompanyDetailsModalOpen(true)
                                }}>
                                    <CardTitle>{props.t(`Company Details`)}</CardTitle>

                                    <CardBody>{props.t(`Keep up to date your company's details.`)}</CardBody>
                                </Link>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Link to={`/#`} onClick={(e) => {
                                    e.preventDefault()
                                    setCompanyAddressModalOpen(true)
                                }}>
                                    <CardTitle>{props.t(`Company Address`)}</CardTitle>

                                    <CardBody>{props.t(`Keep up to date your company's address.`)}</CardBody>
                                </Link>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Link to={`/users`} onClick={(e) => {
                                    // e.preventDefault()
                                    // setTeamMembersModalOpen(true)
                                }}>
                                    <CardTitle>{props.t(`Team Members`)}</CardTitle>

                                    <CardBody>{props.t(`Invite team members to help you control your company.`)}</CardBody>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {companyDetailsModalOpen && __renderCompanyModal()}
            {companyAddressModalOpen && __renderCompanyAddressModal()}
            {teamMembersModalOpen && __renderUsersModal()}
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return { company: state.Auth.activeCompany }
}

export default connect(
    mapStatetoProps, { updateCompany }
)(withRouter(withNamespaces()(Company)))