import React from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import 'toastr/build/toastr.min.css'
import { withNamespaces } from 'react-i18next'

const Orders = (props) => {
    return (
        <React.Fragment>
            <Row className="settings_company">
                <Col md={12}>
                    <Row className="settings_company_options">
                        <Col md={4}>
                            <Card>
                                <Link to={`/tax-schemes`}>
                                    <CardTitle>{props.t(`Tax Schemes`)}</CardTitle>

                                    <CardBody>{props.t(`Apply different tax schemes to orders`)}</CardBody>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Orders))