import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Users/Login"
import Logout from "../pages/Users/Logout"
import Signup from "../pages/Users/Signup"
import Verify from "../pages/Users/Verify"
import RecuperarPass from "../pages/Users/recoverpassword"
import ConfirmarRecuperarPass from "../pages/Users/ConfirmRecover"

import Clientes from "../pages/Customers/Index"
import AgregarCliente from "../pages/Customers/Create"
import EditarCliente from "../pages/Customers/Edit"

import CustomerAddresses from "../pages/Customers/addresses/Index"
import CustomerAddressesCreate from "../pages/Customers/addresses/Create"
import CustomerAddressesEdit from "../pages/Customers/addresses/Edit"

import Suppliers from "../pages/Suppliers/Index"
import SuppliersCreate from "../pages/Suppliers/Create"
import SuppliersEdit from "../pages/Suppliers/Edit"

import Dashboard from "../pages/dashboard/index"

import Reportes from "../pages/reportes/Index"

import Brands from "../pages/Brands/Index"
import BrandsCreate from "../pages/Brands/Create"
import BrandsEdit from "../pages/Brands/Edit"

import Families from "../pages/Families/Index"
import FamiliesCreate from "../pages/Families/Create"
import FamiliesEdit from "../pages/Families/Edit"

import Categories from "../pages/Categories/Index"
import CategoriesCreate from "../pages/Categories/Create"
import CategoriesEdit from "../pages/Categories/Edit"

import Subcategories from "../pages/Subcategories/Index"
import SubcategoriesCreate from "../pages/Subcategories/Create"
import SubcategoriesEdit from "../pages/Subcategories/Edit"

import Branches from "../pages/Branches/Index"
import BranchesCreate from "../pages/Branches/Create"
import BranchesEdit from "../pages/Branches/Edit"

import Users from "../pages/Users/Index"
import UsersCreate from "../pages/Users/Create"
import UsersEdit from "../pages/Users/Edit"

import Products from "../pages/Products/Index"
import ProductsCreate from "../pages/Products/Create"
import ProductsEdit from "../pages/Products/Edit"
import ProductsImport from "../pages/Products/Import"
import ProductView from "../pages/Products/View"
import ProductUpdateProducts from "../pages/Products/UpdateProducts"
import ProductUpdatePrices from "../pages/Products/UpdatePrices"
import ProductUpdateStock from "../pages/Products/UpdateStock"

import BankAccount from "../pages/Bank/Index"
import BankAccountCreate from "../pages/Bank/Create"
import BankAccountEdit from "../pages/Bank/Edit"

import PriceList from "../pages/PriceTypes/Index"
import PriceListCreate from "../pages/PriceTypes/Create"
import PriceListEdit from "../pages/PriceTypes/Edit"

import TaxSchemes from "../pages/TaxSchemes/Index"
import TaxSchemesCreate from "../pages/TaxSchemes/Create"
import TaxSchemesEdit from "../pages/TaxSchemes/Edit"

import Expenses from "../pages/Expenses/Index"
import ExpensesCreate from "../pages/Expenses/Create"
import ExpensesEdit from "../pages/Expenses/Edit"

import POS from "../pages/POS/Index"
import PrintSale from "../pages/Sales/Print"
import Sales from "../pages/Sales/Index"

import PurchaseOrder from "../pages/PurchaseOrder/Index"
import PurchaseOrderCreate from "../pages/PurchaseOrder/Create"
import PurchaseOrderPrint from "../pages/PurchaseOrder/Print"
import PurchaseOrderView from "../pages/PurchaseOrder/View"

import SalesOrder from "../pages/SalesOrder/Index"
import SalesOrderCreate from "../pages/SalesOrder/Create"
import SalesOrderPrint from "../pages/SalesOrder/Print"
import SalesOrderView from "../pages/SalesOrder/View"

import PurchaseQuote from "../pages/PurchaseQuote/Index"
import PurchaseQuoteCreate from "../pages/PurchaseQuote/Create"
import PurchaseQuotePrint from "../pages/PurchaseQuote/Print"

import Settings from "../pages/Settings/Index"

const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    
    { path: "/customers/addresses/:customerId/edit/:id", component: CustomerAddressesEdit },
    { path: "/customers/addresses/:customerId/add", component: CustomerAddressesCreate },
    { path: "/customers/addresses/:customerId", component: CustomerAddresses },

    { path: "/customers/edit/:id", component: EditarCliente },
    { path: "/customers/add", component: AgregarCliente },
    { path: "/customers", component: Clientes },
    
    { path: "/suppliers/edit/:id", component: SuppliersEdit },
    { path: "/suppliers/add", component: SuppliersCreate },
    { path: "/suppliers", component: Suppliers },

    { path: "/brands/edit/:id", component: BrandsEdit },
    { path: "/brands/add", component: BrandsCreate },
    { path: "/brands", component: Brands },

    { path: "/families/edit/:id", component: FamiliesEdit },
    { path: "/families/add", component: FamiliesCreate },
    { path: "/families", component: Families },

    { path: "/categories/edit/:id", component: CategoriesEdit },
    { path: "/categories/add", component: CategoriesCreate },
    { path: "/categories", component: Categories },

    { path: "/subcategories/edit/:id", component: SubcategoriesEdit },
    { path: "/subcategories/add", component: SubcategoriesCreate },
    { path: "/subcategories", component: Subcategories },

    { path: "/branches/edit/:id", component: BranchesEdit },
    { path: "/branches/add", component: BranchesCreate },
    { path: "/branches", component: Branches },

    { path: "/users/edit/:id", component: UsersEdit },
    { path: "/users/add", component: UsersCreate },
    { path: "/users", component: Users },
    
    { path: "/products/view/:id", component: ProductView },
    { path: "/products/edit/:id", component: ProductsEdit },
    { path: "/products/add", component: ProductsCreate },
    { path: "/products/import", component: ProductsImport },
    { path: "/products/update-prices", component: ProductUpdatePrices },
    { path: "/products/update-stock", component: ProductUpdateStock },
    { path: "/products/update-products", component: ProductUpdateProducts },
    { path: "/products", component: Products },

    { path: "/price-lists/edit/:id", component: PriceListEdit },
    { path: "/price-lists/add", component: PriceListCreate },
    { path: "/price-lists", component: PriceList },

    { path: "/tax-schemes/edit/:id", component: TaxSchemesEdit },
    { path: "/tax-schemes/add", component: TaxSchemesCreate },
    { path: "/tax-schemes", component: TaxSchemes },

    { path: "/bank/edit/:id", component: BankAccountEdit },
    { path: "/bank/add", component: BankAccountCreate },
    { path: "/bank", component: BankAccount },

    { path: "/expenses/edit/:id", component: ExpensesEdit },
    { path: "/expenses/add", component: ExpensesCreate },
    { path: "/expenses", component: Expenses },

    { path: "/sales", component: Sales },
    { path: "/pos", component: POS },
    
    { path: "/purchase-order/view/:id", component: PurchaseOrderView },
    { path: "/purchase-order/new", component: PurchaseOrderCreate },
    { path: "/purchase-order", component: PurchaseOrder },

    { path: "/sales-order/view/:id", component: SalesOrderView },
    { path: "/sales-order/new", component: SalesOrderCreate },
    { path: "/sales-order", component: SalesOrder },

    { path: "/purchase-quote/edit/:id", component: PurchaseQuoteCreate },
    { path: "/purchase-quote/new", component: PurchaseQuoteCreate },
    { path: "/purchase-quote", component: PurchaseQuote },

    { path: "/settings/:route", component: Settings },
    { path: "/settings", component: Settings },

    { path: "/reportes", component: Reportes },
    
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
    { path: "/signup", component: Signup },
    { path: "/login", component: Login },
    { path: "/verify/:companyId/:userId/:verificationCode", component: Verify },
    { path: "/recuperar-password", component: RecuperarPass },
    { path: "/confirmar-recuperar-password/:token", component: ConfirmarRecuperarPass },
    { path: "/sales/print/:id", component: PrintSale },
    { path: "/purchase-order/print/:id", component: PurchaseOrderPrint },
    { path: "/sales-order/print/:id", component: SalesOrderPrint },
    { path: "/purchase-quote/print/:id", component: PurchaseQuotePrint },
    { path: "/logout", component: Logout },
]

export { authProtectedRoutes, publicRoutes }
