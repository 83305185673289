import React from "react"
import PropTypes from "prop-types"
import ProductHelper from '../helpers/ProductHelper'

export const Context = React.createContext({})
export const Provider = (props) => {
    const { children } = props
    const [loading, setLoading] = React.useState(false)
    const [currentRoute, setCurrentRoute] = React.useState('')
    const [pagination, setPagination] = React.useState([])
    const [cartItems, setCartItems] = React.useState([])
    const [activePriceList, setActivePriceList] = React.useState({})
    const [taxRate, setTaxRate] = React.useState(0)
    const [folio, setFolio] = React.useState()
    const [activeBranch, setActiveBranch] = React.useState({
        value: null,
        label: ''
    })
    const [breadcrumb, setBreadcrumb] = React.useState({
        icon: '',
        parent: '',
        parentLink: '',
        item: ''
    })
    const [activeCustomer, setActiveCustomer] = React.useState({
        value: null,
        label: ''
    })

    const addItemToCart = async (data) => {
        try {
            let itemfound = false
            let items = cartItems

            setLoading(true)
            await items.forEach((item) => {
                if (item.product) {
                    if (item.product.id === data.product.id && item.product.product.productType !== 'serialized') {
                        let quantity = +item.quantity + +data.quantity
                        let scheme = item.product.product.taxScheme
                        let { subtotal, taxAmount, total, discount } = ProductHelper.calculateTotals({
                            unitPrice: item.unitPrice,
                            quantity,
                            discountRate: item.discount,
                            scheme
                        })

                        item.quantity = quantity
                        item.subtotal = subtotal
                        item.discount = discount
                        item.taxAmount = taxAmount
                        item.total = total
                        itemfound = true
                    }
                }
            })

            if (!itemfound) { items.push(data) }

            setCartItems(items)
        } finally {
            setLoading(false)
        }
    }

    const removeItemFromCart = async (item) => {
        const result = cartItems.filter(i => i.product.id !== item.product.id)
        setCartItems(result)
    }

    const increaseItemQuantity = async (item, i) => {
        try {
            setLoading(true)

            let quantity = item.quantity++
            let scheme = item.product.product.taxScheme
            let { subtotal, taxAmount, total, discount } = await ProductHelper.calculateTotals({
                unitPrice: item.unitPrice,
                quantity,
                discountRate: item.discount,
                scheme
            })

            const items = [...cartItems]
            items[i] = {
                ...items[i],
                quantity,
                subtotal,
                discount,
                taxAmount,
                total,
                scheme
            }

            setCartItems(items)
        } finally {
            setLoading(false)
        }
    }

    const decreaseItemQuantity = async (item, i) => {
        let quantity = item.quantity--
        let scheme = item.product.product.taxScheme
        let { subtotal, taxAmount, total, discount } = ProductHelper.calculateTotals({
            unitPrice: item.unitPrice,
            quantity,
            discountRate: item.discount,
            scheme
        })

        const items = [...cartItems]
        items[i] = {
            ...items[i],
            quantity,
            subtotal,
            discount,
            taxAmount,
            total,
            scheme
        }

        setCartItems(items)
    }

    const getCartTotal = () => {
        if (!cartItems.length) { return 0 }

        return cartItems.reduce((ac, next) => ac + next.quantity * getPriceByActiveListId(next.product), 0)
    }

    const getPriceByActiveListId = (item) => {
        const price = item.prices.filter(p => p.priceTypeId === activePriceList.id)

        return price[0].amount
    }

    const clearCart = () => {
        setCartItems([])
    }

    const getCartTotals = () => {
        if (!cartItems.length) { 
            return {
                subtotal: 0,
                discount: 0,
                taxAmount: 0,
                total: 0
            } 
        }

        const subtotal = cartItems.reduce((a, b) => a + +b.subtotal, 0)
        const discount = cartItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)
        const taxAmount = cartItems.reduce((a, b) => a + +b.taxAmount, 0)
        const total = cartItems.reduce((a, b) => a + +b.total, 0)

        return {
            subtotal,
            discount,
            taxAmount,
            total
        }
    }

    const objContext = {
        loading,
        setLoading,
        currentRoute,
        setCurrentRoute,
        pagination,
        setPagination,
        cartItems,
        addItemToCart,
        removeItemFromCart,
        increaseItemQuantity,
        decreaseItemQuantity,
        clearCart,
        activePriceList,
        setActivePriceList,
        setCartItems,
        getCartTotal,
        getCartTotals,
        activeCustomer,
        setActiveCustomer,
        setTaxRate,
        folio,
        setFolio,
        taxRate,
        breadcrumb,
        setBreadcrumb,
        setActiveBranch,
        activeBranch
    }

    return <Context.Provider value={objContext}>{children}</Context.Provider>
}

Provider.propTypes = { children: PropTypes.any }
export const { Consumer } = Context