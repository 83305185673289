import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from 'reactstrap'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    // const [data, setData] = useState(props.data)
    const [familyId, setFamilyId] = useState('')
    const [categoryId, setCategoryId] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')

    const [families, setFamilies] = useState([])
    const [categories, setCategories] = useState([])

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setFamilyId(data.familyId)
            setCategoryId(data.categoryId)
            setName(data.name)
            setDescription(data.description)
            setImage(data.image)

            if (data.familyId !== undefined && families.length === 0) {
                fetchCategories(data.familyId)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        (async () => {
            await fetchFamilies()
        })()
    }, []) // eslint-disable-line

    async function fetchFamilies() {
        setLoading(true)
        const response = await ApiService.getFamilies()
        let data = []

        response.data.data.map((item) => {
            return data.push({
                value: item.id,
                label: item.name,
            })
        })

        setLoading(false)
        setFamilies(data)
    }

    const _handleFamilyChange = async (e) => {
        const id = e.target.value

        setFamilyId(id)
        await fetchCategories(id)
    }

    async function fetchCategories(id) {
        setLoading(true)
        const response = await ApiService.getCategoriesByFamilyId(id)
        let data = []

        response.data.data.map((item) => {
            return data.push({
                value: item.id,
                label: item.name,
            })
        })

        setLoading(false)
        setCategories(data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        const data = {
            familyId,
            categoryId,
            name,
            description,
            image
        }

        setLoading(true)
        if (props.data !== undefined) { 
            data.id = props.data.id
            const response = await ApiService.updateSubcategory(data).catch(error => {
                toastr.error('No pude editar esta subcategoría', 'Error')
            })

            if (response.data.success) {
                toastr.success('Edité una subcategoría', '¡Éxito!')
            }

            setLoading(false)
            return
        }

        const response = await ApiService.createSubcategory(data).catch(error => {
            toastr.error('No pude agregar esta subcategoría', 'Error')
        })

        if (response.data.success) {
            toastr.success('Agregué una subcategoría', '¡Éxito!')
        }

        setLoading(false)
        _clearForm()
    }

    const _validateForm = () => {
        if (name === '') { return false }

        return true
    }

    const _clearForm = () => {
        setName('')
        setImage('')
        setDescription('')

        document.getElementById('name').focus()
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imagenes', 'Error')

            return 
        }
        setLoading(true)
        fileUpload(files[0]).then((response) => {
            setImage(response.data.fileName)
            setLoading(false)
            toastr.success('Subí la imagen', '¡Éxito!')
        }).catch(error => {
            console.log('fileUpload', error)
            setLoading(false)
            toastr.error('No pude subir la imagen', 'Error')
        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('file', file)

        return ApiService.uploadFamilyImage(formData)
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label className="control-label">
                                                {props.t(`Family`)} *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={familyId}
                                                onChange={ _handleFamilyChange }>
                                                <option key="0" value="0">{props.t(`Select a family`)}... </option> 
                                                {families.map((item, i) => {
                                                    let selected = familyId === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="control-label">
                                                {props.t(`Category`)} *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={categoryId}
                                                onChange={(e) => { setCategoryId(e.target.value) }}>
                                                <option key="0" value="0">{props.t(`Select a category`)}... </option> 
                                                {categories.map((item, i) => {
                                                    let selected = categoryId === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>
                                                {props.t(`Name`)} *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                id="name"
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="name">
                                                {props.t(`Description`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={description}
                                                onChange={event => setDescription(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>{props.t(`Image`)}</Label>

                                            <Input type="file"
                                                id="image"
                                                onChange={ _handleFileUpload }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))