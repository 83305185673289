import React, { useContext, useEffect, useState, useRef } from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Button,
    FormGroup,
    Label,
    Table
} from 'reactstrap'
import { withRouter, Link } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import Select from "react-select"
import { GeneralContext } from '../../context/'
import ApiService from '../../data/services/ApiService'
import { isEmpty, head, filter, debounce } from 'lodash'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import DefaultImage from '../../assets/images/avatar-product-placeholder.png'
import CurrencyFormat from 'react-currency-format'
import { connect } from "react-redux"
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import Modal from '../../components/Modal'
import ProductHelper from '../../helpers/ProductHelper'

registerLocale('es', es)

const Index = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext
    const [branchId, setBranchId] = useState()
    const [folio, setFolio] = useState(0)
    const [dueDate, setDueDate] = useState(new Date((new Date()).getTime() + (10 * 86400000))) // 10 days @todo: algo to calculate projection
    const [email, setEmail] = useState('')
    const [notes, setNotes] = useState('')
    const [customerId, setCustomerId] = useState()
    const [customerAddressId, setCustomerAddressId] = useState()
    const [currencyId, setCurrencyId] = useState()
    const [currencyRate, setCurrencyRate] = useState(0.00)
    const [lineItems, setLineItems] = useState([])
    // const [products] = useState([])
    const [branches, setBranches] = useState([])
    const [customers, setCustomers] = useState([])
    const [customerAddresses, setCustomerAddresses] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [requireShipping, setRequireShipping] = useState(false)
    const [requiredShippingDate, setRequiredShippingDate] = useState(new Date((new Date()).getTime() + (5 * 86400000))) // 5 days @todo: algo to calculate projection
    const [shippingCosts, setShippingCosts] = useState()

    const [activePriceList, setActivePriceList] = useState()
    const [seriesModalOpen, setSeriesModalOpen] = useState(false)
    const [productInUse, setProductInUse] = useState()
    const [tempSeries, setTempSeries] = useState('')
    // const [shippingAddress, setShippingAddress] = useState()
    const debouncedSearch = useRef(
        debounce(async (criteria, activePriceList) => {
            if (!activePriceList) { return }

            const response = await ApiService.searchVariants(criteria, activePriceList.id)

            setFilteredProducts(response.data.products)
        }, 300)
    ).current

    useEffect(() => {
        if (!customerId) { return }

        (async () => {
            await fetchCustomerAddresses(customerId)
        })()
    }, [customerId]) // eslint-disable-line

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Sales Orders`),
            parentLink: '/sales-order',
            item: `${props.t(`Creating order`)} #SO-${folio}`
        })
    }, [folio]) // eslint-disable-line

    useEffect(() => {
        const branch = branches.filter(b => {
            return props.profile.branchId === b.value
        })

        if (branch.length < 1) { return }

        setBranchId(branch[0])
    }, [branches]) // eslint-disable-line

    useEffect(() => {
        (async () => {
            await fetchDefaultPriceList()
            await fetchNextFolio()
            await fetchCustomers()
            await fetchBranches()
            await fetchCurrencies()
        })()
    }, []) // eslint-disable-line

    useEffect(() => {
        (() => {
            debouncedSearch.cancel()
        })()
    }, [debouncedSearch]) // eslint-disable-line

    async function fetchDefaultPriceList() {
        try {
            setLoading(true)
            const response = await ApiService.fetchDefaultPriceList()

            setActivePriceList(response.data.list)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    async function fetchCustomerAddresses(customerId) {
        try {
            setLoading(true)

            let data = []
            const response = await ApiService.getAddressByCustomerId(customerId.value)

            response.data.addresses.map(i => {
                return data.push({
                    value: i.id,
                    label: i.streetName
                })
            })

            setCustomerAddresses(data)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch this customer's addresses`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchCustomers() {
        try {
            setLoading(true)
            const response = await ApiService.getCustomers()
            let customers = []

            response.data.customers.map((item) => {
                return customers.push({
                    value: item.id,
                    label: item.businessName,
                    email: item.email
                })
            })

            setLoading(false)
            setCustomers(customers)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your customers`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchBranches() {
        try {
            setLoading(true)
            const response = await ApiService.getBranches()
            let branches = []

            response.data.branches.map((b) => {
                return branches.push({
                    value: b.id,
                    label: b.branchName,
                })
            })

            setLoading(false)
            setBranches(branches)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your branches`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchCurrencies() {
        try {
            setLoading(true)
            const response = await ApiService.getCurrencies()
            let currencies = []

            response.data.currencies.map((b) => {
                return currencies.push({
                    value: b.id,
                    label: b.name,
                })
            })

            setLoading(false)
            setCurrencies(currencies)
            setCurrencyId(currencies[0])
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch currencies list`))
        } finally {
            setLoading(false)
        }
    }

    const __findAndAddProduct = async (criteria) => {
        try {
            setLoading(true)
            const response = await ApiService.searchVariants(criteria, activePriceList.id)
            if (response.data.products.length === 1) {
                const product = response.data.products[0]

                if (product.product.productType === 'serialized') {
                    setProductInUse(product)
                    setSeriesModalOpen(true)
                } else {
                    __addItemToCart(product, false)
                }

                document.getElementById("searchInput").value = ''
                setFilteredProducts(response.data.products)
            }
        } catch (error) {
            // catch error
        } finally {
            setLoading(false)
        }
    }

    const _filterProducts = async (value) => {
        try {
            setLoading(true)
            debouncedSearch(value, activePriceList)
        } catch (error) {
            // catch error
            toastr.error(props.t(`I couldn't fetch your products`), props.t(`Oop's!`))
        } finally {
            setLoading(false)
        }
    }

    const addItemToCart = async (data) => {
        try {
            let itemfound = false
            let items = lineItems

            setLoading(true)
            await items.forEach((item) => {
                if (item.product) {
                    if (item.product.id === data.product.id) {
                        let quantity = +item.quantity + +data.quantity
                        let scheme = item.product.product.taxScheme
                        let { subtotal, discount, taxAmount, total, unitPrice } = ProductHelper.calculateTotals({
                            unitPrice: ProductHelper.getVariantPriceByPriceId(item.product, activePriceList.id),
                            subtotal: +item.subtotal + (+data.quantity * +item.unitPrice),
                            quantity,
                            discountRate: data.discount,
                            scheme
                        })

                        item.branchId = branchId
                        item.quantity = quantity
                        item.subtotal = subtotal
                        item.discount = discount
                        item.taxAmount = taxAmount
                        item.total = total
                        item.unitPrice = unitPrice
                        itemfound = true
                    }
                }
            })

            if (!itemfound) { items.push(data) }

            setLineItems(items)
        } finally {
            setLoading(false)
        }
    }

    const _renderProductAvatar = (product, size) => {
        if (!size) { size = 48 }

        if (isEmpty(product.gallery)) {
            return (<img alt={``} style={{ maxWidth: `${size}px` }} src={DefaultImage} />)
        }

        const image = filter(product.gallery, { featured: true })
        if (isEmpty(head(image))) {
            return (<img alt={``} style={{ maxWidth: `${size}px` }} src={head(product.gallery).fileName || DefaultImage} />)
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <img style={{ maxWidth: `${size}px` }} alt={head(image).id} src={`${head(image).fileName}`} />
            </div>
        )
    }

    const removeItemFromCart = async (item) => {
        const result = lineItems.filter(i => i.product.id !== item.product.id)
        setLineItems(result)
    }

    async function fetchNextFolio() {
        try {
            setLoading(true)
            const response = await ApiService.getNextSalesOrderFolio()

            setFolio(response.data.folio)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const _saveOrderAsDraft = async () => {
        try {
            setLoading(true)
        } catch (error) {
            toastr.error(`no pude guardar tu orden, inténtalo de nuevo`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    async function _createOrder() {
        try {
            if (!validateForm()) {
                toastr.info(props.t(`Don't forget to fill in the details`))

                return false
            }

            console.log(branchId)

            setLoading(true)
            const subtotal = lineItems.reduce((a, b) => a + +b.subtotal, 0)
            const discount = lineItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)
            const taxAmount = lineItems.reduce((a, b) => a + +b.taxAmount, 0)
            const total = lineItems.reduce((a, b) => a + +b.total, 0)
            const response = await ApiService.saveSalesOrder({
                branchId: branchId.value,
                customerId: customerId.value,
                currencyId: currencyId.value,
                customerAddressId: customerAddressId ? customerAddressId.value : '',
                priceTypeId: activePriceList.id,
                folio,
                dueDate,
                email,
                notes,
                currencyRate,
                shippingCosts,
                requireShipping,
                requiredShippingDate,
                subtotal,
                taxAmount,
                discount,
                total,
                lineItems,
                channel: 'Vanta UI'
            })

            if (response.data.code === 'E_MISSING_FIELDS') {
                toastr.error(props.t(`Don't forget to fill in the blanks`))

                return
            }

            if (response.data.code !== 'ORDER_CREATED') {
                toastr.error(props.t(`I couldn't save your sales order, please try again`))

                return
            }

            const w = window.open(`/sales-order/print/${response.data.order.id}`, props.t(`Sales Order`), "width=900,height=960,menubar=0,status=0,scrollbars=1,resizable=1")
            if (!w) {
                toastr.info(props.t(`I couldn't open the sales order receipt, make sure to give your browser permissions`))
            }

            props.history.push(`/sales-order/view/${response.data.order.id}`)
        } catch (error) {
            toastr.error(props.t(`I couldn't save your sales order, please try again`))
        } finally {
            setLoading(false)
        }
    }

    const validateForm = () => {
        if (lineItems.length < 1) { return false }
        if (branchId === undefined) { return false }
        if (customerId === undefined) { return false }
        if (customerAddressId === undefined) { return false }

        return true
    }

    const __addItemToCart = (product, serie) => {
        let quantity = 1
        let discount = 0.00
        let scheme = product.product.taxScheme
        let { subtotal, taxAmount, total, unitPrice } = ProductHelper.calculateTotals({
            unitPrice: ProductHelper.getVariantPriceByPriceId(product, activePriceList.id),
            subtotal: ProductHelper.getVariantPriceByPriceId(product, activePriceList.id),
            quantity,
            discountRate: discount,
            scheme
        })

        addItemToCart({
            product,
            quantity,
            discount,
            taxAmount: taxAmount,
            branchId,
            itemsFulfilled: 0,
            unitCost: product.unitCost,
            unitPrice,
            subtotal,
            serie,
            total,
            scheme
        })
    }

    const __renderSeriesModal = () => (
        <Modal
            title={props.t(`Scan barcode or write series`)}
            isOpen={seriesModalOpen}
            onRequestClose={() => {
                setSeriesModalOpen(false)
            }}
            body={
                <React.Fragment>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Input
                                    autoFocus={true}
                                    type="text"
                                    value={tempSeries}
                                    style={{ height: 100 }}
                                    placeholder={props.t(`Scan barcode or write series`)}
                                    onChange={e => setTempSeries(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </React.Fragment>
            }
            footer={
                <Row className='form-actions'>
                    <Col sm={12}>
                        <Button color='primary' onClick={(e) => {
                            e.preventDefault()
                            if (tempSeries === '') {
                                toastr.warning(props.t(`Please input the product's series or batch`))
                                return
                            }

                            __addItemToCart(productInUse, tempSeries)
                            setProductInUse(null)
                            setSeriesModalOpen(false)
                            setTempSeries('')
                        }}>
                            {props.t(`Continue`)}
                        </Button>
                    </Col>
                </Row>
            }/>
    )

    // const __reloadForm = async () => {
    //     setDueDate(new Date((new Date()).getTime() + (10 * 86400000)))
    //     setEmail('')
    //     setNotes('')
    //     setCustomerId()
    //     setCurrencyId()
    //     setLineItems([])
    //     setCurrencyRate(0.00)
    //     setRequireShipping(false)
    //     setRequiredShippingDate(new Date((new Date()).getTime() + (5 * 86400000)))
    //     setShippingCosts()

    //     await fetchNextFolio()
    // }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <Row>
                            <Col sm={12} md={4} lg={4}>
                                <FormGroup>
                                    <Select
                                        value={customerId}
                                        defaultValue={customerId}
                                        placeholder={props.t(`Select customer`)}
                                        onChange={(value) => {
                                            setEmail(value.email)
                                            setCustomerId(value)
                                        }}
                                        options={customers}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4} lg={3}>
                                <FormGroup style={{paddingTop: '6px'}}>
                                    {props.t(`or`)} <Link to='/customers/add'>{props.t(`create a new customer`)}</Link>
                                </FormGroup>
                            </Col>
                            <Col style={{ textAlign: 'right' }} sm={12} md={4} lg={5}>
                                <CardTitle>
                                    <Button className='btn btn-secondary' onClick={(e) => {
                                        e.preventDefault()
                                        _saveOrderAsDraft()
                                    }}>
                                        {props.t(`Save as draft`)}
                                    </Button>

                                    <Button color='primary' onClick={(e) => {
                                        e.preventDefault()
                                        _createOrder()
                                    }}>
                                        {props.t(`Create Order`)}
                                    </Button>
                                </CardTitle>
                            </Col>
                        </Row>
                    </Card>

                    <Row>
                        <Col sm={12} md={4} lg={5}>
                            <Card>
                                <Row>
                                    <Col md={6} sm={12}>
                                        <FormGroup>
                                            <Label>{props.t(`Due date`)}</Label>

                                            <DatePicker
                                                locale="es"
                                                selected={dueDate}
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy"
                                                onChange={date => setDueDate(date)}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} sm={12}>
                                        <FormGroup>
                                            <Label>{props.t(`Location`)}</Label>

                                            <Select
                                                value={branchId}
                                                defaultValue={branchId}
                                                placeholder={props.t(`Select a location`)}
                                                onChange={(value) => { 
                                                    setBranchId(value)
                                                }}
                                                options={branches}
                                                classNamePrefix="select2-selection"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={12} md={12}>
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input type="checkbox" className="custom-control-input" id="manageStock" onChange={() => false} checked={requireShipping} />
                                            <label className="custom-control-label" onClick={() => { setRequireShipping(!requireShipping) }} > {props.t(`Requires Shipping?`)}</label>
                                        </div>
                                    </Col>

                                    {requireShipping && (
                                        <>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Label>{props.t(`Req. ship date`)}</Label>

                                                    <DatePicker
                                                        locale="es"
                                                        selected={requiredShippingDate}
                                                        className="form-control"
                                                        dateFormat="dd/MM/yyyy"
                                                        onChange={date => setRequiredShippingDate(date)}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Label>{props.t(`Shipping Costs`)}</Label>

                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                        <Input
                                                            id={shippingCosts}
                                                            type="number"
                                                            name="shippingCosts"
                                                            value={shippingCosts}
                                                            pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                            className="form-control"
                                                            placeholder={props.t(`0.00`)}
                                                            onChange={e => setShippingCosts(e.target.value)} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </>
                                    )}

                                    <Col sm={12}>
                                        <Select
                                            value={customerAddressId}
                                            defaultValue={customerAddressId}
                                            placeholder={props.t(`Select customer address`)}
                                            onChange={(value) => { 
                                                setCustomerAddressId(value)
                                            }}
                                            options={customerAddresses}
                                            classNamePrefix="select2-selection"
                                        />

                                        <FormGroup>
                                            <Input
                                                type="email"
                                                name="email"
                                                value={email}
                                                placeholder={props.t(`Customer's E-Mail`)}
                                                onChange={e => setEmail(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Card>

                            <Card>
                                <FormGroup>
                                    <Label>{props.t(`Currency`)}</Label>

                                    <Select
                                        value={currencyId}
                                        defaultValue={currencyId}
                                        placeholder={props.t(`Select a currency`)}
                                        onChange={(value) => { 
                                            setCurrencyId(value)
                                        }}
                                        options={currencies}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>{props.t(`Currency Rate`)}</Label>

                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                        <Input
                                            id={currencyRate}
                                            type="number"
                                            value={currencyRate}
                                            pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                            className="form-control"
                                            placeholder={props.t(`Write currency rate`)}
                                            onChange={e => setCurrencyRate(e.target.value)} />
                                    </InputGroup>
                                </FormGroup>
                            </Card>

                            <Card>
                                <CardBody>
                                    <Row>
                                        <FormGroup>
                                            <Label>{props.t(`Remarks`)}</Label>

                                            <Input
                                                type="textarea"
                                                name="notes"
                                                value={notes}
                                                style={{ height: 100 }}
                                                placeholder={props.t(`Write any important comments you may have about this order`)}
                                                onChange={e => setNotes(e.target.value)} />
                                        </FormGroup>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col sm={9} md={8} lg={7}>
                            <Card>
                                <CardBody>
                                    {/* Product Search */}
                                    <Row>
                                        <Col sm={12}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-barcode" aria-hidden="true" /> / <i className="fa fa-search" aria-hidden="true" />
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <Input
                                                    autoFocus={true}
                                                    id="searchInput"
                                                    type={`search`}
                                                    className="form-control"
                                                    style={{ zIndex: '0' }}
                                                    placeholder={props.t(`Search products or scan barcode`)}
                                                    onKeyUp={(e) => _filterProducts(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Tab') {
                                                            e.preventDefault()
                                                            __findAndAddProduct(e.target.value)
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingTop: '24px' }}>
                                        {filteredProducts.map((product, i) => {
                                            // eslint-disable-next-line
                                            if (i >= 8) { return }

                                            return (
                                                <Col className='product' key={i} xs={12} sm={4} md={3} lg={3}
                                                    style={{ padding: '16px', textAlign: 'center' }}>
                                                    <a style={{ color: '#464b56' }} href="/pos" onClick={(e) => {
                                                        e.preventDefault()

                                                        if (product.product.productType === 'serialized') {
                                                            setProductInUse(product)
                                                            setSeriesModalOpen(true)
                                                        } else {
                                                            __addItemToCart(product, false)
                                                        }

                                                        document.getElementById("searchInput").focus()
                                                    }}>
                                                        <figure className='product-photo'>{_renderProductAvatar(product)}</figure>
                                                        <span className='product-name'>{product.name}</span> <br />
                                                        <span className='product-price'>
                                                            {props.t(`Retail Price`)}: <CurrencyFormat
                                                                displayType="text"
                                                                thousandSeparator
                                                                prefix="$"
                                                                value={ProductHelper.getVariantPriceByPriceId(product, activePriceList.id)} /> <br />
                                                            {props.t(`Units`)}: {product.stock?.reduce((a, b) => a + b.currentStock, 0)}
                                                        </span>
                                                    </a>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </CardBody>
                            </Card>

                            {lineItems.length > 0 && (
                                <Card>
                                    <Table className='table-responsive' responive="true">
                                        <thead>
                                            <tr>
                                                <th>{props.t(`Product and SKU`)}</th>
                                                <th>{props.t(`QTY`)}</th>
                                                <th><span title={props.t(`Stock after fullfiling this order`)}>{props.t(`Stock`)}</span></th>
                                                <th>{props.t(`Location`)}</th>
                                                <th>{props.t(`Unit price`)}</th>
                                                <th>{props.t(`Discount`)}</th>
                                                <th>{props.t(`Subtotal`)}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lineItems.map((item, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <Link to={`#`} onClick={(e) => removeItemFromCart(item)}><i className='mdi mdi-close'></i></Link>
                                                        <strong>{item.product.name}</strong> <em>{item.product.sku}</em><br />
                                                    </td>
                                                    <td width={100}>
                                                        <Input 
                                                            type='number'
                                                            value={item.quantity}
                                                            onChange={(e) => {
                                                                const { value } = e.target

                                                                let importe = +value * +item.unitPrice
                                                                let discount = importe * (+item.discount / 100)
                                                                let subtotal = importe - discount
                                                                let taxAmount = 0
                                                                let scheme = item.product.product.taxScheme

                                                                for (let tax of scheme.value) {
                                                                    taxAmount += +subtotal * +tax.taxRate
                                                                }

                                                                let total = +subtotal + +taxAmount
                                                                const items = [...lineItems]
                                                                items[i] = {
                                                                    ...items[i],
                                                                    quantity: value,
                                                                    subtotal,
                                                                    discount,
                                                                    taxAmount,
                                                                    total
                                                                }

                                                                setLineItems(items)
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span title={props.t(`Stock after fullfiling this order`)}>{+item.product.stock?.reduce((a, b) => a + b.currentStock, 0) - +item.quantity}</span>
                                                    </td>
                                                    <td width={150}>
                                                        <Select
                                                            value={item.branchId}
                                                            defaultValue={item.branchId}
                                                            placeholder={props.t(`Location`)}
                                                            onChange={(value) => { 
                                                                const items = [...lineItems]

                                                                items[i] = {
                                                                    ...items[i],
                                                                    branchId: value
                                                                }

                                                                setLineItems(items)
                                                            }}
                                                            options={branches}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </td>
                                                    <td width={150}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                            <Input
                                                                type="number"
                                                                pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                                value={item.unitPrice}
                                                                className="form-control" 
                                                                onChange={(e) => {
                                                                    const { value } = e.target
                                                                
                                                                    let importe = +item.quantity * +value
                                                                    let discount = importe * (+item.discount / 100)
                                                                    let subtotal = importe - discount
                                                                    let taxAmount = 0
                                                                    let scheme = item.product.product.taxScheme

                                                                    for (let tax of scheme.value) {
                                                                        taxAmount += +subtotal * +tax.taxRate
                                                                    }

                                                                    let total = +subtotal + +taxAmount
                                                                    const items = [...lineItems]
                                                                    items[i] = {
                                                                        ...items[i],
                                                                        unitPrice: value,
                                                                        subtotal,
                                                                        discount,
                                                                        taxAmount,
                                                                        total
                                                                    }

                                                                    setLineItems(items)
                                                                }}
                                                                />
                                                        </InputGroup>
                                                    </td>
                                                    <td width={150}>
                                                        <InputGroup>
                                                            <Input
                                                                type="number"
                                                                pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                                className="form-control"
                                                                value={item.discount}
                                                                onChange={(e) => {
                                                                    const { value } = e.target
                                                                    
                                                                    let importe = +item.quantity * +item.unitPrice
                                                                    let discount = importe * (+value / 100)
                                                                    let subtotal = importe - discount
                                                                    let taxAmount = 0
                                                                    let scheme = item.product.product.taxScheme

                                                                    for (let tax of scheme.value) {
                                                                        taxAmount += +subtotal * +tax.taxRate
                                                                    }

                                                                    let total = +subtotal + +taxAmount
                                                                    const items = [...lineItems]
                                                                    items[i] = {
                                                                        ...items[i],
                                                                        discount: value,
                                                                        subtotal,
                                                                        taxAmount,
                                                                        total
                                                                    }

                                                                    setLineItems(items)
                                                                }}
                                                            />
                                                            <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={(item.unitPrice * item.quantity) - (item.unitPrice * item.quantity) * (item.discount / 100)} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Subtotal`)}:</td>
                                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal, 0)} /></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Discount`)}:</td>
                                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)} /></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Tax rate`)}:</td>
                                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.taxAmount, 0)} /></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'right'}} colSpan={7}>{props.t(`Total`)}:</td>
                                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.total, 0)} /></td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>

            {seriesModalOpen && __renderSeriesModal()}
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth.profile
    }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(withNamespaces()(Index)))
