import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label,
    Table
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import Select from 'react-select'

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const options = [{
        value: true,
        label: props.t(`Yes`)
    }, {
        value: false,
        label: props.t(`No`)
    }]

    const [id, setId] = useState('')
    const [label, setLabel] = useState('')
    const [code, setCode] = useState('')
    const [value, setValue] = useState([])
    const [isDefault, setIsDefault] = useState({
        value: false,
        label: props.t(`No`)
    })
    const [taxInclusive, setTaxInclusive] = useState({
        value: false,
        label: props.t(`No`)
    })
    const [tempValue, setTempValue] = useState({
        code: '',
        taxRate: 0,
        taxRateLabel: '',
        type: {
            label: 'Traslado',
            value: 'T'
        }
    })

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setId(data.id)
            setLabel(data.label)
            setCode(data.code)
            setValue(data.value)

            const def = options.find(item => {
                return item.value === data.default
            })

            const taxinc = options.find(item => {
                return item.value === data.taxInclusive
            })

            setIsDefault(def)
            setTaxInclusive(taxinc)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        let data = {
            label,
            code,
            value,
            default: isDefault.value,
            taxInclusive: taxInclusive.value
        }

        setLoading(true)
        if (props.data !== undefined) {
            try {
                const response = await ApiService.updateTaxScheme({
                    ...data, id
                })
    
                if (response.data.success) {
                    toastr.success(props.t(`Tax scheme edited`), '¡Éxito!')
                }
            } catch (error) {
                toastr.error(props.t(`I couldn't edit your tax scheme`), 'Error')
            } finally {
                setLoading(false)
            }

            return
        }

        try {
            const response = await ApiService.createTaxScheme(data)

            if (response.data.success) {
                toastr.success(props.t(`I added a new tax scheme`), '¡Éxito!')
            }
    
            _clearForm()
        } catch (error) {
            toastr.error(props.t(`I couldn't add this tax scheme`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (label === '') { return false }
        if (code === '') { return false }

        return true
    }

    const _clearForm = () => {
        setLabel('')
        setCode('')
        setValue([])
        setIsDefault({
            value: false,
            label: props.t(`No`)
        })
        setTaxInclusive({
            value: false,
            label: props.t(`No`)
        })

        document.getElementById('label').focus()
    }

    const _addTaxValue = () => {
        try {
            setLoading(true)

            let data = [...value]
            const { code, taxRate, taxRateLabel, type } = tempValue

            data.push({ code, taxRate, taxRateLabel, type: type.value })
            setValue(data)
            setTempValue({
                code: '',
                taxRate: 0,
                taxRateLabel: '',
                type: {
                    label: props.t(`Translation`),
                    value: 'T'
                }
            })

            toastr.success(props.t(`Value added`), 'Éxito')
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const _deleteValue = (i) => {
        const data = [...value]
        data.splice(i, 1)

        setValue(data)
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={5}>
                                        <FormGroup>
                                            <Label>
                                                {props.t(`Label`)} *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={label}
                                                id="label"
                                                required
                                                onChange={event => setLabel(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>
                                                {props.t(`Identification Code`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                id="code"
                                                className="form-control"
                                                value={code}
                                                onChange={event => setCode(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className='control-label'>{props.t(`Default`)}</Label>

                                            <Select 
                                                defaultValue={isDefault}
                                                value={isDefault}
                                                options={options}
                                                onChange={value => setIsDefault(value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className='control-label'>
                                                {props.t(`Tax Inclusive`)} <br />
                                                <small>{props.t(`Tax inclusive means your taxes are already included in the prices`)}</small><br />
                                                <small>{props.t(`Tax not inclusive means Vanta would calculate taxes on top of the prices`)}</small>
                                            </Label>

                                            <Select 
                                                defaultValue={taxInclusive}
                                                value={taxInclusive}
                                                options={options}
                                                onChange={value => setTaxInclusive(value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={7}>
                                        <Table responsive={true}>
                                            <thead>
                                                <tr>
                                                    <th>{props.t(`Code`)}</th>
                                                    <th>{props.t(`Tax Rate`)}</th>
                                                    <th>{props.t(`Tax Rate Label`)}</th>
                                                    <th>{props.t(`Type`)}</th>
                                                    <th>...</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td width={150}>
                                                        <Input
                                                            type="text"
                                                            placeholder={props.t(`VAT`)}
                                                            className="form-control"
                                                            onChange={(event) => {
                                                                setTempValue({
                                                                    ...tempValue,
                                                                    code: event.target.value
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td width={150}>
                                                        <Input
                                                            type="text"
                                                            placeholder={'0.16'}
                                                            className="form-control"
                                                            onChange={(event) => {
                                                                setTempValue({
                                                                    ...tempValue,
                                                                    taxRate: event.target.value
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td width={150}>
                                                        <Input
                                                            type="text"
                                                            placeholder={props.t(`VAT 16%`)}
                                                            className="form-control"
                                                            onChange={(event) => {
                                                                setTempValue({
                                                                    ...tempValue,
                                                                    taxRateLabel: event.target.value
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                    <td width={180}>
                                                        <Select
                                                            defaultValue={tempValue.type}
                                                            value={tempValue.type}
                                                            options={[{
                                                                label: props.t(`Translation`),
                                                                value: 'T'
                                                            }, {
                                                                label: props.t(`Retention`),
                                                                value: 'R'
                                                            }]}
                                                            onChange={(value => setTempValue({
                                                                ...tempValue,
                                                                type: value
                                                            }))}
                                                            />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                                                            onClick={ _addTaxValue }>
                                                            <i className="mdi mdi-plus"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                                {value && value.map((v, i) => (
                                                    <tr key={i}>
                                                        <td>{v.code}</td>
                                                        <td>{v.taxRate}</td>
                                                        <td>{v.taxRateLabel}</td>
                                                        <td>{v.type}</td>
                                                        <td>
                                                            <button onClick={(e) => {
                                                                    e.preventDefault()
                                                                    _deleteValue(i)
                                                                }}
                                                                className="btn btn-small btn-default">
                                                                <i className="mdi mdi-delete"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))