import React, { useState, useEffect } from 'react'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {
    Card,
    Table,
    Row,
    Col
} from 'reactstrap'
import CurrencyFormat from 'react-currency-format'
import Moment from 'moment'
import Modal from '../../../components/Modal'
import MakeOrder from './MakeOrder'

export default ({
    purchaseQuoteId,
    setLoading,
    history,
    t
}) => {
    const [quote, setQuote] = useState()
    const [lineItems, setLineItems] = useState([])
    const [branches, setBranches] = useState([])
    const [supplier, setSupplier] = useState({})

    const [createOrderModalOpen, setCreateOrderModalOpen] = useState(false)

    useEffect(() => {
        (async () => {
            await fetchBranches()
        })()
    }, []) // eslint-disable-line

    useEffect(() => {
        (async () => {
            const response  = await ApiService.getPurchaseQuoteById(purchaseQuoteId)

            if (!response.data.success) {
                toastr.error(t(`I couldn't find this quote`), `Error!`)
                history.push(`/purchase-quotes`)

                return
            }

            setQuote(response.data.quote)
            setLineItems(response.data.quote.lineItems)
            setSupplier(response.data.quote.supplier)
        })()
        // eslint-disable-next-line
    }, [purchaseQuoteId])

    async function fetchBranches() {
        try {
            const response = await ApiService.getBranches()
            let branches = []

            response.data.branches.map((b) => {
                return branches.push({
                    value: b.id,
                    label: b.branchName,
                })
            })

            setBranches(branches)
        } catch (error) { }
    }

    const _cancelQuote = async (id) => {
        try {
            const result = await ApiService.cancelPurchaseQuote(id)

            if (!result.data.success) {
                toastr.error(t(`I couldn't cancel this purchase quote`), 'Error')

                return false
            }

            toastr.success(t(`I cancelled this purchase quote`), t(`Success!`))
            window.analytics.track('Purchase quote canceled', {
                quoteId: id
            })

            history.push(`/purchase-quote`)
        } catch (error) {
            console.log(error)

            toastr.error(t(`I couldn't cancel this purchase quote`), 'Error')
        }
    }

    const __renderMakeOrderModal = () => (
        <Modal
            className="xxl"
            title={t(`Create Purchase Order from Quote`)}
            isOpen={createOrderModalOpen}
            onRequestClose={() => {
                setCreateOrderModalOpen(false)
            }}
            body={<MakeOrder t={t} setCreateOrderModalOpen={setCreateOrderModalOpen} history={history} setLoading={setLoading} quote={quote} />} />
    )

    return !quote ? (<React.Fragment></React.Fragment>) : (
        <React.Fragment>
            <Card>
                <Row>
                    <Col sm={10}>
                        <nav className='purchase-orders-view-nav'>
                            {/* <a href={"/"}><i className='fa fa-copy'></i> {t(`Copy quote`)}</a> */}
                            {quote.status.name === 'Borrador' && (
                                <a className="btn btn-link p-0 m-8 d-inline align-baseline" href='/' onClick={(e) => {
                                    e.preventDefault()
                                    history.push(`/purchase-quote/edit/${quote.id}`)
                                }}>
                                    <i className='mdi mdi-pencil'></i> {t(`Edit`)}
                                </a>
                            )}

                            <a className="btn btn-link p-0 m-8 d-inline align-baseline" href={`/purchase-quote/print/${quote.id}`} rel="noopener noreferrer" target="_blank">
                                <i className='mdi mdi-printer'></i> {t(`Print`)}
                            </a>
                            {/* <a href={"/"}><i className='mdi mdi-email'></i> {t(`Email`)}</a> */}
                            {quote.status.codeName === 'ACTIVO' && (
                                // eslint-disable-next-line 
                                <>
                                    <a className="btn btn-link p-0 m-8 d-inline align-baseline" href={"/"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            // open modal
                                            setCreateOrderModalOpen(true)
                                        }}>
                                        <i className='fa fa-receipt'></i> {t(`Convert into order`)}
                                    </a>

                                    <a className="btn btn-link p-0 m-8 d-inline align-baseline" href={"/"}
                                        onClick={(e) => { 
                                            e.preventDefault()
                                            _cancelQuote(quote.id)
                                    }}>
                                        <i className='mdi mdi-cancel'></i> {t(`Cancel quote`)}
                                    </a>
                                </>
                            )}
                        </nav>
                    </Col>

                    <Col sm={2}><strong>#PQ-{quote.folio}</strong> ({t(quote.status.name)})</Col>
                </Row>
            </Card>

            <Card>
                <Row>
                    <Col sm={6}>
                        <strong>{t(`Vendor details`)}</strong><br /><br />

                        <strong>{t(`Legal Business Name`)}:</strong> {supplier.legalBusinessName} <br />
                        <strong>{t(`Tax Id`)}:</strong> {supplier.taxId}<br />
                        <strong>{t(`Phone`)}:</strong> {supplier.phone}<br />
                        <strong>{t(`WhatsApp`)}:</strong> {supplier.whatsapp}<br />
                        <strong>{t(`E-Mail`)}:</strong> {supplier.email}<br />
                    </Col>

                    <Col sm={6}>
                        <strong>{t(`Shipment Details`)}</strong><br /><br />

                        <strong>{quote.company.legalBusinessName}</strong><br />
                        <strong>{t(`Tax Id`)}</strong> {quote.company.taxId} <br />
                        <strong>{t(`Location`)}:</strong> {quote.branch.branchName}<br />
                        <strong>{t(`Address`)}:</strong><br /> {quote.branch.streetName} {quote.branch.exteriorNumber} Int. {quote.branch.interiorNumber}, {quote.branch.neighborhood}, {quote.branch.city}, {quote.branch.state}, {quote.branch.country}, {t(`Zip Code`)} {quote.branch.zipCode}<br />
                        <strong>{t(`Expiration date`)}:</strong> {Moment(quote.expirationDate).format("DD/MM/YYYY")}<br />
                        {quote.requireShipping && (
                            <React.Fragment>
                                <strong>{t(`Ship before`)}:</strong> {Moment(quote.requiredShippingDate).format("DD/MM/YYYY")}<br />
                                <strong>{t(`Shipping Costs`)}:</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={quote.shippingCosts} />
                            </React.Fragment>
                        )}
                    </Col>
                </Row>

                <br /><br />

                <section className="items">
                    <Table responsive="true">
                        <thead>
                            <tr>
                                <th>{t(`Product and SKU`)}</th>
                                <th>{t(`QTY`)}</th>
                                <th>{t(`Location`)}</th>
                                <th>{t(`Unit cost`)}</th>
                                <th>{t(`Discount`)}</th>
                                <th>{t(`Subtotal`)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems.map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        <strong>{item.product.name}</strong> <em>{item.product.sku}</em><br />
                                    </td>
                                    <td width={100}>
                                        <span>{item.quantity}</span>
                                    </td>
                                    <td width={150}>
                                        <span>{branches.length > 0 && branches.find(b => b.value === item.branchId.value).label}</span>
                                    </td>
                                    <td width={150}>
                                        <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitCost} />
                                    </td>
                                    <td width={130}>
                                        <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.discount} />
                                    </td>
                                    <td>
                                        <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={(item.unitCost * item.quantity) - ((item.unitCost * item.quantity) * (item.discount / 100))} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        {lineItems.reduce((a, b) => a + +b.quantity, 0) > 0 && (
                            <tfoot>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={5}>{t(`Subtotal`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal, 0)} /></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={5}>{t(`Discount`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.subtotal * (+b.discount / 100), 0)} /></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={5}>{t(`Tax rate`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.taxAmount, 0)} /></td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={5}>{t(`Total`)}:</td>
                                    <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={lineItems.reduce((a, b) => a + +b.total, 0)} /></td>
                                </tr>
                            </tfoot>
                        )}
                    </Table>
                </section>
            </Card>

            {createOrderModalOpen && __renderMakeOrderModal()}
        </React.Fragment>
    )
}