import React, { useState, useEffect, useContext } from 'react'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardTitle
} from "reactstrap"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import Select from 'react-select'
import activeCountries from '../../../data/activeCountries'
import { updateAvatar, updateProfile } from '../../../store/actions'

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [roleId, setRoleId] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [countryCode, setCountryCode] = useState({
        label: '(+52) México',
        value: '+52',
        active: true
    })
    const [phone, setPhone] = useState()
    const [password, setPassword] = useState()
    const [password2, setPassword2] = useState()

    // catalogs
    const [roles, setRoles] = useState([])

    useEffect(() => {
        (async () => {
            await getRoles()
        })()
    }, []) // eslint-disable-line

    const getRoles = async () => {
        setLoading(true)
        const result = await ApiService.getRoles()
        let roles = []

        result.data.data.map((role) => {
            const item = {
                value: role.id,
                label: role.name,
                codeName: role.codeName
            }

            if (item.label === 'Vanta Super User') {
                // eslint-disable-next-line
                return
            }

            return roles.push(item)
        })

        setLoading(false)
        setRoles(roles)
    }

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setCountryCode(activeCountries.find(i => i.value === data.countryCode))
            setRoleId(roles.find(i => i.value === data.roleId))

            setId(data.id)
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setEmail(data.email)
            setPhone(data.phone)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const _handleSubmit = async (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        try {
            setLoading(true)
            let data = {
                roleId: roleId.value,
                firstName,
                lastName,
                email,
                countryCode: countryCode.value,
                phone,
                password
            }

            if (props.data !== undefined) { 
                await _updateUser(id, data)

                return
            }

            await _createUser(data)

            props.history.push('/users')
        } catch (error) {
            toastr.error(props.t(`I couldn't add this user`))
        } finally {
            setLoading(false)
        }
    }

    const _updateUser = async (id, data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateUser({
                ...data,
                id
            })

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            props.updateProfile(data)
            window.analytics.track('User updated')
            toastr.success(props.t(`User Edited`), '¡Éxito!')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _createUser = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createUser(data)

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            window.analytics.track('User Added')
            toastr.success(props.t(`User Added`), '¡Éxito!')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (firstName === '') { return false }
        if (email === '') { return false }
        if (password === '') { return false }
        if (phone === '') { return false }

        return true
    }

    return (
        <React.Fragment>
            <Form id="lugares" method="POST" onSubmit={ _handleSubmit }>
                <Row>
                    <Col sm={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Col sm={12} md={4}>
                            <Card>
                                <CardBody>
                                    <CardTitle>{props.t(`User's details`)}</CardTitle>

                                    <p>
                                        {props.t(`Fill in the basic details for your team member`)}.
                                    </p>

                                    <p>{props.t(`We'll send a confirmation email`)}.</p>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col sm={12} md={8}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="firstName">
                                                    {props.t(`First Name`)}
                                                </Label>

                                                <Input
                                                    id="firstName"
                                                    name="firstName"
                                                    type="text"
                                                    className="form-control"
                                                    value={firstName}
                                                    onChange={event => setFirstName(event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="lastName">
                                                    {props.t(`Last Name`)}
                                                </Label>

                                                <Input
                                                    id="lastName"
                                                    name="lastName"
                                                    type="text"
                                                    className="form-control"
                                                    value={lastName}
                                                    onChange={event => setLastName(event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label htmlFor="email">
                                                {props.t(`Email`)}
                                                </Label>

                                                <Input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={event => setEmail(event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Country code`)}
                                                </Label>

                                                <Select
                                                    defaultValue={countryCode}
                                                    options={activeCountries}
                                                    placeholder={props.t(`Country code`)}
                                                    onChange={value => setCountryCode(value)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label>
                                                    {props.t(`Phone number`)}
                                                </Label>

                                                <Input
                                                    type="tel"
                                                    className="form-control"
                                                    value={phone}
                                                    onChange={event => setPhone(event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label htmlFor="roleId">
                                                    {props.t(`Role`)}
                                                </Label>

                                                <Select 
                                                    id={`roleId`}
                                                    value={roleId}
                                                    placeholder={props.t(`Select role`)}
                                                    onChange={ value => setRoleId(value) }
                                                    options={roles} 
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label htmlFor="password">
                                                    {props.t(`Password`)}
                                                </Label>

                                                <Input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    className="form-control"
                                                    value={password}
                                                    onChange={event => setPassword(event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={4}>
                                            <FormGroup>
                                                <Label htmlFor="password">
                                                    {props.t(`Confirm Password`)}
                                                </Label>

                                                <Input
                                                    id="password2"
                                                    name="password2"
                                                    type="password"
                                                    className="form-control"
                                                    value={password2}
                                                    onChange={event => {
                                                        setPassword2(event.target.value)
                                                    }}
                                                    onBlur={e => {
                                                        if (password !== password2) {
                                                            toastr.error(props.t(`Passwords does not match`))
                                                            document.getElementById('password').style.border = '1px solid red'
                                                            document.getElementById('password2').style.border = '1px solid red'

                                                            document.getElementById('password2').focus()
                                                        } else {
                                                            document.getElementById('password').style.border = '1px solid #ced4da'
                                                            document.getElementById('password2').style.border = '1px solid #ced4da'
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { user } = state.Auth.profile

    return { user }
}

export default connect(
    mapStatetoProps, { updateAvatar, updateProfile }
)(withRouter(withNamespaces()(FormPage)))