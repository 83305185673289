import React, { useState, useEffect, useContext } from 'react'
import {
    Container,
} from 'reactstrap'

import EditForm from './components/EditForm'
import { GeneralContext } from '../../context/'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import ApiService from '../../data/services/ApiService'

const Edit = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext

    const [data, setData] = useState([])

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Bank`),
            parentLink: '/bank',
            item: props.t(`Edit Bank Account`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            const id = props.match.params.id
            setLoading(true)
            const response = await ApiService.getBankAccount(id)
            setLoading(false)
            setData(response.data.data)
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <EditForm data={data} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Edit))
