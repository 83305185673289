import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import toastr from 'toastr'
import { loadState } from "../store/localStorage"

axios.interceptors.response.use(undefined, (error) => {
    const { response, config } = error
    const newResponse = {}
    const newConfig = {
        url: config.url,
        method: config.method,
        headers: config.headers
    }

    if (config.url != null) { newConfig.data = config.data }

    if (response != null) {
        if (response.status === 404) {
            return Promise.reject(error)
        }

        if (response.status === 403) {
            toastr.error(`action unauthorized`)
            window.location.replace('/logout')
        }

        if (response.status === 401) {
            toastr.error(`Tu sesión ha expirado`, 'Error')
            window.location.replace('/logout')
        }

        newResponse.status = response.status
        newResponse.statusText = response.statusText
        newResponse.headers = response.headers

        if (response.data != null && response.data.message) {
            newResponse.dataMessage = response.data.message
        }
    }

    return Promise.reject(error)
})

export let baseURL = ''
const hostname = window && window.location && window.location.hostname

switch (hostname) {
    case 'localhost':
        baseURL = 'http://localhost:3000'
        break
    case 'juvasoft.local':
        baseURL = 'http://juvasoft.local:3000'
        break
    default:
        baseURL = 'https://bulk.vantaapp.mx'
        break
}

const useActiveToken = () => {
    return loadState()?.Auth?.token
}

const useActiveCompany = () => {
    return loadState()?.Auth?.activeCompany
}

export class ApiHelper {
    getAuthToken() {
        return useActiveToken()
    }

    getActiveCompany() {
        return useActiveCompany()
    }

    getBaseURL() { return baseURL }

    getOutgoingHeaders() {
        const company = this.getActiveCompany()

        return {
            'authorization': this.getAuthToken(),
            'request-id': uuidv4(),
            'Request-Source': 'momentum_ui',
            'company-id': company?.id
        }
    }

    async post(url, data) {
        try {
            return axios.post(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async postWithFiles(url, data) {
        let headers = this.getOutgoingHeaders()
        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data'
        }

        try {
            return axios.post(url, data, {
                baseURL,
                headers
            })
        } catch (error) {
            throw error
        }
    }

    async put(url, data) {
        try {
            return axios.put(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async get(url) {
        try {
            return axios.get(url, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async delete(url) {
        try {
            return axios.delete(url, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }
}

export default new ApiHelper()