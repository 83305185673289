import React, { useEffect, useState, useContext } from 'react'

import {
    Form,
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    Button,
    Card,
    CardTitle
} from 'reactstrap'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { GeneralContext } from '../../context/'
import ApiService from '../../data/services/ApiService'
import { useRouter } from '../../helpers/useRouter'
import { withNamespaces } from "react-i18next"
import Select from 'react-select'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'


import { loginSuccess, apiError, setActiveCompany, setToken } from "../../store/actions"
import activeCountries from '../../data/activeCountries'

const Signup = (props) => {
    const generalContext = useContext(GeneralContext)
    const { loading, setLoading } = generalContext
    const router = useRouter()
    const utmMedium = router.query.utm_medium || 'direct'

    const [planes, setPlanes] = useState([])
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [taxId, setTaxId] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [countryCode, setCountryCode] = useState({
        label: '(+52) México',
        value: '+52',
        active: true
    })
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [plan, setPlan] = useState()
    const [howFoundOut, setHowFoundOut] = useState('')
    const [companySize, setCompanySize] = useState('')
    const [industry, setIndustry] = useState('')
    const [reference, setReference] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [currentStep, setCurrentStep] = useState(1)

    useEffect(() => {
        (async () => {
            window.analytics.track('User signup page viewed')
            await _fetchPlanes()
        })()
    }, []) // eslint-disable-line

    const _fetchPlanes = async () => {
        try {
            setLoading(true)
            let data = []
            const response = await ApiService.getActivePlanes()

            // eslint-disable-next-line
            await response.data?.planes?.map(p => {
                data.push({
                    label: `${p.name} ($${p.monthlyCost} / mes)`,
                    value: p.id
                })
            })

            setPlanes(data)
            setPlan(data[0])
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const _handleSubmit = async (e) => {
        try {
            setLoading(true)
            const response = await ApiService.signup({
                firstName, 
                lastName, 
                taxId, 
                businessName, 
                email, 
                countryCode: countryCode.value,
                phone,
                password,
                howFoundOut: howFoundOut.value,
                industry: industry.value,
                companySize: companySize.value,
                reference: reference,
                planId: plan.value,
                utmMedium
            })

            if (!response.data.success) { 
                toastr.error(`${response.data.message}`, 'Error')
                return
            }

            window.analytics.identify(response.data.userId, { email })
            window.analytics.track("User signed up", { userId: response.data.userId })
            // window.gtag('event', 'conversion', { 'send_to': '' })

            const user = {
                ...response.data.user,
                companies: [response.data.company]
            }

            props.setToken(response.data.token)
            props.loginSuccess(user)
            props.setActiveCompany(response.data.company)
            props.history.push('/')
        } catch(e) {
            if (e.response) {
                const { code, message } = e.response?.data

                if (message.includes('is not a mobile number')) {
                    toastr.error(`Asegúrate de usar un número de Whatsapp válido`, '¡Hey!')
                }

                if (code === 'IS_TAKEN') {
                    toastr.error(props.t(`You have an account, want to try resetting your password?`))
                }

                if (code === 'IS_BURNER_EMAIL') {
                    toastr.error(props.t(`Wanna try a valid email?`))
                }
            } else {
                if (e.includes(`Network error`)) {
                    toastr.error(props.t(`I am having trouble reaching the network`), props.t(`Network error`))
                }
            }
        } finally {
            setLoading(false)
        }
    }

    const _validateStep1 = () => {
        if (email === '') { return false }

        return true
    }

    const _validateStep2 = () => {
        if (firstName === '') { return false }
        if (lastName === '') { return false }

        return true
    }

    const _validateStep3 = () => {
        if (businessName === '') { return false }
        if (taxId === '') { return false }

        return true
    }

    const _validateStep4 = () => {
        if (password === '') { return false }
        if (password2 === '') { return false }
        if (password !== password2) { 
            toastr.error('Make sure to write down the same password on both fields', props.t(`Password mismatch`))

            return false 
        }

        return true
    }

    const __renderSignupForm = () => {
        return (
            <div className="signup-form">
                <h1>{props.t(`Start your 14 day trial`)}</h1>

                <h2>{props.t(`The future of inventory management`)}</h2>

                {currentStep === 1 && (
                    <Card className='text-align-left'>
                        <CardTitle>Comencemos por tu correo electrónico</CardTitle>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`E-Mail`)}*  <small>(Lo usarás para iniciar sesión)</small>
                                    </Label>

                                    <Input
                                        required
                                        type="email"
                                        className="form-control"
                                        value={email}
                                        onChange={event => setEmail(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className='form-actions'>
                            <Col sm={12}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!_validateStep1()) {
                                            toastr.error(props.t(`Don't forget to enter a valid email`))
                                            return
                                        }

                                        setCurrentStep(2)
                                    }}
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Next`)}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}

                {currentStep === 2 && (
                    <Card className='text-align-left'>
                        <CardTitle>Ahora cuéntanos tu historia</CardTitle>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`First name`)}*
                                    </Label>

                                    <Input
                                        required
                                        value={firstName}
                                        type="text"
                                        className="form-control"
                                        placeholder='Sansa'
                                        onChange={event => setFirstName(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Last name`)}*
                                    </Label>

                                    <Input
                                        required
                                        value={lastName}
                                        type="text"
                                        className="form-control"
                                        placeholder='Stark'
                                        onChange={event => setLastName(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Country code`)}
                                    </Label>

                                    <Select
                                        defaultValue={countryCode}
                                        options={activeCountries}
                                        placeholder={props.t(`Country code`)}
                                        onChange={value => setCountryCode(value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Phone number`)} <small>(Puedes iniciar sesión con él)</small>
                                    </Label>

                                    <Input
                                        type="tel"
                                        className="form-control"
                                        value={phone}
                                        onChange={event => setPhone(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={12}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Plan`)} <small>(Puedes cambiarlo después)</small>
                                    </Label>

                                    <Select
                                        value={plan}
                                        defaultValue={plan}
                                        onChange={value => setPlan(value)}
                                        options={planes}
                                        />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className='form-actions'>
                            <Col sm={12}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!_validateStep2()) {
                                            toastr.error(props.t(`Don't forget to enter your name`))
                                            return
                                        }

                                        setCurrentStep(3)
                                    }}
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Next`)}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}

                {currentStep === 3 && (
                    <Card className='text-align-left'>
                        <CardTitle>Ahora la historia de tu empresa</CardTitle>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Legal Business Name`)}*
                                    </Label>

                                    <Input
                                        required
                                        value={businessName}
                                        type="text"
                                        className="form-control"
                                        placeholder='Stark Industries, Inc'
                                        onChange={event => setBusinessName(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Tax Id`)}*
                                    </Label>

                                    <Input
                                        required
                                        value={taxId}
                                        type="text"
                                        className="form-control"
                                        placeholder='STI11102662A'
                                        onChange={event => setTaxId(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>{props.t(`How did you find us?`)}</Label>

                                    <Select
                                        value={howFoundOut}
                                        defaultValue={howFoundOut}
                                        onChange={value => setHowFoundOut(value)}
                                        options={[{
                                            label: 'Búsqueda en internet',
                                            value: 'Búsqueda en internet'
                                        }, {
                                            label: 'Anuncio / Publicidad',
                                            value: 'Anuncio / Publicidad'
                                        }, {
                                            label: 'Un amigo / conocido',
                                            value: 'Un amigo / conocido'
                                        }, {
                                            label: 'Redes sociales',
                                            value: 'Redes sociales'
                                        }, {
                                            label: 'Otro',
                                            value: 'Otro'
                                        }]}
                                        />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>{props.t(`Company size`)}</Label>

                                    <Select
                                        value={companySize}
                                        defaultValue={companySize}
                                        onChange={value => setCompanySize(value)}
                                        options={[{
                                            label: '1-5 Empleados',
                                            value: '1-5 Empleados'
                                        }, {
                                            label: '6-20 Empleados',
                                            value: '6-20 Empleados'
                                        }, {
                                            label: '21-100 Empleados',
                                            value: '21-100 Empleados'
                                        }, {
                                            label: '101+ Empleados',
                                            value: '101+ Empleados'
                                        }]}
                                        />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>{props.t(`Industry`)}</Label>

                                    <Select
                                        value={industry}
                                        defaultValue={industry}
                                        onChange={value => setIndustry(value)}
                                        options={[{
                                            label: 'Animales y Mascotas',
                                            value: 'Animales y Mascotas'
                                        }, {
                                            label: 'Artes y Manualidades',
                                            value: 'Artes y Manualidades'
                                        }, {
                                            label: 'Audio, Impresos y Medios',
                                            value: 'Audio, Impresos y Medios'
                                        }, {
                                            label: 'Autopartes y Refaccionarias',
                                            value: 'Autopartes y Refaccionarias'
                                        }, {
                                            label: 'Cervezas, vinos y licores',
                                            value: 'Cervezas, vinos y licores'
                                        }, {
                                            label: 'Construcción',
                                            value: 'Construcción'
                                        }, {
                                            label: 'Comidas/Bebidas',
                                            value: 'Comidas/Bebidas'
                                        }, {
                                            label: 'Comesticos, Productos de Belleza',
                                            value: 'Comesticos, Productos de Belleza'
                                        }, {
                                            label: 'Electrónicos y Computadoras',
                                            value: 'Electrónicos y Computadoras'
                                        }, {
                                            label: 'Fashion, Boutiques, Accesorios y Joyerías',
                                            value: 'Fashion, Boutiques, Accesorios y Joyerías'
                                        }, {
                                            label: 'Farmacéuticos',
                                            value: 'Farmacéuticos'
                                        }, {
                                            label: 'Fitness/deportes',
                                            value: 'Fitness/deportes'
                                        }, {
                                            label: 'Jugueterías',
                                            value: 'Jugueterías'
                                        }, {
                                            label: 'Línea blanca, diseño de interiores y mueblerías',
                                            value: 'Línea blanca, diseño de interiores y mueblerías'
                                        }, {
                                            label: 'Manufactura y Maquinaria',
                                            value: 'Manufactura y Maquinaria'
                                        }, {
                                            label: 'Otros',
                                            value: 'Otros'
                                        }, {
                                            label: 'Productos Diversos',
                                            value: 'Productos Diversos'
                                        }, {
                                            label: 'Servicios Profesionales',
                                            value: 'Servicios Profesionales'
                                        }, {
                                            label: 'Productos de Jardinería',
                                            value: 'Productos de Jardinería'
                                        }]}
                                        />
                                </FormGroup>
                            </Col>

                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <Label>Código de Promoción</Label>

                                    <Input
                                        value={reference}
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t(`Win credits by inviting your colleagues`)}
                                        onChange={event => setReference(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className='form-actions'>
                            <Col sm={12}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (!_validateStep3()) {
                                            toastr.error(props.t(`Don't forget to enter your company's name and tax id`))
                                            return
                                        }

                                        setCurrentStep(4)
                                    }}
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Next`)}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}

                {currentStep === 4 && (
                    <Card className='text-align-left'>
                        <Row>
                            <CardTitle>Por último, genera tu contraseña</CardTitle>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Password`)}*
                                    </Label>

                                    <Input
                                        type="password"
                                        className="form-control"
                                        value={password}
                                        required
                                        onChange={event => setPassword(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label>
                                        {props.t(`Confirm password`)}*
                                    </Label>

                                    <Input
                                        type="password"
                                        className="form-control"
                                        value={password2}
                                        required
                                        onChange={event => setPassword2(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className='form-actions'>
                            <Col sm={12}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={e => {
                                        e.preventDefault()
                                        if (!_validateStep4()) {
                                            toastr.error(props.t(`Don't forget to enter your password`))

                                            return
                                        }

                                        _handleSubmit()
                                    }}
                                    className="btn btn-primary btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Ir a mi cuenta`)}
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                )}
            </div>
        )
    }

    return(
        <React.Fragment>
            <div className="login signup">
                <div className="wrapper grid-layout">
                    <Form id="signup" type="POST" onSubmit={ _handleSubmit }>
                        <div className="logo">
                            <img alt="Vanta" src="./assets/images/logo.svg" />
                        </div>

                        {__renderSignupForm()}
                    </Form>

                    <Row>
                        <Col sm={12} className="login-copyright">
                            <p>Copyright © {new Date().getFullYear()}. Todos los Derechos Reservados.</p>
                            <p>Hecho con amor por <a target="_blank" rel="noopener noreferrer" href="https://juvasoft.com/?utm_source=vantaapp&utm_medium=login">Juvasoft</a></p>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { error } = state.Auth

    return { error }
}

export default withRouter(
    connect(
        mapStatetoProps, 
        { loginSuccess, apiError, setActiveCompany, setToken }
    )(withNamespaces()(Signup)
))