import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import ApiService from '../../data/services/ApiService'
import Moment from 'moment'
import CurrencyFormat from 'react-currency-format'
import Barcode from 'react-barcode'
import { Row, Col, Button } from 'reactstrap'

const Print = (props) => {
    const [quote, setquote] = useState()
    const [lineItems, setLineItems] = useState([])
    const [supplier, setSupplier] = useState({})
    const [branches, setBranches] = useState([])

    useEffect(() => {
        (async () => {
            const id = props.match.params.id
            await fetchquoteById(id)
            await fetchBranches()
        })()
    }, []) // eslint-disable-line

    async function fetchBranches() {
        try {
            const response = await ApiService.getBranches()
            let branches = []

            response.data.branches.map((b) => {
                return branches.push({
                    value: b.id,
                    label: b.branchName,
                })
            })

            setBranches(branches)
        } catch (error) { }
    }

    const fetchquoteById = async (id) => {
        try {
            const response = await ApiService.getPurchaseQuoteForPrintById(id)

            setquote(response.data.quote)
            setLineItems(response.data.quote.lineItems)
            setSupplier(response.data.quote.supplier)
        } catch (error) {
            // 
        }
    }

    return (
        <React.Fragment>
            {quote && (
                <React.Fragment>
                    <div className="order-action-bar">
                        <Row>
                            <Col sm={6}>
                                <h1>PQ-{quote.folio}</h1>
                            </Col>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                                <Button color='default'
                                    onClick={() => { window.close() }}>
                                    <i className='mdi mdi-close'></i> {props.t(`Close`)}
                                </Button>
                                <Button color='primary'
                                    onClick={() => { window.print() }}>
                                    <i className='mdi mdi-printer'></i> {props.t(`Print`)}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="order page">
                        <Row className='order-header'>
                            <Col sm={9}>
                                {quote.company.logo && (
                                    <img width={220} alt={quote.company.businessName} src={quote.company.logo} />
                                )}
                            </Col>

                            <Col sm={3} style={{ textAlign: 'right' }}>
                                <h1>{props.t(`Purchase quote`)}</h1>
                                
                                <div className="barcode-wrapper">
                                    <Barcode width={3} height={24} lineColor={'#495057'} displayValue={false} value={quote.folio} />
                                </div>

                                <Row>
                                    <Col sm={6}><strong>{props.t(`quote`)}</strong></Col>
                                    <Col sm={6}>PQ-{quote.folio}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}><strong>{props.t(`Date`)}</strong></Col>
                                    <Col sm={6}>{Moment(quote.createdAt).format("DD/MM/YYYY")}</Col>
                                </Row>
                                {quote.status.name === 'Cancelada' && (
                                    <Row>
                                        <Col sm={12}><strong style={{color: 'red', lineHeight: '3'}}>CANCELADA</strong></Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <strong>{props.t(`Vendor details`)}</strong><br /><br />

                                <strong>{props.t(`Legal Business Name`)}:</strong> {supplier.legalBusinessName} <br />
                                <strong>{props.t(`Tax Id`)}:</strong> {supplier.taxId}<br />
                                <strong>{props.t(`Phone`)}:</strong> {supplier.phone}<br />
                                <strong>{props.t(`WhatsApp`)}:</strong> {supplier.whatsapp}<br />
                                <strong>{props.t(`E-Mail`)}:</strong> {supplier.email}<br />
                            </Col>

                            <Col sm={6}>
                                <strong>{props.t(`Shipment Details`)}</strong><br /><br />

                                <strong>{quote.company.legalBusinessName}</strong><br />
                                <strong>{props.t(`Tax Id`)}</strong> {quote.company.taxId} <br />
                                <strong>{props.t(`Location`)}:</strong> {quote.branch.branchName}<br />
                                <strong>{props.t(`Address`)}:</strong><br />{quote.branch.streetName} {quote.branch.exteriorNumber} Int. {quote.branch.interiorNumber}, {quote.branch.neighborhood}, {quote.branch.city}, {quote.branch.state}, {quote.branch.country}, {props.t(`Zip Code`)} {quote.branch.zipCode}<br />
                                <strong>{props.t(`Expiration date`)}:</strong> {Moment(quote.expirationDate).format("DD/MM/YYYY")}<br />
                                {quote.requireShipping && (
                                    <React.Fragment>
                                        <strong>{props.t(`Ship before`)}:</strong> {Moment(quote.requiredShippingDate).format("DD/MM/YYYY")}<br />
                                        <strong>{props.t(`Shipping Costs`)}:</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={quote.shippingCosts} />
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>

                        <section className="items">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{props.t(`Name & SKU`)}</th>
                                        <th>{props.t(`Location`)}</th>
                                        <th>{props.t(`QTY`)}</th>
                                        <th>{props.t(`Unit cost`)}</th>
                                        <th>{props.t(`Discount`)}</th>
                                        <th>{props.t(`Subtotal`)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lineItems.map((item, i) => (
                                        <tr key={i}>
                                            <td>
                                                <strong>{item.product.name}</strong><br />
                                                <small>{props.t(`SKU`)} {item.product.sku}</small>
                                            </td>
                                            <td>{branches.length > 0 && branches.find(b => b.value === item.branchId.value).label}</td>
                                            <td>{item.quantity}</td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitCost} /></td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.discount} /></td>
                                            <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitCost * item.quantity} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={4}>
                                            <strong>{props.t(`Remarks`)}</strong><br />

                                            {quote.message}
                                        </td>
                                        <td style={{textAlign: 'right'}}><strong>{props.t(`Subotal`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={quote.subtotal} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={5}><strong>{props.t(`Discount`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={quote.discount} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={5}><strong>{props.t(`Tax`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={quote.taxAmount} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'right'}} colSpan={5}><strong>{props.t(`Total`)}</strong></td>
                                        <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={quote.total} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </section>
                    </div>
                    <div className="order-footer-bar">
                        {props.t(`This quote was sent through Vanta`)}<br />
                        {props.t(`Not using Vanta?`)} <a href='//vantaapp.mx'>{props.t(`Learn more`)}</a>

                        <br /><br />
                        <p>{props.t(`Made with`)} <span role="img" aria-label='heart'>♥️</span> {props.t(`by`)} juvasoft</p>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Print))