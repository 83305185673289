import React, { useContext, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Input,
    FormGroup
} from "reactstrap"
import Modal from '../../../components/Modal'
import { isEmpty, head, filter } from 'lodash'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../../context/'
import ProductHelper from '../../../helpers/ProductHelper'
import DefaultImage from '../../../assets/images/avatar-product-placeholder.png'
import CurrencyFormat from 'react-currency-format'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const Cart = (props) => {
    const generalContext = useContext(GeneralContext)
    const [productInUse, setProductInUse] = useState()
    const [seriesModalOpen, setSeriesModalOpen] = useState(false)
    const [tempSeries, setTempSeries] = useState('')
    const { cartItems, setCartItems, addItemToCart, removeItemFromCart, activePriceList } = generalContext

    const _renderProductAvatar = (product, size) => {
        if (!size) { size = 128 }

        if (isEmpty(product.gallery)) {
            return( <img alt={``} style={{maxWidth: `${size}px`}} src={DefaultImage} /> )
        }

        const image = filter(product.gallery, { featured: true })
        if (isEmpty(head(image))) {
            return (<img alt={``} style={{maxWidth: `${size}px`}} src={head(product.gallery).fileName || DefaultImage} />)
        }

        return (
            <div style={{textAlign: 'center'}}>
                <img style={{maxWidth: `${size}px`}}  alt={head(image).id} src={`${head(image).fileName}`} />
            </div>
        )
    }

    const __renderSeriesModal = () => (
        <Modal
            title={props.t(`Scan barcode or write series`)}
            isOpen={seriesModalOpen}
            onRequestClose={() => {
                setSeriesModalOpen(false)
            }}
            body={
                <React.Fragment>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Input
                                    autoFocus={true}
                                    id='series'
                                    type="text"
                                    value={tempSeries}
                                    style={{ height: 100 }}
                                    placeholder={props.t(`scan barcode or write series`)}
                                    onChange={e => setTempSeries(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </React.Fragment>
            } 
            footer={
                <Row className='form-actions'>
                    <Col sm={12}>
                        <Button color='primary' onClick={(e) => {
                            e.preventDefault()
                            if (tempSeries === '') {
                                toastr.warning(props.t(`Please input the product's series or batch`))
                                return
                            }

                            __addItemToCart(productInUse, tempSeries)
                            setProductInUse(null)
                            setSeriesModalOpen(false)
                            setTempSeries('')
                        }}>
                            {props.t(`Continue`)}
                        </Button>
                    </Col>
                </Row>
            }/>
    )

    const __addItemToCart = (product, serie) => {
        let quantity = 1
        let discount = 0.00
        let scheme = product.product.taxScheme
        let unitPrice = ProductHelper.getVariantPriceByPriceId(product, activePriceList.id) 
        let { subtotal, taxAmount, total } = ProductHelper.calculateTotals({
            unitPrice,
            quantity,
            discountRate: discount,
            scheme
        })

        addItemToCart({
            product,
            quantity,
            discount,
            taxAmount: taxAmount,
            itemsFulfilled: 0,
            unitCost: product.unitCost,
            unitPrice,
            subtotal,
            serie,
            total,
            scheme
        })
    }

    return (
        <React.Fragment>
            {cartItems.map((item, i) => {
                return (
                    <Row className='cart-item' key={i}>
                        <Col sm={2}>
                            {_renderProductAvatar(item.product, 48)}
                        </Col>

                        <Col sm={6}>
                            <span className='cart-item-name'>{item.product.name}</span> <br />
                            <span className='cart-item-price'>
                                <CurrencyFormat
                                    displayType="text"
                                    thousandSeparator
                                    decimalScale={2}
                                    prefix="$"
                                    value={(() => {
                                        let unitPrice = ProductHelper.getVariantPriceByPriceId(item.product, activePriceList.id)
                                        let { taxInclusive, value } = item.product.product.taxScheme

                                        if (taxInclusive) {
                                            let taxAmount = 0
                                            for (let tax of value) {
                                                taxAmount += (+unitPrice / (+tax.taxRate + 1)) * tax.taxRate
                                            }

                                            return +unitPrice - +taxAmount
                                        }

                                        return unitPrice
                                    })()} />
                            </span>
                        </Col>

                        <Col sm={4}>
                            <Row>
                                <Col sm={12} className='cart-item-quantity'>
                                    <Input 
                                        type='number'
                                        value={item.quantity}
                                        onChange={(e) => {
                                            const { value } = e.target

                                            if (value === 0) {
                                                removeItemFromCart(item)
                                            }

                                            if (item.product.product.productType === 'serialized') {
                                                setProductInUse(item.product)
                                                setSeriesModalOpen(true)

                                                return
                                            }

                                            let scheme = item.product.product.taxScheme
                                            let unitPrice = item.unitPrice
                                            let { subtotal, discount, taxAmount, total } = ProductHelper.calculateTotals({
                                                unitPrice,
                                                quantity: value,
                                                discountRate: 0,
                                                scheme
                                            })

                                            const items = [...cartItems]
                                            items[i] = {
                                                ...items[i],
                                                quantity: value,
                                                subtotal,
                                                discount,
                                                taxAmount,
                                                total
                                            }

                                            setCartItems(items)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })}
            {seriesModalOpen && __renderSeriesModal()}
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Cart))