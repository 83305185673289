import React, { Component } from "react"
import CircularProgress from "material-ui/CircularProgress"
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider"

export function numberFormat(number, decimals, decPoint, thousandsSep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "")

    let n = !isFinite(+number) ? 0 : +number
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    let sep = typeof thousandsSep === undefined ? "," : thousandsSep
    let dec = typeof decPoint === undefined ? "." : decPoint
    let s = ""

    let toFixedFix = (n, prec) => {
        let k = Math.pow(10, prec)

        return "" + (Math.round(n * k) / k).toFixed(prec)
    }

    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".")
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }

    if ((s[1] || "").length < prec) {
        s[1] = s[1] || ""
        s[1] += new Array(prec - s[1].length + 1).join("0")
    }

    return s.join(dec)
}

export function validateEmail(element) {
    var emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (emailRegex.test(element)) {
        return true
    }

    return false
}

export class Loading extends Component {
    render() {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    backgroundColor: "rgba(0,0,0,.5)",
                    zIndex: "1"
                }}
            >
                <MuiThemeProvider>
                    <CircularProgress
                        style={{
                            marginLeft: "-40px",
                            marginTop: "-40px",
                            left: "50%",
                            top: "50%"
                        }}
                        color="#09beb6"
                        size={80}
                        thickness={5}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

export function validateRFC(rfc) {
    if (rfc === undefined || rfc.length > 13) {
        return false
    }

    const _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                  "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                  "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                  "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

    const _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                       "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                       "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                       "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$"

    // RFC Válido.
    if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)) {
        return true
    }

    return false
}
