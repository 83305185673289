import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Button,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import { Link } from 'react-router-dom'

import toastr from 'toastr'
import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context/'

import 'toastr/build/toastr.min.css'

import DefaultImage from '../../../assets/images/avatar-product-placeholder.png'

const Gallery = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [product, setProduct] = useState()
    const [variants, setVariants] = useState()
    const [addingNewVariant, setAddingNewVariant] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [tempVariant, setTempVariant] = useState({
        name: false,
        productCode: false,
        sku: false,
        options: [],
        stock: {
            currentStock: 0,
        }
    })

    useEffect(() => {
        setProduct(props.product)
        setVariants(props.variants)
        setFilteredData(props.variants)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const _loadProduct = async (id) => {
    //     const response = await ApiService.getProductById(id)

    //     setProduct(response.data.product)
    //     setVariants(response.data.product.variants)
    // }

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = variants.filter((item) => {
            const name = item.name || ''
            const sku = item.sku || ''
            const productCode = item.productCode || ''
            const description = item.description || ''

            return name.toLowerCase().includes(keystroke) ||
                productCode.toLowerCase().includes(keystroke) || 
                sku.toLowerCase().includes(keystroke) ||
                description.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteVariant = async (id) => {
        setLoading(true)
        const response = await ApiService.deleteVariant(id)
        if (!response.data.success) {
            this.showToast('Error', 'Hubo un problema al eliminar esta variación.', 'error')
            setLoading(false)
            return
        }

        setVariants(await variants.filter((item) => {
            return item.id !== id
        }))
        setLoading(false)
        toastr.success(`Variación Eliminada`, '¡Éxito!')
    }

    const _addProductVariant = async (e) => {
        e.preventDefault()
        let variant = tempVariant
        if (
            variant.buyPrice === false || 
            variant.wholeSalePrice === false || 
            variant.retailPrice === false ||
            variant.firstOption.value === false
        ) {
            toastr.error('No puedes agregar una variación vacía', 'Error')

            return
        }

        variant.name = `${variant.firstOption.value} / ${variant.secondOption.value}`
        variant.productCode = `${product.productCode}`
        variant.sku = `${product.sku}`
        variant.productId = product.id

        setLoading(true)
        const response = await ApiService.addProductVariant(variant)
        if (!response.data.success) {
            toastr.error(`No pude agregar a ${variant.name}`, 'error')
            setLoading(false)
            return
        }

        let data = variants
        data.push(variant)
        setVariants(data)
        
        setTempVariant({
            ...tempVariant,
            name: false,
            sku: false,
            vendoraCode: false
        })
        setLoading(false)
        toastr.success(`${variant.name} agregado`, 'Éxito')
    }

    return(
        <React.Fragment>
            <Row>
                <Col sm="6">
                    <h5>Variantes de {product.name}</h5>
                </Col>

                <Col sm="6">
                    <Row>
                        <Col sm={6}>
                            <div className="search-box">
                                <div className="position-relative">
                                    <Input type="text" className="form-control"
                                        placeholder="Buscar por SKU, Nombre de Variación, etc..."
                                        onKeyUp={(e) => _filterData(e)}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className="text-sm-right">
                                <Button 
                                    onClick={() => { setAddingNewVariant(!addingNewVariant)}}
                                    className="btn btn-secondary waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-plus mr-1"></i> Agregar Variación
                                </Button>
                                <Link to={`/productos/editar/${product.id}`} className="btn btn-success waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-pencil mr-1"></i> Editar Producto
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Table className="table">
                    <thead>
                        <th></th>
                        <th>SKU</th>
                        <th>Nombre del Producto</th>
                        {variants[0].firstOption.length > 0 && (
                            <th>{variants[0].firstOption.label}</th>
                        )}
                        {variants[0].secondOption.length > 0 && (
                            <th>{variants[0].secondOption.label}</th>
                        )}
                        {variants[0].thirdOption.length > 0 && (
                            <th>{variants[0].thirdOption.label}</th>
                        )}
                        <th>Precio Compra</th>
                        <th>Precio Vendora</th>
                        <th>Precio Venta</th>
                        <th>Stock</th>
                        <th>...</th>
                    </thead>
                    <tbody>
                        {filteredData.map((variant) => {
                            return(
                                <tr>
                                    <td>
                                        {variant.image && (
                                            <img alt={variant.name} style={{maxWidth: '64px'}} src={`https://storage.googleapis.com/vendora-storage/products/${variant.image}`} />
                                        )}

                                        {!variant.image && (
                                            <img alt={variant.name} style={{maxWidth: '64px'}} src={DefaultImage} />
                                        )}
                                    </td>
                                    <td>{variant.sku}</td>
                                    <td>{variant.name}</td>
                                    {variant.firstOption.length > 0 && (
                                        <td>{variants.firstOption.value}</td>
                                    )}
                                    {variant.secondOption.length > 0 && (
                                        <td>{variants.secondOption.value}</td>
                                    )}
                                    {variant.thirdOption.length > 0 && (
                                        <td>{variants.thirdOption.value}</td>
                                    )}
                                    <td>${variant.buyPrice}</td>
                                    <td>${variant.wholeSalePrice}</td>
                                    <td>${variant.retailPrice}</td>
                                    <td>{variant.stock ? variant.stock.currentStock : 0}</td>
                                    <td>
                                        <UncontrolledDropdown>
                                            <DropdownToggle href="#" className="card-drop" tag="i">
                                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem href={`/productos/editarvariant/${variant.id}`}>
                                                    <i className="mdi mdi-pencil font-size-16 text-success mr-2"></i> Editar
                                                </DropdownItem>
                                                <DropdownItem href="#"
                                                    onClick={() => {
                                                        _deleteVariant(variant.id)
                                                    }}>
                                                    <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i> Eliminar
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                            )
                        })}
                        {addingNewVariant && (
                            <tr>
                                {variants[0].firstOption.label && (
                                <td>
                                    <Input
                                        type="text"
                                        placeholder={variants[0].firstOption.label}
                                        className="form-control"
                                        onChange={(event) => {
                                            setTempVariant({
                                                ...tempVariant,
                                                firstOption: {
                                                    label: variants[0].firstOption.label,
                                                    value: event.target.value
                                                }
                                            })
                                        }}
                                    />
                                </td>
                                )}
                                {variants[0].secondOption.label && (
                                <td>
                                    <Input
                                        type="text"
                                        placeholder={variants[0].secondOption.label}
                                        className="form-control"
                                        onChange={(event) => {
                                            setTempVariant({
                                                ...tempVariant,
                                                secondOption: {
                                                    label: variants[0].secondOption.label,
                                                    value: event.target.value
                                                }
                                            })
                                        }}
                                    />
                                </td>
                                )}
                                {variants[0].thirdOption.label && (
                                    <td>
                                        <Input
                                            type="text"
                                            placeholder={variants[0].thirdOption.label}
                                            className="form-control"
                                            onChange={(event) => {
                                                setTempVariant({
                                                    ...tempVariant,
                                                    thirdOption: {
                                                        label: variants[0].thirdOption.label,
                                                        value: event.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </td>
                                )}
                                <td>
                                    <Input
                                        type="text"
                                        placeholder="Nombre del Producto"
                                        className="form-control"
                                        onChange={(event) => {
                                            setTempVariant({
                                                ...tempVariant,
                                                name: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        placeholder="Precio Compra"
                                        className="form-control"
                                        onChange={(event) => {
                                            setTempVariant({
                                                ...tempVariant,
                                                buyPrice: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        placeholder="Precio Compra"
                                        className="form-control"
                                        onChange={(event) => {
                                            setTempVariant({
                                                ...tempVariant,
                                                wholeSalePrice: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                
                                <td>
                                    <Input
                                        type="number"
                                        placeholder="Precio Compra"
                                        className="form-control"
                                        onChange={(event) => {
                                            setTempVariant({
                                                ...tempVariant,
                                                retailPrice: event.target.value
                                            })
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        placeholder="Stock"
                                        onChange={(event) => {
                                            setTempVariant({
                                                ...tempVariant,
                                                stock: {
                                                    currentStock: event.target.value,
                                                }
                                            })
                                        }}
                                    />
                                </td>

                                <td>
                                    <Button
                                        className="btn btn-success waves-effect waves-light mb-2 mr-2"
                                        onClick={ _addProductVariant }>
                                        <i className="mdi mdi-plus"></i>
                                    </Button>

                                    <Button
                                        className="btn btn-secondary waves-effect waves-light mb-2 mr-2"
                                        onClick={() => { setAddingNewVariant( !addingNewVariant ) }}>
                                        <i className="mdi mdi-close"></i>
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Row>
        </React.Fragment>
    )
}

export default Gallery