import React, { useContext, useEffect } from 'react'
import { GeneralContext } from '../../../context/'
import {
    Container,
} from "reactstrap"

import Form from './AddressForm'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const Create = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setBreadcrumb } = generalContext

    useEffect(() => {
        const customerId = props.match.params.customerId
        setBreadcrumb({
            icon: '',
            parent: props.t(`Customer Addresses`),
            parentLink: `/customers/addresses/${customerId}`,
            item: props.t(`New Address`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Form {...props} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Create))