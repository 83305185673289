import React, { useEffect, useState, useContext } from "react"
import {
    Container,
    Card,
    CardTitle,
    CardBody,
    Col,
    Row,
    FormGroup,
    Label
} from "reactstrap"
import { withNamespaces } from "react-i18next"
import BulkImportModify from './components/BulkUpdateStock'
import ApiService from '../../data/services/ApiService'
import Select from "react-select"
import { GeneralContext } from '../../context'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import moment from 'moment'
import { saveStream } from '../../helpers/utils'
import { isEmpty } from 'lodash'

moment.locale('es')
const Import = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext

    const [branches, setBranches] = useState([])
    const [branchId, setBranchId] = useState('')

    useEffect(() => {
        setBreadcrumb({
            icon: '',
            parent: props.t(`Inventory`),
            parentLink: '/products',
            item: props.t(`Update Stock`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (async () => {
            await getBranches()
        })()
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getBranches = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getBranches()
            let data = []

            // eslint-disable-next-line
            response.data.branches.map((item) => {
                data.push({
                    value: item.id,
                    label: item.branchName
                })
            })

            setBranches(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const __exportProducts = async (e) => {
        if (isEmpty(branchId.value)) {
            toastr.error(props.t(`You must select a branch to export from.`))

            return
        }

        try {
            setLoading(true)
            const response = await ApiService.exportProductsForStockUpdate({
                branchId: branchId.value
            })

            saveStream(
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                `products-stock-update-template-${moment().format('DD-MM-YYYY_HH-mm-ss')}.csv`,
                response.data
            )
        } catch (error) {
            toastr.error(error.response.data.message, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _renderStep1 = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col sm={6} xs={12}>
                        <Card>
                            <CardBody>
                                <CardTitle>Exportar Catálogo de Productos</CardTitle>

                                <FormGroup>
                                    <Label>{props.t(`Branches`)}</Label>

                                    <Select
                                        value={branchId}
                                        placeholder={props.t(`Select a branch`)}
                                        onChange={(value) => { 
                                            setBranchId(value)
                                        }}
                                        options={branches}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>

                                <button
                                    className="btn btn-secondary waves-effect waves-light mb-2 mr-2"
                                    onClick={__exportProducts}
                                    disabled={false}
                                >
                                    <i className="mdi mdi-download mr-1"></i> Descargar Template
                                </button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} xs={12}>
                        <Card>
                            <CardBody>
                                <BulkImportModify />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    { _renderStep1() }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces()(Import)