export default [
    {value: '01', label: 'Efectivo'},
    {value: '02', label: 'Cheque nominativo'},
    {value: '03', label: 'Transferencia electrónica de fondos'},
    {value: '04', label: 'Tarjeta de crédito'},
    {value: '05', label: 'Monedero electrónico'},
    {value: '06', label: 'Dinero electrónico'},
    {value: '08', label: 'Vales de despensa'},
    {value: '12', label: 'Dación en pago'},
    {value: '13', label: 'Pago por subrogación'},
    {value: '14', label: 'Pago por consignación'},
    {value: '15', label: 'Condonación'},
    {value: '17', label: 'Compensación'},
    {value: '23', label: 'Novación'},
    {value: '24', label: 'Confusión'},
    {value: '25', label: 'Remisión de deuda'},
    {value: '26', label: 'Prescripción o caducidad'},
    {value: '27', label: 'A satisfacción del acreedor'},
    {value: '28', label: 'Tarjeta de débito'},
    {value: '29', label: 'Tarjeta de servicios'},
    {value: '99', label: 'Por definir'}
]