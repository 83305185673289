import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
} from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import View from './components/View'
import { GeneralContext } from '../../context/'
import 'toastr/build/toastr.min.css'

export default withRouter(withNamespaces()((props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading, setBreadcrumb } = generalContext
    const [salesOrderId, setSalesOrderId] = useState()

    useEffect(() => {
        setSalesOrderId(props.match.params.id)

        setBreadcrumb({
            icon: '',
            parent: props.t(`Sales Orders`),
            parentLink: '/sales-order',
            item: props.t(`Sales Order`)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {salesOrderId && (<View t={props.t} history={props.history} setLoading={setLoading} salesOrderId={salesOrderId} />)}
                </Container>
            </div>
        </React.Fragment>       
    )
}))