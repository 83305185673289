import React, { useState, useContext, useEffect } from 'react'

import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap"

import { withRouter, useParams } from "react-router-dom"
import toastr from 'toastr'
import { GeneralContext } from '../../context/'
import ApiService from '../../data/services/ApiService'

const RecoverPassword = (props) => {
    const { token } = useParams()
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        (async () => {
            await validatePasswordRecoverToken()
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const validatePasswordRecoverToken = async () => {
        try {
            setLoading(true)
            const response = await ApiService.validatePasswordRecoverToken(token)

            if (!response.data.success) {
                props.history.push('/login')
                
                return
            }
        } catch (error) {
            props.history.push('/login')
        } finally {
            setLoading(false)
        }
    }

    const _handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        if (password !== confirmPassword) {
            toastr.error(`Las contraseñas que escribiste no coinciden`)

            return
        }

        try {
            const response = await ApiService.confirmPasswordRecover({
                token,
                password
             })

            toastr.success(response.data.message)
            props.history.push('/login')
        } catch (error) {
            setLoading(false)
            toastr.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="login">
                <div className="wrapper grid-layout">
                    <Form id="login" type="POST" onSubmit={ _handleSubmit }>
                        <div className="logo">
                            <img alt={`Timbrát`} src="/assets/images/logo.svg" />
                        </div>

                        <div className="form">
                            <h1>Cambiar Contraseña</h1>

                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="password">
                                            Contraseña:
                                        </Label>
                                        <Input
                                            required
                                            id="password"
                                            type="password"
                                            className="form-control"
                                            value={password}
                                            onChange={event => setPassword(event.target.value)}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="password2">
                                            Confirmar Contraseña:
                                        </Label>
                                        <Input
                                            required
                                            id="password2"
                                            type="password"
                                            className="form-control"
                                            value={confirmPassword}
                                            onChange={event => setConfirmPassword(event.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className='form-actions'>
                                <Col sm={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >
                                        Cambiar Contraseña
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className="login-copyright">
                                    <p>Copyright © {new Date().getFullYear()}. Todos los Derechos Reservados.</p>
                                    <p>Hecho con amor por <a rel="noopener noreferrer" target="_blank" href="https://juvasoft.com/?utm_source=timbrat&utm_medium=login">Juvasoft</a></p>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(RecoverPassword)