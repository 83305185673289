import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import ApiService from '../../data/services/ApiService'
import Moment from 'moment'
import num2letras from '../../helpers/num2letras'
import CurrencyFormat from 'react-currency-format'
import Barcode from 'react-barcode'

const Print = (props) => {
    const [sale, setSale] = useState()
    const [lineItems, setLineItems] = useState([])
    const [customer, setCustomer] = useState({})

    const paymentTypeCatalog = [
        {value: '01', label: 'Efectivo'},
        {value: '02', label: 'Cheque nominativo'},
        {value: '03', label: 'Transferencia electrónica de fondos'},
        {value: '04', label: 'Tarjeta de crédito'},
        {value: '05', label: 'Monedero electrónico'},
        {value: '06', label: 'Dinero electrónico'},
        {value: '08', label: 'Vales de despensa'},
        {value: '12', label: 'Dación en pago'},
        {value: '13', label: 'Pago por subrogación'},
        {value: '14', label: 'Pago por consignación'},
        {value: '15', label: 'Condonación'},
        {value: '17', label: 'Compensación'},
        {value: '23', label: 'Novación'},
        {value: '24', label: 'Confusión'},
        {value: '25', label: 'Remisión de deuda'},
        {value: '26', label: 'Prescripción o caducidad'},
        {value: '27', label: 'A satisfacción del acreedor'},
        {value: '28', label: 'Tarjeta de débito'},
        {value: '29', label: 'Tarjeta de servicios'},
        {value: '99', label: 'Por definir'}
    ]

    useEffect(() => {
        (async () => {
            const id = props.match.params.id
            await fetchSaleById(id)
        })()
    }, []) // eslint-disable-line

    const fetchSaleById = async (id) => {
        try {
            const response = await ApiService.getSaleById(id)

            setSale(response.data.sale)
            setLineItems(response.data.sale.lineItems)
            setCustomer(response.data.sale.customer)
        } catch (error) {
            // 
        }
    }

    return (
        <React.Fragment>
        {sale && (<div className="sale page">
            <section className="logotipo">
                {sale.company.logo && (
                    <img width={150} alt={sale.company.businessName} src={sale.company.logo} />
                )}
            </section>

            <section className="datosempresa">
                <strong>{sale.company.businessName}</strong><br />
                {sale.company.legalBusinessName} <br />
                RFC: {sale.company.taxId} <br />
                {sale.company.street} {sale.company.exteriorNumber} {sale.company.interiorNumber}<br />
                {sale.company.neighborhood} {sale.company.exteriorNumber} {sale.company.city} {sale.company.state} <br />
                {sale.company.country} {sale.company.zipCode}<br /><br />

                <p>ESTE COMPROBANTE NO ES VÁLIDO PARA EFECTOS FISCALES</p>
            </section>

            <section className="folio">
                <strong>Folio No.:</strong> {sale.folio}<br />
                <strong>Fecha:</strong> {Moment(sale.createdAt).format("DD/MM/YYYY")}<br />
                <strong>Hora:</strong> {Moment(sale.createdAt).format("hh:mm:ss a")}<br />
                <strong>Cliente: </strong> {customer.businessName} <br />
                {paymentTypeCatalog.filter(t => t.value === sale.paymentType).length > 0 && (
                    <React.Fragment>
                        <strong>Forma de Pago: </strong> ({paymentTypeCatalog.filter(type => type.value === sale.paymentType)[0].value}) {paymentTypeCatalog.filter(type => type.value === sale.paymentType)[0].label}<br />
                    </React.Fragment>
                )}
            </section>

            <section className="items">
                <table>
                    <thead>
                        <th>Cant.</th>
                        <th>Concepto</th>
                        <th>P.U.</th>
                        <th>Importe</th>
                    </thead>
                    <tbody>
                        {lineItems.map((item, i) => (
                            <tr key={i}>
                                <td>{item.quantity}</td>
                                <td>
                                    {item.product.name} <br />
                                    {item.serie && ( <small>Serie / Lote: {item.serie}</small> )}
                                </td>
                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitPrice} /></td>
                                <td><CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={item.unitPrice * item.quantity} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            <section className="totales">
                <strong>SUBTOTAL: $</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={sale.subtotal} /><br />
                <strong>IVA: $</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={sale.taxAmount} /><br />
                <strong>TOTAL: $</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={sale.total} /><br />
                <br />
                {sale.amountPaid && (
                    <React.Fragment>
                        <strong>Pagado: $</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={sale.amountPaid} /><br />
                        <strong>Cambio: $</strong> <CurrencyFormat decimalScale={2} displayType="text" thousandSeparator prefix="$" value={sale.amountPaid - sale.total} /><br />
                    </React.Fragment>
                )}

                <hr />

                <p className='totalItems'># de Arts. Vendidos: {lineItems.length}</p>
                <p className="cantidadLetra">Son {num2letras(Math.floor(sale.total))} {(((Math.round(sale.total * 100)) - (Math.floor(sale.total) * 100)))}/100 M.N.</p>
            </section>

            <div className="barcode-wrapper">
                <Barcode width={5} lineColor={'#495057'} displayValue={false} value={sale.folio} />
            </div>

            <section className='gracias'>
                <p>GRACIAS POR SU PREFERENCIA</p>
                <p>** COPIA CLIENTE **</p>

                <p>juvasoft.com</p>
            </section>
        </div>)}
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Print))