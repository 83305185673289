import { isEmpty } from 'lodash'
import ApiHelper from '../../helpers/ApiHelper'
import BulkOperationsApiHelper from '../../helpers/BulkOperationsApiHelper'

export class ApiProduct {

    getBaseURL() {
        return ApiHelper.getBaseURL()
    }

    async signup(user) {
        return await ApiHelper.post(`/v1/user/signup`, user)
    }

    async verifyAccount(data) {
        return ApiHelper.post(`/v1/user/verify`, data)
    }

    async login(login) {
        return await ApiHelper.post(`/v1/user/login`, login)
    }

    async passwordRecover(data) {
        return await ApiHelper.post(`/v1/user/password-recover`, data)
    }

    async validatePasswordRecoverToken(token) {
        return await ApiHelper.get(`/v1/user/validate-password-recover-token/${token}`)
    }

    async confirmPasswordRecover(data) {
        return await ApiHelper.post(`/v1/user/confirm-password-recover`, data)
    }

    async updatePassword(data) {
        return await ApiHelper.put(`/v1/user/update-password`, data)
    }

    async getUsers() {
        return await ApiHelper.get(`/v1/user/get`)
    }

    async getUserById(userId) {
        return await ApiHelper.get(`/v1/user/get-by-id/${userId}`)
    }

    async createUser(user) {
        return await ApiHelper.post(`/v1/user/save`, user)
    }

    async updateUser(user) {
        return await ApiHelper.put(`/v1/user/update`, user)
    }

    async deleteUser(id) {
        return await ApiHelper.delete(`/v1/user/delete/${id}`)
    }

    async uploadUserAvatar(data) {
        return await ApiHelper.postWithFiles(`/v1/user/upload-avatar`, data)
    }

    async uploadCompanyLogo(data) { 
        return await ApiHelper.postWithFiles(`/v1/company/upload-logo`, data)
    }

    async updateCompany(data) { 
        return await ApiHelper.put(`/v1/company/update`, data)
    }

    // Companies
    async getMyCompanies() {
        return await ApiHelper.get(`/v1/company/my-companies`)
    }

    async getTeamMembers(companyId) {
        return await ApiHelper.get(`/v1/company/team-members/${companyId}`)
    }

    /// ROLES
    async getRoles() {
        return await ApiHelper.get(`/v1/role/get`)
    }

    async getRole(roleId) {
        return await ApiHelper.get(`/v1/role/get-by-id/${roleId}`)
    }

    async createRole(role) {
        return await ApiHelper.post(`/v1/role/save`, role)
    }

    async updateRole(role) {
        return await ApiHelper.put(`/v1/role/update`, role)
    }

    async deleteRole(id) {
        return await ApiHelper.delete(`/v1/role/delete/${id}`)
    }

    /// BRANDS
    async getBrands() {
        return await ApiHelper.get(`/v1/brand/get`)
    }

    async getBrandById(id) {
        return await ApiHelper.get(`/v1/brand/get-by-id/${id}`)
    }

    async createBrand(data) {
        return await ApiHelper.post(`/v1/brand/save`, data)
    }

    async updateBrand(data) {
        return await ApiHelper.put(`/v1/brand/update`, data)
    }

    async deleteBrand(id) {
        return await ApiHelper.delete(`/v1/brand/delete/${id}`)
    }

    async uploadBrandImage(data) {
        return await ApiHelper.postWithFiles(`/v1/brand/upload`, data)
    }

    /// BRANCHES
    async getBranches() {
        return await ApiHelper.get(`/v1/branch/get`)
    }

    async getBranch(id) {
        return await ApiHelper.get(`/v1/branch/get-by-id/${id}`)
    }

    async createBranch(data) {
        return await ApiHelper.post(`/v1/branch/save`, data)
    }

    async updateBranch(data) {
        return await ApiHelper.put(`/v1/branch/update`, data)
    }

    async deleteBranch(id) {
        return await ApiHelper.delete(`/v1/branch/delete/${id}`)
    }

    /// CUSTOMERS
    async getCustomerById(id) {
        return await ApiHelper.get(`/v1/customer/get-by-id/${id}`)
    }

    async getCustomers() {
        return await ApiHelper.get(`/v1/customer/get`)
    }

    async deleteCustomer(id) {
        return await ApiHelper.delete(`/v1/customer/delete/${id}`)
    }

    async createCustomer(data) {
        return await ApiHelper.post(`/v1/customer/save`, data)
    }

    async updateCustomer(data) {
        return await ApiHelper.put(`/v1/customer/update`, data)
    }

    // CUSTOMER ADDRESS
    async addCustomerAddress(data) {
        return await ApiHelper.post(`/v1/customer/address/add`, data)
    }

    async getAddressByCustomerId(id) {
        return await ApiHelper.get(`/v1/customer/address/get-by-customer-id/${id}`)
    }

    async getAddressById(id) {
        return await ApiHelper.get(`/v1/customer/address/get-by-id/${id}`)
    }

    async deleteCustomerAddress(id) {
        return await ApiHelper.delete(`/v1/customer/address/delete/${id}`)
    }

    async updateCustomerAddress(data) {
        return await ApiHelper.put(`/v1/customer/address/update`, data)
    }

    /// PRODUCTS
    async getProducts(offset, limit, filter) {
        let moreParams = '&'
        if (!isEmpty(filter)) {
            for (const f of filter) {
                moreParams += `${f.columnName}=${f.value}`
            }
        }

        return await ApiHelper.get(`/v1/product/get?offset=${offset}&limit=${limit}${moreParams}`)
    }

    async fetchDefaultPriceList() {
        return await ApiHelper.get('/v1/price-types/get-default')
    }

    async getPopularProducts(priceTypeId) {
        return await ApiHelper.get(`/v1/product/get-popular/${priceTypeId}`)
    }

    async searchProducts(keyword, offset, limit) {
        return await ApiHelper.get(`/v1/product/search/${keyword}?offset=${offset}&limit=${limit}`)
    }

    async searchVariants(keyword, priceTypeId) {
        return await ApiHelper.get(`/v1/product/search-variants/${keyword}/${priceTypeId}`)
    }

    async searchVariantsBySupplierId(keyword, supplierId) {
        return await ApiHelper.get(`/v1/product/search-variants-by-supplier/${keyword}/${supplierId}`)
    }

    // eslint-disable-next-line
    async searchVariants(keyword) {
        return await ApiHelper.get(`/v1/product/search-variants/${keyword}`)
    }

    async getProductById(id, includeVariants) {
        const query = includeVariants ? `?includeVariants=true` : ''
        return await ApiHelper.get(`/v1/product/get-by-id/${id}${query}`)
    }

    async createProduct(data) {
        return await ApiHelper.post(`/v1/product/save`, data)
    }

    async updateProduct(data) {
        return await ApiHelper.post(`/v1/product/update`, data)
    }

    async deleteProduct(id) {
        return await ApiHelper.delete(`/v1/product/delete/${id}`)
    }

    async exportProducts(filters) {
        return await ApiHelper.post(`/v1/product/export`, filters)
    }

    async exportProductsForPriceUpdate(data) {
        return await ApiHelper.post(`/v1/product/export-product-for-pricing-update`, data)
    }

    async exportProductsForStockUpdate(data) {
        return await ApiHelper.post(`/v1/product/export-product-for-stock-update`, data)
    }

    async uploadProductImage(data) {
        return await ApiHelper.postWithFiles(`/v1/product/upload`, data)
    }

    async addProductVariant(data) {
        return await ApiHelper.post(`/v1/product/add-variant`, data)
    }

    async deleteVariant(id) {
        return await ApiHelper.delete(`/v1/product/delete-variant/${id}`)
    }

    async editVariant(data) {
        return await ApiHelper.put(`/v1/product/edit-variant`, data)
    }

    async deleteProductImage(productId, fileName) {
        return await ApiHelper.delete(`/v1/product/images/delete/${productId}/${fileName}`)
    }

    async setDefaultImage(data) {
        return await ApiHelper.put(`/v1/product/images/set-default`, data)
    }

    async getProductVariant(id) {
        return await ApiHelper.get(`/v1/product/variants/get/${id}`)
    }

    async getProductGallery(productId) {
        return await ApiHelper.get(`/v1/product/gallery/get/${productId}`)
    }

    async getProductCodes() {
        return await ApiHelper.get(`/v1/product/get-product-codes`)
    }

    async getPriceTypes() {
        return await ApiHelper.get('/v1/price-types/get')
    }

    async getPriceTypeById(id) {
        return await ApiHelper.get(`/v1/price-types/get-by-id/${id}`)
    }

    async createPriceType(data) {
        return await ApiHelper.post(`/v1/price-types/save`, data)
    }

    async updatePriceType(data) {
        return await ApiHelper.put(`/v1/price-types/update`, data)
    }

    async deletePriceType(id) {
        return await ApiHelper.delete(`/v1/price-types/delete/${id}`)
    }

    async bulkImport(data) {
        return await BulkOperationsApiHelper.postWithFiles(`/v1/product/bulk-import`, data)
    }

    async bulkImportPrices(data) {
        return await BulkOperationsApiHelper.postWithFiles(`/v1/product/bulk-import-prices`, data)
    }

    async bulkImportStock(data) {
        return await BulkOperationsApiHelper.postWithFiles(`/v1/product/bulk-import-stock`, data)
    }

    async pollBulkImport(identifier) {
        return await BulkOperationsApiHelper.get(`/v1/product/poll-import-process/${identifier}`)
    }

    /// CATEGORIES
    async getCategories() {
        return await ApiHelper.get(`/v1/category/get`)
    }

    async getCategoryById(id) {
        return await ApiHelper.get(`/v1/category/get-by-id/${id}`)
    }

    async getCategoriesByFamilyId(id) {
        return await ApiHelper.get(`/v1/category/get-by-family/${id}`)
    }

    async createCategory(data) {
        return await ApiHelper.post(`/v1/category/save`, data)
    }

    async updateCategory(data) {
        return await ApiHelper.put(`/v1/category/update`, data)
    }

    async deleteCategory(id) {
        return await ApiHelper.delete(`/v1/category/delete/${id}`)
    }

    async uploadCategoryImage(data) {
        return await ApiHelper.postWithFiles(`/v1/category/upload`, data)
    }

    /// FAMILIES
    async getFamilies() {
        return await ApiHelper.get(`/v1/family/get`)
    }

    async getFamilyById(id) {
        return await ApiHelper.get(`/v1/family/get-by-id/${id}`)
    }

    async createFamily(data) {
        return await ApiHelper.post(`/v1/family/save`, data)
    }

    async updateFamily(data) {
        return await ApiHelper.put(`/v1/family/update`, data)
    }

    async deleteFamily(id) {
        return await ApiHelper.delete(`/v1/family/delete/${id}`)
    }

    async uploadFamilyImage(data) {
        return await ApiHelper.postWithFiles(`/v1/family/upload`, data)
    }

    // SUBCATEGORIES
    async getSubcategories() {
        return await ApiHelper.get(`/v1/subcategory/get`)
    }

    async getSubcategoriesByCategoryId(id) {
        return await ApiHelper.get(`/v1/subcategory/get-by-category/${id}`)
    }

    async getSubcategoryById(id) {
        return await ApiHelper.get(`/v1/subcategory/get-by-id/${id}`)
    }

    async createSubcategory(data) {
        return await ApiHelper.post(`/v1/subcategory/save`, data)
    }

    async updateSubcategory(data) {
        return await ApiHelper.put(`/v1/subcategory/update`, data)
    }

    async deleteSubcategory(id) {
        return await ApiHelper.delete(`/v1/subcategory/delete/${id}`)
    }

    async uploadSubcategoryImage(data) {
        return await ApiHelper.postWithFiles(`/v1/subcategory/upload`, data)
    }

    /// SUPPLIERS
    async getSuppliers() {
        return await ApiHelper.get(`/v1/supplier/get`)
    }

    async getSupplierById(id) {
        return await ApiHelper.get(`/v1/supplier/get-by-id/${id}`)
    }

    async createSupplier(data) {
        return await ApiHelper.post(`/v1/supplier/save`, data)
    }

    async updateSupplier(data) {
        return await ApiHelper.put(`/v1/supplier/update`, data)
    }

    async deleteSupplier(supplierId) {
        return await ApiHelper.delete(`/v1/supplier/delete/${supplierId}`)
    }

    async uploadSupplierImage(data) {
        return await ApiHelper.postWithFiles(`/v1/supplier/upload`, data)
    }

    // BANKS
    async getBankAccounts() {
        return await ApiHelper.get(`/v1/bank/get`)
    }

    async getBankAccount(id) {
        return await ApiHelper.get(`/v1/bank/account/${id}`)
    }

    async createBankAccount(data) {
        return await ApiHelper.post(`/v1/bank/save`, data)
    }

    async updateBankAccount(data) {
        return await ApiHelper.put(`/v1/bank/update`, data)
    }

    async deleteBankAccount(id) {
        return await ApiHelper.delete(`/v1/bank/delete/${id}`)
    }

    async incrementBalance(id, amount) {
        return await ApiHelper.put(`/v1/bank/increment-balance/${id}`, { amount })
    }

    async decrementBalance(id, amount) {
        return await ApiHelper.put(`/v1/bank/decrement-balance/${id}`, { amount })
    }

    async getCurrencies() {
        return await ApiHelper.get(`/v1/bank/get-currencies`)
    }

    // Expenses
    async getExpenses() {
        return await ApiHelper.get(`/v1/expenses/get`)
    }

    async getExpenseById(id) {
        return await ApiHelper.get(`/v1/expenses/get-by-id/${id}`)
    }

    async createExpense(data) {
        return await ApiHelper.post(`/v1/expenses/save`, data)
    }

    async updateExpense(data) {
        return await ApiHelper.put(`/v1/expenses/update`, data)
    }

    async uploadExpenseAttachment(data) {
        return await ApiHelper.postWithFiles(`/v1/expenses/upload`, data)
    }

    async getExpenseTypes() {
        return await ApiHelper.get(`/v1/expenses/get-types`)
    }

    async deleteExpense(id) {
        return await ApiHelper.delete(`/v1/expenses/delete/${id}`)
    }

    // Settings
    async getSettingsByCode(code) {
        return await ApiHelper.get(`/v1/settings/get/${code}`)
    }

    async getSettings() {
        return await ApiHelper.get(`/v1/settings/get-all`)
    }

    // Sales
    async getSaleById(saleId) {
        return await ApiHelper.get(`/v1/sales/get-by-id/${saleId}`)
    }

    async saveSale(sale) {
        return await ApiHelper.post(`/v1/sales/save`, sale)
    }

    async fetchLastFolio() {
        return await ApiHelper.get(`/v1/sales/get-last-folio`)
    }

    async fetchNextFolio() {
        return await ApiHelper.get(`/v1/sales/get-next-folio`)
    }

    async getSales() {
        return await ApiHelper.get(`/v1/sales/get`)
    }

    ////// stock control
    async getVariantsWithLowStock() {
        return await ApiHelper.get(`/v1/stock/get-low-stock-variants`)
    }

    async getProductsBySupplierId(supplierId) {
        return await ApiHelper.get(`/v1/product/get-by-supplier/${supplierId}`)
    }

    // purchase orders
    async getNextPurchaseOrderFolio() {
        return await ApiHelper.get(`/v1/purchase-order/get-next-folio`)
    }

    async savePurchaseOrder(data) {
        return await ApiHelper.post(`/v1/purchase-order/save`, data)
    }

    async getPurchaseOrders() {
        return await ApiHelper.get(`/v1/purchase-order/get`)
    }

    async getPurchaseOrderById(id) {
        return await ApiHelper.get(`/v1/purchase-order/get-by-id/${id}`)
    }

    async getPurchaseOrderForPrintById(id) {
        return await ApiHelper.get(`/v1/purchase-order/get-for-print/${id}`)
    }

    async cancelPurchaseOrder(id) {
        return await ApiHelper.delete(`/v1/purchase-order/cancel/${id}`)
    }

    async markOrderAsPaid(order) {
        return await ApiHelper.post(`/v1/purchase-order/mark-as-paid`, { orderData: order})
    }

    async receiveProducts(orderId, lineItems) {
        return await ApiHelper.post(`/v1/purchase-order/receive-products/${orderId}`, { lineItems })
    }

    // purchase quotes
    async getNextPurchaseQuoteFolio() {
        return await ApiHelper.get(`/v1/purchase-quote/get-next-folio`)
    }

    async savePurchaseQuote(data) {
        return await ApiHelper.post(`/v1/purchase-quote/save`, data)
    }

    async savePurchaseQuoteAsDraft(data) {
        return ApiHelper.post(`/v1/purchase-quote/save-draft`, data)
    }

    async getPurchaseQuote() {
        return await ApiHelper.get(`/v1/purchase-quote/get`)
    }

    async getPurchaseQuoteById(id) {
        return await ApiHelper.get(`/v1/purchase-quote/get-by-id/${id}`)
    }

    async getPurchaseQuoteForPrintById(id) {
        return await ApiHelper.get(`/v1/purchase-quote/get-for-print/${id}`)
    }

    async cancelPurchaseQuote(id) {
        return await ApiHelper.delete(`/v1/purchase-quote/cancel/${id}`)
    }

    async markPurchaseQuoteAsOrder(order) {
        return await ApiHelper.post(`/v1/purchase-quote/mark-as-ordered`, order)
    }

    // Sales Order
    async getNextSalesOrderFolio() {
        return await ApiHelper.get(`/v1/sales-order/get-next-folio`)
    }

    async saveSalesOrder(data) {
        return await ApiHelper.post(`/v1/sales-order/save`, data)
    }

    async getSalesOrders() {
        return await ApiHelper.get(`/v1/sales-order/get`)
    }

    async getSalesOrderById(id) {
        return await ApiHelper.get(`/v1/sales-order/get-by-id/${id}`)
    }

    async getSalesOrderForPrintById(id) {
        return await ApiHelper.get(`/v1/sales-order/get-for-print/${id}`)
    }

    async cancelSalesOrder(id) {
        return await ApiHelper.delete(`/v1/sales-order/cancel/${id}`)
    }

    async markSalesOrderAsPaid(data) {
        return await ApiHelper.post(`/v1/sales-order/mark-as-paid`, data)
    }

    async saleOrderFulfillProducts(orderId, lineItems) {
        return await ApiHelper.post(`/v1/sales-order/fulfill/${orderId}`, { lineItems })
    }

    // sales quotes
    async getNextSalesQuoteFolio() {
        return await ApiHelper.get(`/v1/sales-quote/get-next-folio`)
    }

    async saveSalesQuote(data) {
        return await ApiHelper.post(`/v1/sales-quote/save`, data)
    }

    async getSalesQuotes() {
        return await ApiHelper.get(`/v1/sales-quote/get`)
    }

    async getSalesQuoteById(id) {
        return await ApiHelper.get(`/v1/sales-quote/get-by-id/${id}`)
    }

    async getSalesQuoteForPrintById(id) {
        return await ApiHelper.get(`/v1/sales-quote/get-for-print/${id}`)
    }

    async cancelSalesQuote(id) {
        return await ApiHelper.delete(`/v1/sales-quote/cancel/${id}`)
    }

    async markSalesQuoteAsPaid(data) {
        return await ApiHelper.post(`/v1/sales-quote/mark-as-paid`, data)
    }

    // Tax Schemes
    async getTaxSchemes() {
        return await ApiHelper.get(`/v1/tax-schemes/get`)
    }

    async getTaxSchemeById(id) {
        return await ApiHelper.get(`/v1/tax-schemes/get/${id}`)
    }

    async getDefaultTaxScheme() {
        return await ApiHelper.get(`/v1/tax-schemes/get-default`)
    }

    async createTaxScheme(scheme) {
        return await ApiHelper.post(`/v1/tax-schemes/save`, scheme)
    }

    async updateTaxScheme(scheme) {
        return await ApiHelper.post(`/v1/tax-schemes/update`, scheme)
    }

    async deleteTaxScheme(id) {
        return await ApiHelper.delete(`/v1/tax-schemes/delete/${id}`)
    }

    async getActivePlanes() {
        return await ApiHelper.get(`/v1/planes/get`)
    }

    async getCountries() {
        return await ApiHelper.get(`/v1/country/get`)
    }

    async getStates(countryId) {
        return await ApiHelper.get(`/v1/state/get-by-countryId/${countryId}`)
    }
}

export default new ApiProduct()