import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    Table,
    InputGroup,
    InputGroupAddon
} from 'reactstrap'
import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context'
import TagsInput from 'react-tagsinput'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import { draftToMarkdown } from 'markdown-draft-js'
import toastr from 'toastr'
import Dropzone from "react-dropzone"
import { Link, withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { connect } from 'react-redux'
import Select from 'react-select'
import CSelect from 'react-select/creatable'

import 'react-tagsinput/react-tagsinput.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import 'toastr/build/toastr.min.css'

const CreateForm = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const genders = [ {
        label: 'Femenino',
        value: 'Femenino'
    }, {
        label: 'Masculino',
        value: 'Masculino'
    }, {
        label: 'Neutro',
        value: 'Neutro'
    }]

    const units = [{
        value: 'g',
        label: 'g'
    }, {
        value: 'kg',
        label: 'kg'
    }, {
        value: 'l',
        label: 'l'
    }, {
        value: 'ml',
        label: 'ml'
    }, {
        value: 'oz',
        label: 'oz'
    }, {
        value: 'lb',
        label: 'lb'
    }]

    const sizeUnits = [{
        value: 'cm',
        label: props.t('Centimeters')
    }, {
        value: 'm',
        label: props.t('Meters')
    }, {
        value: 'in',
        label: props.t('Inches')
    }, {
        value: 'mm',
        label: props.t('Millimeters')
    }]

    const variantTypes = [{
        value: 'color',
        label: props.t('Color')
    }, {
        value: 'size',
        label: props.t('Size')
    }, {
        value: 'pieces',
        label: props.t('Pieces')
    }, {
        value: 'presentation',
        label: props.t('Presentation')
    }]

    const [familyId, setFamilyId] = useState()
    const [categoryId, setCategoryId] = useState()
    const [subcategoryId, setSubCategoryId] = useState()
    const [brandId, setBrandId] = useState()
    const [sku, setSKU] = useState('')
    const [upc, setUPC] = useState('')
    const [barcode, setBarcode] = useState('')
    const [description, setDescription] = useState('')
    const [tags, setTags] = useState([])
    const [name, setName] = useState('')    
    const [featured] = useState(false)
    const [weight, setWeight] = useState()
    const [weightUnit, setWeightUnit] = useState({
        value: 'g',
        label: 'g'
    })
    const [sizeUnit, setSizeUnit] = useState({
        value: 'cm',
        label: props.t('Centimeters')
    })
    const [gender, setGender] = useState({
        label: 'Neutro',
        value: 'Neutro'
    })
    const [model, setModel] = useState('')
    const [boxHeight, setBoxHeight] = useState()
    const [boxLength, setBoxLength] = useState()
    const [boxWidth, setBoxWidth] = useState()
    const [initialStock, setInitialStock] = useState(0)
    const [sellable, setSellable] = useState(true)
    const [productType, setProductType] = useState({
        label: props.t(`Stocked Products`),
        value: 'stocked'
    })
    const [unitCost, setUnitCost] = useState()
    const [itHasVariants, setItHasVariants] = useState(false)
    const [variants, setVariants] = useState([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [priceList, setPriceList] = useState([])
    const [variantOptions, setVariantOptions] = useState([])
    const [taxSchemes, setTaxSchemes] = useState([])
    const [taxSchemeId, setTaxSchemeId] = useState()
    const [images, setImages] = useState([])

    const [tempVariant, setTempVariant] = useState({
        name: false,
        barcode: false,
        sku: false,
        unitCost: 0,
        priceList: [],
        options: [],
        initialStock: 0
    })

    // Relations
    const [families, setFamilies] = useState([])
    const [categories, setCategories] = useState([])
    const [subcategories, setSubCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [priceTypes, setPriceTypes] = useState([])

    useEffect(() => {
        (async () => {
            await fetchFamilies()
            await fetchBrands()
            await fetchPriceTypes()
            await fetchTaxSchemes()
        })()

        setBarcode(`BC-${Math.floor(10000 + Math.random() * 90000)}`)
    }, []) // eslint-disable-line

    async function fetchTaxSchemes() {
        try {
            setLoading(true)
            const { data } = await ApiService.getTaxSchemes()
            let array = []

            for (let s of data.schemes) {
                array.push({
                    value: s.id,
                    label: s.label,
                    default: s.default
                })
            }

            let dts = array.find(i => i.default)

            setTaxSchemes(array)
            setTaxSchemeId(dts)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your tax schemes`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchFamilies() {
        try {
            setLoading(true)
            const response = await ApiService.getFamilies()
            let data = []

            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.name
                })
            })

            setFamilies(data)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your families`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchBrands() {
        try {
            setLoading(true)
            const response = await ApiService.getBrands()
            let brands = []

            response.data.data.map((brand) => {
                const item = {
                    value: brand.id,
                    label: brand.name,
                }

                return brands.push(item)
            })

            setBrands(brands)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your brands`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchCategories(id) {
        try {
            setLoading(true)
            const response = await ApiService.getCategoriesByFamilyId(id)
            let data = []

            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.name,
                })
            })

            setCategories(data)
            } catch (error) {
            toastr.error(props.t(`I couldn't fetch your categories`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchSubcategories(id) {
        try {
            setLoading(true)
            const response = await ApiService.getSubcategoriesByCategoryId(id)
            let data = []

            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.name,
                })
            })

            setSubCategories(data)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your subcategories`))
        } finally {
            setLoading(false)
        }
    }

    async function fetchPriceTypes() {
        try {
            setLoading(true)
            const response = await ApiService.getPriceTypes()
            let data = []

            response.data.types.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.name,
                    type: item.type,
                    default: item.default
                })
            })

            setPriceTypes(data)
        } catch (error) {
            toastr.error(props.t(`I couldn't fetch your price lists`))
        } finally {
            setLoading(false)
        }
    }

    const onEditorStateChange = (editorState) => {
        const description = draftToMarkdown(convertToRaw(editorState.getCurrentContent()))

        setEditorState(editorState)
        setDescription(description)
    }

    const handleAcceptedFiles = async (files) => {
        try {
            let data = [...images]
            for (let file of files) {
                setLoading(true)
                const formData = new FormData()
                formData.append('file', file)
                let response = await ApiService.uploadProductImage(formData)
    
                data.push({
                    imageUrl: response?.data?.imageUrl,
                    fileName: response?.data?.fileName,
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            }

            setImages(data)
        } catch (error) {
            toastr.error(props.t(`I couldn't upload an image`))
        } finally {
            setLoading(false)
        }
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const handleSubmit = (async (e) => {
        e.preventDefault()
        
        if (!_validateForm()) {
            toastr.error(props.t(`Please review missing fields`), 'Warning')

            return
        }

        try {
            setLoading(true)

            const data = {
                name,
                description,
                tags,
                upc,
                sku,
                barcode,
                model,
                gender: gender.value,
                familyId: familyId.value,
                categoryId: categoryId.value,
                subcategoryId: subcategoryId.value,
                brandId: brandId.value,
                weight,
                weightUnit: weightUnit.value,
                sizeUnit: sizeUnit.value,
                boxHeight,
                boxLength,
                boxWidth,
                unitCost,
                sellable,
                featured,
                productType: productType.value,
                initialStock,
                taxSchemeId: taxSchemeId.value,
                priceList,
                itHasVariants,
                variantOptions,
                variants,
                images
            }
            const response = await ApiService.createProduct(data)

            if (!response.data.success) {
                toastr.error(props.t(`I couldn't add this product, please try again.`), 'Error')

                return
            }

            toastr.success(props.t(`Product Created Successfully`), '¡Éxito!')
            _clearForm()
        } catch (error) {
            toastr.error(props.t(`I couldn't add this product, please try again.`), 'Error')
        } finally {
            setLoading(false)
        }
    })

    const _handleFamilyChange = async (option) => {
        setFamilyId(option)
        await fetchCategories(option.value)
    }

    const _handleCategoryChange = async (option) => {
        setCategoryId(option)
        await fetchSubcategories(option.value)
    }

    const _validateForm = () => {
        if (name === '') { return false }
        if (barcode === '') { return false }
        if (!familyId) { return false }
        if (!categoryId) { return false }
        if (!subcategoryId) { return false }
        if (!brandId) { return false }
        if (itHasVariants && variants.length < 1) { return false }

        return true
    }

    const _clearForm = () => {
        document.getElementById('name').focus()

        setName('')
        setModel('')
        setBarcode('')
        setSKU('')
        setUPC('')
        setGender({
            label: 'Neutro',
            value: 'Neutro'
        })
        setInitialStock('')
        setTags([])
        setFamilyId('')
        setCategoryId('')
        setSubCategoryId('')
        setBrandId('')
        setWeight(0)
        setWeightUnit({
            value: 'g',
            label: 'g'
        })
        setSizeUnit({
            label: props.t(`Centimeters`),
            value: 'cm'
        })
        setVariants([])
        setItHasVariants(false)
        setEditorState(EditorState.createEmpty())
        setUnitCost('')
        setImages([])

        setTempVariant({
            name: false,
            barcode: false,
            sku: false,
            unitCost: false,
            options:[],
            priceList: [],
            initialStock: 0
        })
    }

    const _deleteVariant = async (i) => {
        const data = [...variants]
        data.splice(i, 1)

        setVariants(data)
    }

    const _addProductVariant = async (e) => {
        e.preventDefault()
        let variant = {...tempVariant}

        if (
            variant.unitCost === false ||
            variant.options.length < 1
        ) {
            toastr.error(props.t(`Please fill out the variant's details`), 'Error')

            return
        }

        let data = [...variants]
        const { sku, name } = variant

        data.push({...variant, name, sku})
        setVariants(data)

        toastr.success(props.t(`Variant added`), 'Éxito')
    }

    return(
        <React.Fragment>
            <Form id="product-create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col sm={12} xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Link 
                                    className="btn btn-default waves-effect waves-light mb-2 mr-2"
                                    to="/products"
                                >
                                    {props.t('Cancel')}
                                </Link>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t('Save Product')}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} xs={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            {props.t(`Product Information`)}
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Ponle nombre y descripción a tu producto. 
                                            Considera que, si estos productos los vas 
                                            a vender en línea, entre más clara sea la
                                            información del producto, más fácil será 
                                            para tus clientes descubrir y comprar tus
                                            productos.
                                        </CardSubtitle>

                                        <CardBody>
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles)
                                                }}
                                                >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div style={{ textAlign: 'center', paddingTop: '48px' }}
                                                                className="dz-message needsclick">
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                </div>

                                                                <h4>{props.t(`Drop files here or click to upload`)}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                <Row>
                                                    {images.map((f, i) => {
                                                        return (                                                            
                                                            <Col key={i} className="col-auto">
                                                                <img
                                                                    height={250}
                                                                    data-dz-thumbnail=""
                                                                    className="avatar-sm rounded bg-light"
                                                                    alt={f.name}
                                                                    src={f.preview}
                                                                />
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="name">
                                                    {props.t(`Product name`)} *
                                                </Label>
                                                <Input
                                                    id="name"
                                                    name="productName"
                                                    type="text"
                                                    placeholder={`Macbook Pro 16"`}
                                                    className="form-control"
                                                    value={name}
                                                    required
                                                    onChange={(event) => { setName(event?.target?.value) }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label htmlFor="name">
                                                    {props.t(`Product type`)} *
                                                </Label>
                                                
                                                <Select 
                                                    id="productType"
                                                    defaultValue={productType}
                                                    onChange={value => setProductType(value)}
                                                    options={[{
                                                            label: props.t(`Stocked Products`),
                                                            value: 'stocked'
                                                        }, {
                                                            label: props.t(`Serialized Products`),
                                                            value: 'serialized'
                                                        },
                                                        // {
                                                        //     label: props.t(`Non-stocked Products`),
                                                        //     value: 'non-stocked'
                                                        // }, {
                                                        //     label: props.t(`Service`),
                                                        //     value: 'service'
                                                        // }
                                                    ]}/>
                                            </FormGroup>
                                        </Col>

                                        <FormGroup>
                                            <Label htmlFor="description">
                                                {props.t(`Product description`)}
                                            </Label>
                                            <Editor
                                                editorClassName="draftjs"
                                                editorState={editorState}
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="control-label">
                                                {props.t(`Product tags`)}
                                            </Label>
                                            <TagsInput
                                                onlyUnique={true}
                                                addKeys={[9, 13, 32, 186, 188]}
                                                value={tags}
                                                onChange={(value) => setTags(value)}
                                                pasteSplit={data => {
                                                    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r']

                                                    return data.split(new RegExp(separators.join('|'))).map(d => d.trim())
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            {props.t(`General Details of a Product`)}
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            No todos los campos son obligatorios
                                            para dar de alta un producto, pero, 
                                            en nuestra experiencia, entre más
                                            datos tengas de tus productos,
                                            más eficiente es tu organización de inventarios
                                            y categorización en tus canales de ventas.
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="barcode">
                                                        <i className='mdi mdi-barcode-scan'></i> {props.t(`Barcode`)} 
                                                    </Label>
                                                    <Input
                                                        id="barcode"
                                                        name="barcode"
                                                        type="text"
                                                        className="form-control"
                                                        value={barcode}
                                                        required
                                                        onChange={(event) => setBarcode(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="sku">
                                                        {props.t(`SKU`)}
                                                    </Label>
                                                    <Input
                                                        id="sku"
                                                        name="sku"
                                                        type="text"
                                                        className="form-control"
                                                        value={sku}
                                                        maxLength={15}
                                                        onChange={(event) => setSKU(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="upc">
                                                        {props.t(`UPC`)}
                                                    </Label>
                                                    <Input
                                                        id="upc"
                                                        name="upc"
                                                        type="text"
                                                        maxLength={12}
                                                        className="form-control"
                                                        value={upc}
                                                        onChange={(event) => setUPC(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="model">
                                                        {props.t(`Model`)}
                                                    </Label>
                                                    <Input
                                                        id="model"
                                                        name="model"
                                                        type="text"
                                                        className="form-control"
                                                        value={model}
                                                        onChange={(event) => setModel(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label htmlFor="upc">
                                                        {props.t(`Gender`)}
                                                    </Label>

                                                    <Select 
                                                        id="gender"
                                                        defaultValue={gender}
                                                        onChange={value => setGender(value)}
                                                        options={genders} />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Brand`)} *
                                                    </Label>

                                                    <Select 
                                                        default={brandId}
                                                        placeholder={props.t(`Select brand`)}
                                                        onChange={ value => setBrandId(value) }
                                                        options={brands} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Family`)} *
                                                    </Label>

                                                    <Select 
                                                        id="family"
                                                        name="family"
                                                        default={familyId}
                                                        placeholder={props.t(`Select family`)}
                                                        onChange={ _handleFamilyChange }
                                                        options={families} 
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Category`)} *
                                                    </Label>

                                                    <Select 
                                                        default={categoryId}
                                                        placeholder={props.t(`Select category`)}
                                                        onChange={ _handleCategoryChange }
                                                        options={categories} 
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className="control-label">
                                                        {props.t(`Subcategory`)} *
                                                    </Label>

                                                    <Select 
                                                        default={subcategoryId}
                                                        placeholder={props.t(`Select subcategory`)}
                                                        onChange={ value => setSubCategoryId(value) }
                                                        options={subcategories} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row style={{ paddingTop: '16px'}}>
                                            <Col sm={3}>
                                                <div className="custom-control custom-checkbox mb-3">
                                                    <input type="checkbox" className="custom-control-input" id="sellable" onChange={() => false} checked={sellable} />
                                                    <label className="custom-control-label" onClick={() => { setSellable(!sellable) }} > Este producto se vende</label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                        {props.t(`Weight and Dimensions`)}
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Los datos de dimensiones y pesos
                                            te ayudan a calcular gastos de logística
                                            y a tener un mayor control en tus espacios
                                            y almacenes.
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label htmlFor="weight">
                                                        {props.t(`Weight`)}
                                                    </Label>
                                                    <Input
                                                        id="weight"
                                                        name="weight"
                                                        type="number"
                                                        className="form-control"
                                                        value={weight}
                                                        onChange={(event) => setWeight(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <Label htmlFor="weightUnit">
                                                    {props.t(`Weight unit`)}
                                                </Label>
                                                <FormGroup>
                                                    <Select 
                                                        id={`weightUnit`}
                                                        default={weightUnit}
                                                        placeholder={props.t(`Select unit`)}
                                                        onChange={ value => setWeightUnit(value) }
                                                        options={units}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="sizeUnit">
                                                        {props.t(`Size unit`)}
                                                    </Label>

                                                    <Select 
                                                        id={`sizeUnit`}
                                                        default={sizeUnit}
                                                        placeholder={props.t(`Select size unit`)}
                                                        onChange={ value => setSizeUnit(value) }
                                                        options={sizeUnits}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="boxHeight">
                                                        {props.t(`Box Height`)} ({sizeUnit?.value})
                                                    </Label>
                                                    <Input
                                                        id="boxHeight"
                                                        name="boxHeight"
                                                        type="number"
                                                        className="form-control"
                                                        value={boxHeight}
                                                        onChange={(event) => setBoxHeight(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="boxLength">
                                                        {props.t(`Box Length`)} ({sizeUnit?.value})
                                                    </Label>
                                                    <Input
                                                        id="boxLength"
                                                        name="boxLength"
                                                        type="number"
                                                        className="form-control"
                                                        value={boxLength}
                                                        onChange={(event) => setBoxLength(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label htmlFor="boxWidth">
                                                        {props.t(`Box Width`)} ({sizeUnit?.value})
                                                    </Label>
                                                    <Input
                                                        id="boxWidth"
                                                        name="boxWidth"
                                                        type="number"
                                                        className="form-control"
                                                        value={boxWidth}
                                                        onChange={(event) => setBoxWidth(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                        {props.t(`Prices and Costs`)}
                                        </CardTitle>
                                        <CardSubtitle className="mb-3">
                                            <p>
                                                Registra los costos y precios del producto.
                                                Si registraste listas de precios, no olvides llenar
                                                sus respectivos valores.
                                            </p>
                                            <p>
                                                El precio marcado con asterisco y negritas es
                                                el valor que el sistema utilizará por default.
                                            </p>
                                            <p>
                                                Si este producto tiene variantes, tendrás la
                                                oportunidad de ajustar el valor a cada una en
                                                la siguiente sección.
                                            </p>
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Label htmlFor="unitCost">
                                                        {props.t(`Unit cost`)}
                                                    </Label>

                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                        <Input
                                                            id="unitCost"
                                                            name="unitCost"
                                                            type="text"
                                                            className="form-control"
                                                            value={unitCost}
                                                            onChange={(event) => setUnitCost(event.target.value)}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Label htmlFor="taxSchemeId">
                                                        {props.t(`Tax Scheme`)}
                                                    </Label>

                                                    <Select 
                                                        id={`taxSchemeId`}
                                                        value={taxSchemeId}
                                                        placeholder={props.t(`Select tax scheme`)}
                                                        onChange={ value => setTaxSchemeId(value) }
                                                        options={taxSchemes} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {priceTypes && !itHasVariants && (
                                            <Row>
                                                <Col sm={12}>
                                                    <CardTitle>{props.t(`Price lists`)}</CardTitle>
                                                </Col>

                                                {priceTypes.map((pt) => {
                                                    // Initialize values for new product
                                                    const initialValue = ''

                                                    // Find the matching price entry in priceList based on priceTypeId
                                                    const matchingPrice = priceList.find((price) => price.priceTypeId === pt.value)

                                                    return (
                                                        <Col key={pt.value} sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    {pt.default && (
                                                                        <strong>{pt.label} *</strong>
                                                                    )}

                                                                    {!pt.default && (
                                                                        <React.Fragment>{props.t(pt.label)}</React.Fragment>
                                                                    )}
                                                                </Label>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">{pt.type === 'price' ? `$` : `%`}</InputGroupAddon>
                                                                    <Input
                                                                        id={pt.value}
                                                                        type="number"
                                                                        pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                                        className="form-control"
                                                                        placeholder={pt.type === 'price' ? '100.00' : '10'}
                                                                        value={matchingPrice ? matchingPrice.amount : initialValue} // Populate with matching value or initial value
                                                                        onChange={(e) => {
                                                                            const { value } = e.target
                                                                            const updatedPriceList = priceList.map((price) => {
                                                                                if (price.priceTypeId === pt.value) {
                                                                                    return {
                                                                                        ...price,
                                                                                        amount: value,
                                                                                    }
                                                                                }

                                                                                return price
                                                                            })

                                                                            // If matchingPrice is not found, add a new entry
                                                                            if (!matchingPrice) {
                                                                                updatedPriceList.push({
                                                                                    priceTypeId: pt.value,
                                                                                    amount: value,
                                                                                    type: pt.type,
                                                                                    default: pt.default
                                                                                })
                                                                            }

                                                                            setPriceList(updatedPriceList)
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4} xs={12}>
                                        <CardTitle>
                                            Variaciones de Producto <small> Se creará automáticamente una variación única, a menos que actives múltiples variaciones.</small>
                                        </CardTitle>

                                        <CardSubtitle>
                                            <p>
                                                Un producto puede tener una o mas variaciones, ej. diferentes tallas y/o colores. Cada variación tiene sus propios precios, niveles de stock, entre otras cosas.
                                            </p>

                                            <div className="custom-control custom-checkbox mb-3">
                                                <input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={itHasVariants} />
                                                <label className="custom-control-label" onClick={() => {
                                                    setItHasVariants(!itHasVariants)
                                                    }} >
                                                        Este producto tiene múltiples variaciones.
                                                </label>
                                            </div>
                                        </CardSubtitle>
                                    </Col>

                                    <Col sm={8} xs={12}>
                                    {itHasVariants && (
                                        <React.Fragment>
                                            <CSelect
                                                placeholder={props.t(`Select variant options`)}
                                                defaultValue={variantOptions}
                                                options={variantTypes}
                                                onChange={(value) => {
                                                    setVariantOptions(value)
                                                }}
                                                isClearable
                                                isSearcheable
                                                isMulti={true}
                                            />
                                        </React.Fragment>
                                    )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        {(itHasVariants && variantOptions?.length > 0) && (
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <Table className={'table'}>
                                                <thead>
                                                    <tr>
                                                        <th>SKU</th>
                                                        <th>Barcode</th>
                                                        <th>Nombre de Variante</th>
                                                        {variantOptions?.map((o, i) => {
                                                            return (
                                                                <React.Fragment key={i}>
                                                                    <th>{o.label}</th>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                        <th>Costo Unitario</th>
                                                        {priceTypes && (
                                                            <React.Fragment>
                                                                {priceTypes.map((pt) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            {pt.default && (
                                                                                <th>Precio {pt.label} *</th>
                                                                            )}

                                                                            {!pt.default && (
                                                                                <th>Precio {pt.label}</th>
                                                                            )}
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )}
                                                        <th>Stock Inicial</th>
                                                        <th>...</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {variants[0] && (
                                                        <React.Fragment>
                                                            {variants.map((v, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{v.sku}</td>
                                                                        <td>{v.barcode}</td>
                                                                        <td>{v.name}</td>
                                                                        {v.options.map((o) => {
                                                                            return (
                                                                                <td>{o.value}</td>
                                                                            )
                                                                        })}
                                                                        <td>{v.unitCost}</td>
                                                                        {v.priceList.map((pt) => (
                                                                            <td>{pt.amount}</td>
                                                                        ))}
                                                                        <td>{v.initialStock ? v.initialStock : 0}</td>
                                                                        <td>
                                                                            <button onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    _deleteVariant(i)
                                                                                }}
                                                                                className="btn btn-small btn-default">
                                                                                <i className="mdi mdi-delete"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                    <tr>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                placeholder="SKU"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        sku: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                placeholder={props.t('Barcode')}
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        barcode: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                placeholder="Adidas Superstar M"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        name: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        {variantOptions.map((o, i) => {
                                                            return (
                                                                <td key={i}>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder={props.t(o.label)}
                                                                        className="form-control"
                                                                        onChange={(event) => {
                                                                            const { value } = event.target
                                                                            const options = [...tempVariant.options]
                                                                            options[i] = {
                                                                                label: props.t(o.label),
                                                                                value
                                                                            }

                                                                            setTempVariant({
                                                                                ...tempVariant,
                                                                                options
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                            )
                                                        })}
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                placeholder="Costo Unitario"
                                                                className="form-control"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        unitCost: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>

                                                        {priceTypes && (
                                                            <React.Fragment>
                                                                {priceTypes.map((pt, index) => {
                                                                    const priceListItem = tempVariant.priceList.find((price) => price.priceTypeId === pt.value)
                                                                    const initialValue = priceListItem ? priceListItem.amount : ''

                                                                    return (
                                                                        <td key={pt.value}>
                                                                            <InputGroup>
                                                                                <InputGroupAddon addonType="prepend">{pt.type === 'price' ? `$` : `%`}</InputGroupAddon>
                                                                                <Input
                                                                                    type="number"
                                                                                    pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                                                                    className="form-control"
                                                                                    placeholder={pt.type === 'price' ? '100.00' : '10'}
                                                                                    value={initialValue}
                                                                                    onChange={(event) => {
                                                                                        const { value } = event.target
                                                                                        const updatedPriceList = tempVariant.priceList.map((price) => {
                                                                                            if (price.priceTypeId === pt.value) {
                                                                                                return {
                                                                                                    ...price,
                                                                                                    amount: value,
                                                                                                }
                                                                                            }

                                                                                            return price
                                                                                        })

                                                                                        // If priceListItem is not found, add a new entry
                                                                                        if (!priceListItem) {
                                                                                            updatedPriceList.push({
                                                                                                priceTypeId: pt.value,
                                                                                                amount: value,
                                                                                                type: pt.type,
                                                                                                default: pt.default,
                                                                                            })
                                                                                        }

                                                                                        setTempVariant({
                                                                                            ...tempVariant,
                                                                                            priceList: updatedPriceList,
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </InputGroup>
                                                                        </td>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )}
                                                        
                                                        <td>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Stock"
                                                                onChange={(event) => {
                                                                    setTempVariant({
                                                                        ...tempVariant,
                                                                        initialStock: event.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </td>

                                                        <td>
                                                            <Button
                                                                style={{ padding: '4px 16px' }}
                                                                className="btn btn-success waves-effect waves-light mb-2 mr-2"
                                                                onClick={ _addProductVariant }>
                                                                <i className="mdi mdi-plus"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return {}
}

export default connect(
    mapStatetoProps, {}
)(withRouter(withNamespaces()(CreateForm)))