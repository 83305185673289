import React, { useState } from "react"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"

import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { setActiveCompany } from "../../store/actions"

const ProfileMenu = (props) => {
    const [menu, setMenu] = useState(false)

    const toggle = () => {
        setMenu(!menu)
    }

    return(
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={ toggle }
                className="d-inline-block"
                onClick={() => {
                    window.analytics.track('Perfil: Menú')
                }}
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <span className="header-item-profile-name">
                        {props && (
                            <span>{props.firstName} ({props.activeCompany?.businessName})</span>
                        )}
                    </span>

                    {props && props.avatar && (
                        <img className="rounded-circle header-profile-user thumbnail"
                            src={props.avatar}
                            alt={props.firstName}
                        />
                    )}

                    {props && !props.avatar && (
                        <img className="rounded-circle header-profile-user thumbnail"
                            src={`https://storage.googleapis.com/vantaapp/assets/avatar-1.png`}
                            alt={props.firstName}
                        />
                    )}

                    <i className="header-item-chevron mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    {/* eslint-disable-next-line */}
                    {props.companies?.map((company, i) => {
                        if (props.activeCompany.businessName !== company.businessName) {
                            return (
                                <DropdownItem key={i} tag="a" href="#" onClick={(e) => {
                                    e.preventDefault()

                                    window.analytics.track('Switched company', {
                                        previousCompany: props.activeCompany.businessName,
                                        newActiveCompany: company.businessName
                                    })

                                    props.setActiveCompany(company)
                                }}>
                                    {company.businessName} <i className="bx bx-chevron-right font-size-17 align-middle mr-1"></i>
                                </DropdownItem>
                            )
                        }
                    })}
                    <div className="dropdown-divider"></div>
                    <DropdownItem tag="a" href="/settings" onClick={() => {
                                window.analytics.track('Ir a Configuraciones')
                            }}>
                        <i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                        Configuraciones
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <Link to="/logout" className="dropdown-item" onClick={() => {
                                window.analytics.track('Cerrar Sesión')
                            }}>
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>Cerrar Sesión</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return {
        profile: state.Auth?.profile,
        firstName: state.Auth?.profile?.firstName,
        avatar: state.Auth?.profile?.avatar,
        companies: state.Auth?.profile?.companies,
        activeCompany: state.Auth?.activeCompany
    }
}

export default connect(
    mapStatetoProps, { setActiveCompany }
)(withRouter(ProfileMenu))
