export const getUrlOfDefaultProductImage = () => 'https://storage.googleapis.com/cloudadmin-storage/assets/images/default-product-img.png'

export const getImagesOfProduct = (gallery) => {
    const images = []
    for (const item of gallery) {
        images.push(item.fileName)
    }
    return images
}

export const saveStream = (contentType, fileName, text) => {
    if (window.navigator.msSaveBlob) {
        // IE 10 and later, and Edge.
        const blobObject = new Blob([text], { type: contentType })
        window.navigator.msSaveBlob(blobObject, fileName)
    } else {
        // Everthing else (except old IE).
        // Create a dummy anchor (with a download attribute) to click.
        var anchor = document.createElement('a')
        anchor.download = fileName

        if (window.URL.createObjectURL) {
            // Everything else new.
            const blobObject = new Blob([text], { type: contentType })
            anchor.href = window.URL.createObjectURL(blobObject)
        } else {
            // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
            // Load up the data into the URI for "download."
            anchor.href = `data:${contentType};charset=utf-8,${encodeURIComponent(text)}`
        }

        // Now, click it.
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents')
            event.initEvent('click', true, true)
            anchor.dispatchEvent(event)
        } else {
            anchor.click()
        }
    }
}