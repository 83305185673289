import React, { useState, useEffect, useContext } from 'react'
import { GeneralContext } from '../../../context'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { isEmpty } from 'lodash'

import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardTitle,
    CardSubtitle
} from "reactstrap"
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [branchName, setBranchName] = useState('')
    const [branchCode, setBranchCode] = useState()
    // Address 
    const [contactName, setContactName] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [contactPhoneNumber, setContactPhoneNumber] = useState('')
    const [contactCellphone, setContactCellphone] = useState('')
    const [streetName, setStreetName] = useState('')
    const [exteriorNumber, setExteriorNumber] = useState()
    const [interiorNumber, setInteriorNumber] = useState()
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [zipCode, setZipCode] = useState()

    useEffect(() => {
        if (isEmpty(branchCode)) {
            const prefix = branchName.split(' ').map(word => word.charAt(0)).join('').toUpperCase() || 'BN'
            setBranchCode(`${prefix}-${Math.floor(10000 + Math.random() * 90000)}`)
        }

        const { branch } = props

        if (props.edit) {
            setId(branch.id)
            setBranchName(branch.branchName)
            setBranchCode(branch.branchCode)
            setContactName(branch.contactName)
            setContactEmail(branch.contactEmail)
            setContactPhoneNumber(branch.contactPhoneNumber)
            setContactCellphone(branch.contactCellphone)
            setStreetName(branch.streetName)
            setExteriorNumber(branch.exteriorNumber)
            setInteriorNumber(branch.interiorNumber)
            setNeighborhood(branch.neighborhood)
            setCity(branch.city)
            setState(branch.state)
            setCountry(branch.country)
            setZipCode(branch.zipCode)
        }

        // eslint-disable-next-line
    }, [])

    const _handleSubmit = async (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        try {
            setLoading(true)
            let data = {
                branchName,
                branchCode,
                contactName,
                contactEmail,
                contactPhoneNumber,
                contactCellphone,
                streetName,
                exteriorNumber,
                interiorNumber,
                neighborhood,
                city,
                state: state,
                country: country,
                zipCode
            }
    
            if (props.edit) { 
                data = {
                    ...data,
                    id
                }
    
                await _updateBranch(data)
            } else {
                await _createBranch(data)
            }
    
            props.history.push('/branches')
        } catch (error) {
            toastr.error(props.t(error.message || 'An unexpected error occurred'), 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _updateBranch = async (data) => {
        try {
            const response = await ApiService.updateBranch(data)

            if (!response.data.success) {
                throw new Error(`I couldn't update the branch`)
            }

            toastr.success(response.data.message, '¡Éxito!')
            window.analytics.track('Lugar de expedición actualizado')
        } catch(error) {
            throw new Error(error.message)
        }
    }

    const _createBranch = async (data) => {
        try {
            const response = await ApiService.createBranch(data)

            if (!response.data.success) {
                throw new Error(`I couldn't create the branch`)
            }

            toastr.success(props.t(`Branch Added`), '¡Éxito!')
            window.analytics.track('Branched Added')
        } catch(error) {
            throw new Error(error.message)
        }
    }

    const _validateForm = () => {
        if (branchName.trim() === '') {
            toastr.error(props.t(`I need a branch name to continue`), props.t(`one second`))
            document.getElementById('branchName').classList.add('is-invalid')

            return false
        } else {
            document.getElementById('branchName').classList.remove('is-invalid')
        }

        if (branchCode.trim() === '') {
            toastr.error(props.t(`I need a branch code to continue`), props.t(`one second`))
            document.getElementById('branchCode').classList.add('is-invalid')

            return false
        } else {
            document.getElementById('branchCode').classList.remove('is-invalid')
        }

        return true
    }

    return(
        <React.Fragment>
            <Form id="branch" method="POST" onSubmit={ _handleSubmit }>
                <Row>
                    <Col md={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t(`Cancel`)}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t(`Save`)}
                                </Button>
                            </CardBody>
                        </Card>

                        <Row>
                            <Col md={4} sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>{props.t(`General Information`)}</CardTitle>

                                        <CardSubtitle className="mb-3">
                                            {props.t(`Write the branch's name, and general data about the branch`)}.
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={8} sm={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label htmlFor="branchName">
                                                        {props.t(`Branch Name`)}
                                                    </Label>

                                                    <Input
                                                        id="branchName"
                                                        name="branchName"
                                                        type="text"
                                                        className="form-control"
                                                        value={branchName}
                                                        required={true}
                                                        onChange={event => setBranchName(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label htmlFor="branchCode">
                                                        {props.t(`Branch Code`)}
                                                    </Label>

                                                    <Input
                                                        id="branchCode"
                                                        name="branchCode"
                                                        type="text"
                                                        className="form-control"
                                                        value={branchCode}
                                                        required={true}
                                                        onChange={event => setBranchCode(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>{props.t(`Contact Information`)}</CardTitle>

                                        <CardSubtitle className="mb-3">
                                            {props.t(`Save the contact information for this branch`)}.
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={8} sm={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact name`)}
                                                    </Label>
                                                    <Input
                                                        id="contactName"
                                                        type="text"
                                                        className="form-control"
                                                        value={contactName}
                                                        onChange={event => setContactName(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact email`)}
                                                    </Label>
                                                    <Input
                                                        id="contactEmail"
                                                        type="email"
                                                        className="form-control"
                                                        value={contactEmail}
                                                        onChange={event => setContactEmail(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact phone number`)}
                                                    </Label>
                                                    <Input
                                                        type="tel"
                                                        pattern="\d{10}"
                                                        maxLength={10}
                                                        className="form-control"
                                                        id="contactPhoneNumber"
                                                        value={contactPhoneNumber}
                                                        onChange={event => setContactPhoneNumber(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Contact Cellphone number`)}
                                                    </Label>
                                                    <Input
                                                        id="contactCellphone"
                                                        type="tel"
                                                        pattern="\d{10}"
                                                        maxLength={10}
                                                        className="form-control"
                                                        value={contactCellphone}
                                                        onChange={event => setContactCellphone(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4} sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>{props.t(`Address Information`)}</CardTitle>

                                        <CardSubtitle className="mb-3">
                                            {props.t(`Save the address information for this branch`)}.
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={8} sm={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Country`)}
                                                    </Label>

                                                    <Input
                                                        id="country"
                                                        name="country"
                                                        type="text"
                                                        className="form-control"
                                                        value={country}
                                                        onChange={event => setCountry(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`State`)}
                                                    </Label>

                                                    <Input
                                                        id="state"
                                                        name="state"
                                                        type="text"
                                                        className="form-control"
                                                        value={state}
                                                        onChange={event => setState(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Zip Code`)}
                                                    </Label>
                                                    <Input
                                                        id="zipCode"
                                                        type="text"
                                                        className="form-control"
                                                        value={zipCode}
                                                        onChange={event => setZipCode(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`City`)}
                                                    </Label>
                                                    <Input
                                                        id="city"
                                                        type="text"
                                                        className="form-control"
                                                        value={city}
                                                        onChange={event => setCity(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Neighborhood`)}
                                                    </Label>
                                                    <Input
                                                        id="neighborhood"
                                                        type="text"
                                                        className="form-control"
                                                        value={neighborhood}
                                                        onChange={event => setNeighborhood(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Street Name`)}
                                                    </Label>
                                                    <Input
                                                        id="streetName"
                                                        type="text"
                                                        className="form-control"
                                                        value={streetName}
                                                        onChange={event => setStreetName(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Exterior No.`)}
                                                    </Label>
                                                    <Input
                                                        id="exteriorNumber"
                                                        type="number"
                                                        className="form-control"
                                                        value={exteriorNumber}
                                                        onChange={event => setExteriorNumber(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={3}>
                                                <FormGroup>
                                                    <Label>
                                                        {props.t(`Interior No.`)}
                                                    </Label>
                                                    <Input
                                                        id="interiorNumber"
                                                        type="number"
                                                        className="form-control"
                                                        value={interiorNumber}
                                                        onChange={event => setInteriorNumber(event.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))