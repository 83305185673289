import bugsnag from "bugsnag-js"
import createPlugin from "bugsnag-react"
import React from "react"
import Layout from './components/Layout/Index'
import Public from './components/Layout/Public'
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { GeneralContextProvider } from './context'

import "./assets/scss/theme.scss"

const bugsnagClient = bugsnag("32415ca1e38b328755f7fc962a7ed211")
const ErrorBoundary = bugsnagClient.use(createPlugin(React))

const App = () => {
    return (
        <React.Fragment>
            <GeneralContextProvider>
                <ErrorBoundary>
                    <Router>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Public}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={false}
                                />
                            ))}

                            {authProtectedRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={true}
                                />
                            ))}
                        </Switch>
                    </Router>
                </ErrorBoundary>
            </GeneralContextProvider>
        </React.Fragment>
    )
}

export default App
