import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    UncontrolledTooltip
} from 'reactstrap'
import { Link } from 'react-router-dom'

import Dropzone from 'react-dropzone'
import toastr from 'toastr'
import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context/'

import 'toastr/build/toastr.min.css'

const Gallery = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [product, setProduct] = useState(props.product)
    const [gallery, setGallery] = useState(props.gallery)

    useEffect(() => {
        setProduct(props.product)
    }, [props.product])

    useEffect(() => {
        setGallery(props.gallery)
    }, [props.gallery])

    const _loadProduct = async (id) => {
        setLoading(true)
        const response = await ApiService.getProductById(id)
        setLoading(false)
        setProduct(response.data.product)
        setGallery(response.data.product.gallery)
    }

    const _handleUploadedFiles = async (files) => {
        setLoading(true)
        let promises = []
        await files.forEach((file) => {
            let formData = new FormData()

            formData.append('id', product.id)
            formData.append('file', file, file.name)
            formData.append('featured', false)

            promises.push(ApiService.uploadProductImages(formData))
        })

        await Promise.all(promises)
        await _loadProduct(product.id)
        setLoading(false)
        toastr.success('Imágenes Listas', 'Éxito')
    }

    const _deleteImage = async (id) => {
        setLoading(true)
        const response = await ApiService.deleteProductImage(id)
        if (!response.data.success) {
            toastr.error('No pude eliminar la imagen', '¡Error!')
            setLoading(false)
            return
        }

        let temp = gallery.filter((i) => {
            return i.id !== id
        })
        setLoading(false)
        setGallery(temp)
        toastr.success('Eliminé una imagen')
    }

    const _setDefaultImage = async (imageId) => {
        setLoading(true)
        const response = await ApiService.setDefaultImage({
            id: imageId,
            productId: product.id
        })

        if (!response.data.success) {
            toastr.error('No pude asignar la imagen como default')
            setLoading(false)
            return
        }

        let temp = gallery
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === imageId) {
                temp[i].featured = true
            }
        }
        setLoading(false)
        setGallery(temp)
        toastr.success('Establecí esta imagen como default')
    }

    return(
        <React.Fragment>
            <Row>
                <Col sm={6}>
                    <h5>Imágenes de {product.name}</h5>
                </Col>

                <Col sm={6}></Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <Dropzone onDrop={acceptedFiles =>
                            _handleUploadedFiles(acceptedFiles)
                        }
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                                <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                        <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                        </div>
                                        <h4>Suelta aquí o da click para seleccionar</h4>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </Col>
            </Row>

            <br /><br />

            {gallery && (
                <Row className="">
                    {gallery.map((image, i) => {
                        return (
                            <Col key={i} sm="2">
                                <Card className="text-center">
                                    <CardBody>
                                        <img alt={product.name} style={{width: '100%'}} src={`${image.fileName}`} />
                                    </CardBody>
                                    <CardFooter className="bg-transparent border-top">
                                        <div className="contact-links d-flex font-size-20">
                                            {!image.featured && (
                                                <div className="flex-fill">
                                                    <Link to="#" id={'img-main-' + image.id} onClick={() => {
                                                        _setDefaultImage(image.id)
                                                    }}>
                                                        <i className="mdi mdi-flag"></i>
                                                        <UncontrolledTooltip placement="top" target={'img-main-' + image.id}>
                                                            Establecer como Predeterminada
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                </div>
                                            )}

                                            <div className="flex-fill">
                                                <Link to="#" id={'img-' + image.id} onClick={() => {
                                                    _deleteImage(image.id)
                                                }}>
                                                    <i className="mdi mdi-delete-circle"></i>
                                                    <UncontrolledTooltip placement="top" target={'img-' + image.id}>
                                                        Borrar Imagen
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            )}
        </React.Fragment>
    )
}

export default Gallery