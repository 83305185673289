import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { GeneralContext } from '../../../context'
import ApiService from '../../../data/services/ApiService'

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [currencyId, setCurrencyId] = useState('')
    const [alias, setAlias] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [CLABE, setCLABE] = useState('')
    const [branchName, setBranchName] = useState('')
    const [initialBalance, setInitialBalance] = useState(0)

    // catalogs
    const [currencies, setCurrencies] = useState([])

    useEffect(() => {
        (async () => {
            await fetchCurrencies()
        })()

        async function fetchCurrencies() {
            setLoading(true)
            const response = await ApiService.getCurrencies()
            let data = []

            response.data.currencies.map((item) => {
                return data.push({
                    value: item.id,
                    label: `${item.name} (${item.value})`,
                })
            })

            setLoading(false)
            setCurrencies(data)
            setCurrencyId(data[0].value)
        }
    }, []) // eslint-disable-line

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        const data = {
            alias,
            currencyId,
            bankName,
            accountNumber,
            CLABE,
            branchName,
            initialBalance
        }

        setLoading(true)

        try {
            const response = await ApiService.createBankAccount(data).catch(error => {
                toastr.error(`No pude crear a ${alias}.`, 'Error')
                setLoading(false)
            })
    
            if (response.data.success) {
                toastr.success(`${alias} fue agregado.`, '¡Éxito!')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }

        _clearForm()
    }

    const _validateForm = () => {
        if (alias === '') { return false }

        return true
    }

    const _clearForm = () => {
        setAlias('')
        setAccountNumber('')
        setBranchName('')
        setBankName('')
        setCLABE('')
        setCurrencyId('')
        setInitialBalance('')

        document.getElementById('alias').focus()
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    {props.t('Cancel')}
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> {props.t('Save')}
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="alias">
                                                {props.t(`Alias`)} *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={alias}
                                                id="alias"
                                                onChange={event => setAlias(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="control-label">
                                                {props.t('Currency')} *
                                            </Label>
                                            <select className="custom-select"
                                                defaultValue={currencyId}
                                                onChange={(event) => setCurrencyId(event.target.value)}>
                                                {currencies.map((item, i) => {
                                                    let selected = currencyId === item.value ? 'selected' : ''
                                                    return (
                                                        <option key={i} value={item.value} selected={selected}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="accountNumber">
                                                {props.t(`Account No.`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={accountNumber}
                                                onChange={event => setAccountNumber(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="CLABE">
                                                {props.t(`CLABE`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={CLABE}
                                                onChange={event => setCLABE(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="bankName">
                                                {props.t(`Bank Name`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={bankName}
                                                onChange={event => setBankName(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="branchName">
                                                {props.t(`Branch Name`)}
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={branchName}
                                                onChange={event => setBranchName(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="bankName">
                                                {props.t(`Initial Balance`)}
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                value={initialBalance}
                                                onChange={event => setInitialBalance(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(FormPage))