import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardFooter
} from 'reactstrap'

import { Link, withRouter } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'

const Table = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState([])
    const [customerId, setCustomerId] = useState()
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await _fetchData()
        })()
    }, []) // eslint-disable-line

    const _fetchData = async () => {
        setLoading(true)
        try {
            const customerId = props.match.params.customerId
            const response = await ApiService.getAddressByCustomerId(customerId)

            setCustomerId(customerId)
            setData(response.data.addresses)
            setFilteredData(response.data.addresses)
            setLoading(false)
        } catch (error) {
            toastr.error(props.t(`Oop's! I couldn't find any addresses`))
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const streetName = item.streetName || ''
            const exteriorNumber = item.exteriorNumber || ''
            const interiorNumber = item.interiorNumber || ''
            const neighborhood = item.neighborhood || ''

            return streetName.toLowerCase().includes(keystroke) ||
                   exteriorNumber.toLowerCase().includes(keystroke) ||
                   interiorNumber.toLowerCase().includes(keystroke) ||
                   neighborhood.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        setLoading(true)

        try {
            const result = await ApiService.deleteCustomerAddress(id)

            if (!result.data.success) {
                toastr.error(props.t(`I couldn't delete this customer address`), 'Error')

                return false
            }

            let filtered = await data.filter((v) => { return v.id !== id })

            setData(filtered)
            setFilteredData(filtered)

            toastr.success(props.t(`I deleted a customer`), '¡Éxito!')
            window.analytics.track('Customer address deleted', {
                customerId: id
            })
        } catch (error) {
            console.log(error)

            toastr.error(props.t(`I couldn't delete this customer address`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control"
                                placeholder={props.t(`Search`)}
                                onKeyUp={(e) => _filterData(e)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col style={{ textAlign: 'center' }} xl={4} md={6} sm={12} xs={12}>
                    <Link to={`/customers/addresses/${customerId}/add`}>
                        <img style={{ width: '60%' }} src="/assets/images/add-item.svg" alt={``} />
                    </Link>
                </Col>

                {filteredData && filteredData.map((item, key) => (
                    <Col key={key} xl={4} md={6} sm={12} xs={12}>
                        <Card>
                            <CardTitle>
                                {item.streetName} {item.exteriorNumber} Int. {item.interiorNumber}<br />
                                {item.neighborhood}, {item.city}, {item.state}, {item.country}, {props.t(`Zip Code`)} {item.zipCode}
                            </CardTitle>

                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex font-size-20">
                                    <div className="flex-fill">
                                        <Link id={'edit'} to={`/customers/addresses/${item.customerId}/edit/${item.id}`}>
                                            <i className="mdi mdi-pencil"></i> {props.t(`Edit`)}
                                        </Link>
                                    </div>

                                    <div className="flex-fill">
                                        <Link id={'delete'} to={`#`} onClick={(e) => {
                                            e.preventDefault()
                                            _deleteItem(item.id)
                                        }}>
                                            <i className="mdi mdi-trash-can font-size-16 mr-2"></i> {props.t(`Delete`)}
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withNamespaces()(Table))