import React from "react"
import { Spinner } from "reactstrap"

const Loading = () => {
    return (
        <div className="full-page-loader">
            <Spinner
                style={{ width: "3rem", height: "3rem", color: "#f44336" }}
            />{" "}
        </div>
    )
}

export default Loading
