import React, { useState, useEffect } from 'react'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import paymentTypeCatalog from '../../../data/paymentTypes'
import { CardFooter } from 'reactstrap'
import {
    Row,
    Col,
    Button,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormGroup,
    Label,
    Input
} from "reactstrap"
import Select from "react-select"
import CurrencyFormat from 'react-currency-format'

export default ({
    order,
    closeModal,
    setLoading,
    updateOrder,
    t
}) => {
    const [accountId, setAccountId] = useState({value: '', label: ''})
    const [showLastForDigits, setShowLastFourDigits] = useState(false)
    const [lastFourDigits, setLastFourDigits] = useState('')
    const [paymentType, setPaymentType] = useState({value: '01', label: 'Efectivo'})
    const [amountPaid, setAmountPaid] = useState()
    const [bankAccounts, setBankAccounts] = useState()

    useEffect(() => {
        if (order.payments.length > 0) {
            setAmountPaid(order.payments.reduce((a, b) => a + +b.amountPaid, 0))
        }
    }, [order]) // eslint-disable-line

    useEffect(() => {
        (async () => {
            await fetchBankAccounts()
        })()
    }, []) // eslint-disable-line

    const fetchBankAccounts = async () => {
        try {
            setLoading(true)
            const response = await ApiService.getBankAccounts()

            let data = []
            response.data.data.map((item) => {
                return data.push({
                    value: item.id,
                    label: item.alias
                })
            })

            setBankAccounts(data)
            setAccountId(data[0])
        } catch (error) {
            setLoading(false)
            toastr.error(t(`I couldn't fetch your bank accounts`))
        } finally {
            setLoading(false)
        }
    }

    const __markOrderAsPaid = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const response = await ApiService.markSalesOrderAsPaid({
                orderId: order.id, 
                accountId: accountId.value,
                currencyId: order.currencyId,
                currencyRate: order.currencyRate,
                amountPaid: amountPaid ? amountPaid : order.total,
                paymentType: paymentType.value,
                lastFourDigits
            })

            if (!response.data.success) {
                toastr.error(t(`I couldn't mark as paid this Sales order`), 'Error')
                return
            }

            toastr.success(t(`Payment processed`), t('Success!'))

            updateOrder(response.data.order)
        } catch (error) {
            toastr.error(t(`I couldn't mark as paid this Sales order`), 'Error')
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col style={{
                    padding: '16px 24px',
                    background: '#496ddb',
                    textAlign: 'right',
                    color: 'white'
                }} sm={12}>
                    <span style={{
                        fontSize: '36px'
                    }}>
                        {t(`Balance`)}: <CurrencyFormat
                                decimalScale={2}
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                                value={+order.total - +order.payments.reduce((a, b) => a + +b.amountPaid, 0)} />
                    </span>
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label>{t(`Amount paid`)}</Label>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    $
                                </InputGroupText>
                            </InputGroupAddon>

                            <Input
                                autoFocus={true}
                                id="amountPaid"
                                type="text"
                                value={amountPaid}
                                placeholder={t(`0.00`)}
                                onChange={(e) => {
                                    const { value } = e.target
                                    setAmountPaid(value)
                                }}
                                onBlur={(e) => {
                                    if (e.target.value !== '' && e.target.value < order.total) {
                                        // toastr.warning(t(`Make sure customer pays the full amount.`))
                                        document.getElementById("amountPaid").focus()
                                    }
                                }}
                                />
                        </InputGroup>
                    </FormGroup>
                </Col>

                <Col sm={6}>
                    <FormGroup>
                        <Label htmlFor="paymentType">
                            {t(`Bank Account`)}
                        </Label>

                        <Select
                            id="bankAccount"
                            value={accountId}
                            placeholder={t(`Select a bank account`)}
                            onChange={(value) => { 
                                setAccountId(value)
                            }}
                            options={bankAccounts}
                            classNamePrefix="select2-selection"
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label htmlFor="paymentType">
                            {t(`Payment type`)}
                        </Label>

                        <Select
                            id="paymentType"
                            value={paymentType}
                            placeholder={t(`Select a payment type`)}
                            onChange={(value) => {
                                if (
                                    value.value === '04' ||
                                    value.value === '05' ||
                                    value.value === '06' ||
                                    value.value === '08' ||
                                    value.value === '28' ||
                                    value.value === '29'
                                    ) {
                                    setShowLastFourDigits(true)
                                }

                                setPaymentType(value)
                            }}
                            options={paymentTypeCatalog}
                            classNamePrefix="select2-selection"
                        />
                    </FormGroup>
                </Col>

                {showLastForDigits && (
                    <Col sm={6}>
                        <FormGroup>
                            <Label htmlFor="paymentType">
                                {t(`Last Four Digits`)}
                            </Label>

                            <Input
                                id="lastFourDigits"
                                type="text"
                                value={lastFourDigits}
                                placeholder={t(`Input card's last four digits`)}
                                onChange={(e) => {
                                    setLastFourDigits(e.target.value)
                                } }
                                />
                        </FormGroup>
                    </Col>
                )}
            </Row>

            <CardFooter>
                <Button color="secondary" size='md' onClick={() => { }}>{t(`Cancel`)}</Button>
                <Button color="primary" size='md' onClick={__markOrderAsPaid}>{t(`Save payment`)}</Button>
            </CardFooter>
        </React.Fragment>
    )
}