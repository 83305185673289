import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Form,
    Button
} from 'reactstrap'
import { withNamespaces } from 'react-i18next'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const Users = (props) => {
    const [users, setUsers] = useState([])
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        countryCode: '',
        phone: '',
        email: '',
        password: '',
        roleId: '',
    })

    useEffect(() => {
        (async () => {
            await fetchUsers()
        })()
    }, []) // eslint-disable-line

    useEffect(() => {
        setUser({
            ...user,
            companyId: props.company.id
        })
    }, []) // eslint-disable-line

    const fetchUsers = async () => {
        try {
            const response = await ApiService.getUsers()

            setUsers(response.data.users)
        } catch (error) {
            toastr.error(`No pude comunicarme con el servidor`, 'Problema de Conexión')
        } finally {
            props.setLoading(false)
        }
    }

    const _handleSubmit = async (e) => {
        try {
            e.preventDefault()
            props.setLoading(true)
    
            const response = await ApiService.createUser(user)
            if (!response) {
                toastr.error(props.t(`I couldn't update your data`))

                return
            }
    
            toastr.success(props.t(`User added`))
            props.closeModal(false)
        } catch (error) {
            toastr.error(props.t(`I couldn't update your data`))
        } finally {
            props.setLoading(false)
        }
    }

    const _deleteUser = async (id) => {
        try {
            props.setLoading(true)
            await ApiService.deleteUser(id)
            const nusers = await users.filter((v) => { return v.id !== id })

            setUsers(nusers)

            toastr.success(props.t(`I deleted a user`))
            window.analytics.track('Branch deleted')
        } catch (error) {
            toastr.error(props.t(`I couldn't delete this user`))
        } finally {
            props.setLoading(false)
        }
    }

    return (
        <Card>
            <CardTitle>{props.t(`Users`)}</CardTitle>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <Form id="users-create" method="POST" onSubmit={ _handleSubmit }>
                            <FormGroup>
                                <Label htmlFor="firstName">
                                    {props.t(`First Name`)}
                                </Label>

                                <Input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    className="form-control"
                                    value={user.firstName}
                                    onChange={event => setUser({
                                        ...user,
                                        firstName: event.target.value
                                    })}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="lastName">
                                    {props.t(`Last Name`)}
                                </Label>

                                <Input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    className="form-control"
                                    value={user.lastName}
                                    onChange={event => setUser({
                                        ...user,
                                        lastName: event.target.value
                                    })}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="email">
                                {props.t(`Email`)}
                                </Label>

                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    value={user.email}
                                    onChange={event => setUser({
                                        ...user,
                                        email: event.target.value
                                    })}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="phone">
                                {props.t(`Phone Number`)}
                                </Label>

                                <Input
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    className="form-control"
                                    value={user.phone}
                                    onChange={event => setUser({
                                        ...user,
                                        phone: event.target.value
                                    })}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="password">
                                    {props.t(`Password`)}
                                </Label>

                                <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    value={user.password}
                                    onChange={event => setUser({
                                        ...user,
                                        password: event.target.value
                                    })}
                                />
                            </FormGroup>

                            <Button
                                color='primary'>
                                {props.t(`Add User`)}
                            </Button>
                        </Form>
                    </Col>

                    <Col md={6}>
                        <ul className='users-list'>
                            {users.map((u, i) => (
                                <li key={i}>
                                    <span>
                                        <button
                                            onClick={(e) => { 
                                                e.preventDefault()
                                                _deleteUser(u.id) 
                                            }}
                                            className='btn-outline'>
                                            <i className='fa fa-trash' />
                                        </button> {u.firstName} {u.lastName}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Users)